import { gql } from '@apollo/client'

export const GET_MEMBERS_CONFIG = gql`
  query MembersConfig($version: String!) {
    membersConfig(version: $version) {
      createdAt
      updatedAt
      version
      id
      footer {
        customText
        showCustomText
        showExternalLinks
        showHerosparkLogo
        externalLinks {
          icon
          url
        }
      }
      header {
        banners {
          active
          altText
          description
          imageDesktopUrl
          imageMobileUrl
          linkButtonText
          linkUrl
          order
          showLink
          showLinkButton
          showLinkText
          showTexts
          title
          kind
        }
      }
      sections {
        active
        cardFormat
        order
        kind
      }
      general {
        faviconUrl
        logoUrl
        showProductName
      }
      customBanners {
        active
        items {
          altText
          linkUrl
          logoDesktopUrl
          logoMobileUrl
          logoTabletUrl
        }
        kind
        order
        title
      }
      customProductLists {
        active
        id
        kind
        order
        title
        items(pagination: { limit: 10, offset: 0 }) {
          customPageUrl
          kind
          order
          schoolProductId
          activeForUser
          title
          id
          enrollmentId
          logo
          logoVertical
        }
      }
    }
  }
`
