import { client } from '@services/api/client'

export async function authUpgrade() {
  return client.post<{ token: string }>(
    `/auth/upgrade`,
    {},
    {
      baseURL: window.location.origin,
    },
  )
}

export async function logout() {
  return client.delete(`/users/sign_out`, {
    baseURL: window.location.origin,
  })
}

export async function magicLinkEmail(email: string, returnURL: string) {
  const params = { user: { email, return_to: returnURL } }

  return client.post(`/magic_link`, params, { baseURL: window.location.origin })
}
