// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/_layout.tsx"); } },
  "./enrollments/[enrollmentId]/courses/[courseId]/online_course/extra_contents/index.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/enrollments/[enrollmentId]/courses/[courseId]/online_course/extra_contents/index.tsx"); } },
  "./enrollments/[enrollmentId]/courses/[courseId]/online_course/index.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/enrollments/[enrollmentId]/courses/[courseId]/online_course/index.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/index.tsx"); } },
  "./orders/[orderId]/payments/index.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/orders/[orderId]/payments/index.tsx"); } },
  "./orders/index.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/orders/index.tsx"); } },
  "./public/orders/[orderId].tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/public/orders/[orderId].tsx"); } },
  "./sign_in/qr.tsx": { enumerable: true, get() { return require("/home/runner/work/members/members/app/sign_in/qr.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;