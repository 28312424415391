import { Order } from '@components/order/Order'
import { Subscription } from '@components/order/Subscription'
import { PaymentMethod } from '@components/order/PaymentMethod'
import { PaymentList } from '@components/order/PaymentList'
import { router, useLocalSearchParams } from 'expo-router'
import { OrdersLayout } from '@layouts/OrdersLayout'
import { isWeb, Text, XStack } from 'tamagui'
import { HeroIcon } from '@components/HeroIcon'
import { Button } from '@components/Button'
import { useCallback } from 'react'
import { OrderDetailsProvider } from '@contexts/OrderDetailsContext'
import { useHasFeature } from '@hooks/useThemeParams'
import { DISABLE_MEMBERS_AREA } from '@constants/features'
export default function OrderDetails() {
  const { orderId } = useLocalSearchParams<{ orderId: string }>()
  const membersAreaDisabled = useHasFeature(DISABLE_MEMBERS_AREA)

  const handleBack = useCallback(() => {
    if (isWeb) window.location.href = '/orders'
    else router.push('/orders')
  }, [])

  return (
    <OrderDetailsProvider orderId={orderId as string}>
      <OrdersLayout>
        {!membersAreaDisabled && (
          <XStack>
            <Button link onPress={handleBack} paddingLeft={0}>
              <HeroIcon
                icon={['fal', 'arrow-left-long']}
                size={16}
                color="$primary-300"
              />
              <Text
                ff="$mulishRegular"
                fontSize="$font-size-xxs"
                color="$primary-300"
                position="relative"
                textDecorationLine="underline"
                top={-4}
                ml={5}
              >
                Voltar para meus pedidos
              </Text>
            </Button>
          </XStack>
        )}
        <Order />
        <Subscription />
        <PaymentMethod />
        <PaymentList />
      </OrdersLayout>
    </OrderDetailsProvider>
  )
}
