import { Modal } from '@components/Modal'
import { YStack, Dialog, useMedia } from 'tamagui'
import React from 'react'
import { Dimensions } from 'react-native'
import { ScrollBar } from '@components/ScrollBar'
import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { MediaRender } from '@components/MediaRender'

type CourseDescriptionModalProps = {
  open: boolean
  onClose: () => void
  description: string | null
}

export function CourseDescriptionModal({
  open,
  description,
  onClose,
}: CourseDescriptionModalProps) {
  const media = useMedia()

  const windowHeight = Dimensions.get('window').height

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPointsMode="fit"
      maxWidth={media.gtSm ? (media.gtMd ? 1064 : 672) : 631}
      shouldAdapt={false}
    >
      {media.sm && (
        <Button position="absolute" top={5} right={5} onPress={onClose}>
          <HeroIcon icon={['fas', 'xmark']} size={16} color="$neutral-800" />
        </Button>
      )}
      <YStack
        padding={media.gtSm ? '$size.spacing-lg' : '$size.spacing-xxs'}
        marginTop="$size.spacing-md"
      >
        <ScrollBar
          maxHeight={
            media.gtSm
              ? media.gtMd
                ? media.gtLg
                  ? 695
                  : windowHeight - 250
                : windowHeight * 0.8
              : windowHeight - 50
          }
        >
          <YStack paddingBottom={media.gtSm ? 0 : 100}>
            <Dialog.Title></Dialog.Title>
            <Dialog.Description>
              {description && (
                <MediaRender
                  media={{
                    title: '',
                    type: 'Text',
                    text: description,
                    id: 1,
                    duration: null,
                    fileUrl: null,
                    fileExt: null,
                    hostKey: null,
                    host: null,
                    organizationUuid: null,
                  }}
                />
              )}
            </Dialog.Description>
          </YStack>
        </ScrollBar>
      </YStack>
    </Modal>
  )
}
