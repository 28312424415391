import { OrderBump } from './OrderBump'
import { useTranslation } from 'react-i18next'
import { H1, H2, Stack, Text, XStack, YStack } from 'tamagui'
import { StatusBadge } from './StatusBadge'
import { useOrderDetails } from '@contexts/OrderDetailsContext'

export function Order() {
  const { t } = useTranslation('OrderDetails')

  const {
    relevantPayment,
    productTitle,
    subscription,
    items,
    id,
    createdAt,
    total,
    isSmartInstallmentORStrategySales,
  } = useOrderDetails()

  const status = subscription?.status || relevantPayment?.status

  return (
    <>
      <H1 color="$neutral-800" fontSize="$font-size-md" ff="$mulishRegular">
        {t('title')}
      </H1>
      <YStack gap="$size.spacing-xxxs">
        <StatusBadge status={status} isSubscription={!!subscription} />
        <H2
          color="$neutral-800"
          fontSize={'$font-size-sm'}
          ff="$mulishSemiBold"
          $gtSm={{
            ff: '$mulishBold',
            fontSize: '$font-size-md',
          }}
          $gtMd={{
            maxWidth: 760,
          }}
        >
          {productTitle}
        </H2>
      </YStack>
      <OrderBump products={items} />
      <YStack gap="$size.spacing-xxxs">
        <YStack
          p="$size.spacing-xs"
          br="$spacing-nano"
          borderWidth="$borderWidth.border-width-hairline"
          borderColor="$neutral-300"
          gap="$size.spacing-xxs"
          $gtSm={{
            padding: 0,
          }}
          $gtMd={{
            maxWidth: 587,
          }}
        >
          <Stack
            flexDirection="column"
            gap="$size.spacing-xxs"
            $gtSm={{
              flexDirection: 'row',
              padding: '$size.spacing-md',
              gap: '$size.spacing-xsl',
            }}
          >
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                {t('orderId')}:{' '}
                <Text
                  color="$neutral-800"
                  ff="$mulishRegular"
                  fontSize="$font-size-xxs"
                >
                  {id}
                </Text>
              </Text>
            </XStack>
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                {t('createdAt')}:{' '}
                <Text
                  color="$neutral-800"
                  ff="$mulishRegular"
                  fontSize="$font-size-xxs"
                >
                  {createdAt}
                </Text>
              </Text>
            </XStack>
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                {isSmartInstallmentORStrategySales
                  ? t('amountPaid')
                  : t('amount')}
                :{' '}
                <Text
                  color="$neutral-800"
                  ff="$mulishRegular"
                  fontSize="$font-size-xxs"
                >
                  {total}
                </Text>
              </Text>
            </XStack>
          </Stack>
        </YStack>
      </YStack>
    </>
  )
}
