import { ApolloError, useQuery } from '@apollo/client'
import { GET_COURSE } from './queries/getCourse'
import { ICourse } from '@dtos/Course'

export const useGetCourse = (id: string) => {
  const { data, loading, error, fetchMore } = useQuery(GET_COURSE, {
    variables: { id },
  })

  return { data, loading, error, fetchMore } as {
    data: ICourse | null
    loading: boolean
    error: ApolloError
    fetchMore: (variables: { id: string }) => void
  }
}
