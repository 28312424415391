import { H2, Spinner, Text, YStack, useWindowDimensions } from 'tamagui'

interface IScreenLockerLoadProps {
  title: string
  description: string
  visible: boolean
}
export function ScreenLockerLoad({
  visible,
  title,
  description,
}: IScreenLockerLoadProps) {
  const { height, width } = useWindowDimensions()
  const BOX_SIDE = 360

  if (!visible) return

  return (
    <>
      <YStack
        position="absolute"
        backgroundColor="$background-100"
        width={width}
        height={height}
        zIndex={9998}
        left={0}
        top={0}
        opacity={0.64}
      />
      <YStack
        position="absolute"
        zIndex={9999}
        bg="$background-100"
        width={width}
        height={height}
        justifyContent="center"
        $gtSm={{
          maxWidth: BOX_SIDE,
          maxHeight: 224,
          top: height / 2 - BOX_SIDE / 2,
        }}
        gap="$size.spacing-md"
        alignSelf="center"
        br="$spacing-nano"
      >
        <Spinner size="large" color="$secundary-400" mb="$size.spacing-xs" />
        <H2
          color="$neutral-800"
          ff="$mulishBold"
          textAlign="center"
          fontSize="$font-size-lg"
        >
          {title}
        </H2>
        <Text
          color="$neutral-800"
          ff="$mulishRegular"
          textAlign="center"
          fontSize="$font-size-xs"
        >
          {description}
        </Text>
      </YStack>
    </>
  )
}
