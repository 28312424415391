import { Shimmer } from '@components/Shimmer'
import { YStack, Stack } from 'tamagui'

export function BannerGhostLoading() {
  return (
    <>
      <Stack flexDirection="column" marginTop={60}>
        <YStack>
          <Shimmer width="100%" />
        </YStack>
      </Stack>
    </>
  )
}
