import { ILessons, ILessonsNodes } from '@dtos/Course'
import { Slider } from '@components/Slider'
import { LessonTitleLink } from './LessonTitleLink'
import { ExtraContentCard } from './ExtraContentCard'
import { isContentAvailable } from '@utils/contentAvailable'
import { GestureReponderEvent } from '@tamagui/core'
import { IOpenModalMediaProps } from './ExtraContentContent'
import { View } from 'tamagui'
import { useEffect, useRef, useState } from 'react'
import { openWebBrowser } from '@utils/openWebBrowser'
import { InView } from 'react-native-intersection-observer'

interface IExtraContentLessonSliderProps {
  lesson: ILessonsNodes
  perView: number
  enrollmentActivatedAt: string
  handlePressDownload: (
    e: GestureReponderEvent,
    link: string | null | undefined,
  ) => void
  handlePressOpenModal: (
    media: IOpenModalMediaProps,
    index: number,
    isMain: boolean,
    nodes: number,
  ) => void
  isLastLesson: boolean
  loadMoreLessonsExtraContents: () => Promise<void>
}

export function ExtraContentLessonSlider({
  lesson,
  perView,
  enrollmentActivatedAt,
  handlePressOpenModal,
  handlePressDownload,
  isLastLesson,
  loadMoreLessonsExtraContents,
}: IExtraContentLessonSliderProps) {
  const lessonRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [loadedMoreLessonsExtraContents, setLoadedMoreLessonsExtraContents] =
    useState(false)

  const lessonLiberationData = {
    releaseAt: lesson.releaseAt,
    releaseAfter: lesson.releaseAfter,
    lockAt: lesson.lockAt,
    lockAfter: lesson.lockAfter,
    createdAt: enrollmentActivatedAt,
  }
  const isLessonAvailable = isContentAvailable(lessonLiberationData)
  const canLoadMoreLessons =
    isVisible && isLastLesson && !loadedMoreLessonsExtraContents

  const handleLessonTitlePress = (lesson: ILessons['nodes'][0]) => {
    const formattedPath = window.location.pathname.split('/online_course')[0]
    openWebBrowser(`${formattedPath}/course_contents/${lesson.courseContentId}`)
  }

  useEffect(() => {
    if (canLoadMoreLessons) {
      loadMoreLessonsExtraContents()
      setLoadedMoreLessonsExtraContents(true)
    }
  }, [canLoadMoreLessons, loadMoreLessonsExtraContents])

  const handleInViewChange = (inView: boolean) => {
    if (inView && !isVisible) {
      setIsVisible(true)
    }
  }

  if (lesson.extraMedias.length === 0 || !isLessonAvailable) return null
  return (
    <InView onChange={(inView: boolean) => handleInViewChange(inView)}>
      <View ref={lessonRef} mb="$size.spacing-md">
        <Slider
          slidesTotalNumber={lesson.extraMedias.length}
          slidesPerView={perView}
          displaySliderControls={lesson.extraMedias.length > perView}
          sliderTitle={
            <LessonTitleLink
              disabled={false}
              handlePress={() => handleLessonTitlePress(lesson)}
              title={lesson.title}
            />
          }
        >
          {lesson.extraMedias.map((extraContent, index) => (
            <ExtraContentCard
              key={extraContent.id}
              extraContent={{
                title: extraContent.title,
                media: extraContent,
                id: extraContent.id,
                ...lessonLiberationData,
                downloadable: true,
              }}
              isLessonAvailable={isContentAvailable(lessonLiberationData)}
              handlePressDownload={(e) =>
                handlePressDownload(e, extraContent.fileUrl)
              }
              handlePressOpenModal={() =>
                handlePressOpenModal(
                  extraContent,
                  index,
                  false,
                  lesson.extraMedias.length,
                )
              }
            />
          ))}
        </Slider>
      </View>
    </InView>
  )
}
