import { Shimmer } from '@components/Shimmer'
import { OrdersLayout } from '@layouts/OrdersLayout'
import { Stack, Text, XStack, YStack } from 'tamagui'

export function GhostLoading() {
  return (
    <OrdersLayout>
      <YStack gap="$size.spacing-xxxs">
        <Shimmer width={146} height={26} />
        <Shimmer maxWidth={250} width="100%" height={26} />
      </YStack>
      <YStack gap="$size.spacing-xxxs">
        <YStack
          p="$size.spacing-xs"
          br="$spacing-nano"
          borderWidth="$borderWidth.border-width-hairline"
          borderColor="$neutral-300"
          gap="$size.spacing-xxs"
          $gtSm={{
            padding: 0,
          }}
          $gtMd={{
            maxWidth: 587,
          }}
        >
          <Stack
            flexDirection="column"
            gap="$size.spacing-xxs"
            $gtSm={{
              flexDirection: 'row',
              padding: '$size.spacing-md',
              gap: '$size.spacing-xsl',
            }}
          >
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                <Shimmer width={70} height={17} display="inline-flex" />
              </Text>
            </XStack>
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                <Shimmer width={70} height={17} display="inline-flex" />
              </Text>
            </XStack>
            <XStack>
              <Text
                textTransform="uppercase"
                color="$primary-300"
                ff="$mulishBold"
                fontSize="$font-size-xxs"
              >
                <Shimmer width={70} height={17} display="inline-flex" />
              </Text>
            </XStack>
          </Stack>
        </YStack>
      </YStack>
      <YStack gap="$size.spacing-xs">
        <Stack
          p="$size.spacing-xs"
          br="$spacing-nano"
          borderWidth="$borderWidth.border-width-hairline"
          borderColor="$neutral-300"
          gap="$size.spacing-xxs"
          $gtSm={{
            flexDirection: 'row',
            gap: '$size.spacing-xsl',
            padding: '$size.spacing-md',
          }}
        >
          <XStack gap="$size.spacing-xxs">
            <XStack space="$size.spacing-xxxs" ai="center">
              <Text
                color="$neutral-800"
                ff="$mulishRegular"
                fontSize="$font-size-xxs"
              >
                <Shimmer width={112} height={17} display="inline-flex" />
              </Text>
            </XStack>
          </XStack>

          <Shimmer
            width="100%"
            height={37}
            display="inline-flex"
            $gtSm={{
              width: 200,
              marginLeft: 'auto',
            }}
          />
        </Stack>
      </YStack>
      <YStack gap="$size.spacing-xs">
        {Array.from({ length: 4 }).map((_, index) => (
          <Stack
            key={index}
            p="$size.spacing-xs"
            br="$spacing-nano"
            borderWidth="$borderWidth.border-width-hairline"
            borderColor="$neutral-300"
            gap="$size.spacing-xxs"
            $gtSm={{
              flexDirection: 'row',
              gap: '$size.spacing-xsl',
              padding: '$size.spacing-md',
              justifyContent: 'space-between',
            }}
          >
            <Shimmer
              width="100%"
              height={32}
              display="inline-flex"
              $gtSm={{
                flex: 1,
              }}
            />
            <Shimmer
              width="100%"
              height={32}
              display="inline-flex"
              $gtSm={{
                flex: 1,
              }}
            />
          </Stack>
        ))}
      </YStack>
    </OrdersLayout>
  )
}
