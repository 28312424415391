import {
  PAYMENT_OVERDUE_STATUS,
  PAYMENT_WAITING_PAYMENT_STATUS,
} from '@constants/payment'
import {
  IBolixInstallmentOrder,
  Order,
  ISmartInstallmentOrder,
  ISubscriptionOrder,
} from '@dtos/Order'
import { getOrder, getPublicOrder } from '@services/api/order'
import { formatDateShort } from '@utils/dateFormatter'
import { formatMoney } from '@utils/moneyFormatter'
import { first } from 'lodash'
import { useQuery } from 'react-query'

const STRATEGY_SUBSCRIPTION_TYPES = ['smart_installment', 'installment']

function checkIsSubscription(order: Order): order is ISubscriptionOrder {
  return order.subscription.subscription_type === 'recurrency'
}

function checkIsSmartInstallment(
  order: Order,
): order is ISmartInstallmentOrder {
  return order.subscription?.subscription_type === 'smart_installment'
}

function checkIsStrategySales(
  order: Order,
): order is ISmartInstallmentOrder | IBolixInstallmentOrder {
  return (
    order.kind === 'common' &&
    STRATEGY_SUBSCRIPTION_TYPES.includes(order.subscription?.subscription_type)
  )
}

function formatPayments(
  payments: Order['payments'],
  isSmartInstallment: boolean,
) {
  const hasBankSlipURL = [
    PAYMENT_WAITING_PAYMENT_STATUS,
    PAYMENT_OVERDUE_STATUS,
  ]
  const formattedPayments = payments
    .sort((a, b) => Date.parse(a.due_at) - Date.parse(b.due_at))
    .map((p) => ({
      amount: formatMoney(p.total_amount_to_be_paid_cents),
      paymentDate: formatDateShort(p.due_at),
      status: p.status,
      bankSlipURL: hasBankSlipURL.includes(p.status)
        ? p.bank_slip_file_url
        : null,
      paymentMethod: p.payment_method,
      dueAt: new Date(p.due_at),
      pixQrCode: p.pix_qr_code,
      id: p.id,
      buyer: p.buyer,
    }))

  const smartInstallmentPaymentsWithoutFirstRefuse =
    formattedPayments[0].status === 'refused' && isSmartInstallment

  if (smartInstallmentPaymentsWithoutFirstRefuse) {
    formattedPayments.shift()
  }

  return formattedPayments
}

type FormattedPayment = ReturnType<typeof formatPayments>[0]

function getTotal(
  isSubscription: boolean,
  isStrategySales: boolean,
  isSmartInstallment: boolean,
  order: Order,
  relevantPayment: FormattedPayment,
) {
  if (isSubscription) return relevantPayment.amount

  if (isStrategySales || isSmartInstallment)
    return formatMoney(
      order.payments.reduce((acc, p) => {
        if (p.status !== 'paid') return acc
        return acc + p.total_amount_to_be_paid_cents
      }, 0),
    )

  return formatMoney(
    order.payments.reduce((acc, p) => {
      if (p.status !== 'paid') return acc
      return acc + p.total_amount_to_be_paid_cents
    }, 0),
  )
}

function formatOrderData(order: Order) {
  const mainItem = order.items.filter((i) => !i.order_bump_item_id)[0]
  const items = order.items.filter((i) => i.order_bump_item_id)

  const isSubscription = checkIsSubscription(order)

  const isStrategySales = checkIsStrategySales(order)

  const isSmartInstallment = checkIsSmartInstallment(order)

  const nextInvoiceAt = formatDateShort(order.subscription.next_invoice_at)

  const createdAt = formatDateShort(order.created_at)

  const payments = formatPayments(order.payments, isSmartInstallment)

  const relevantPayment = first(payments) as FormattedPayment

  const isSmartInstallmentORStrategySales =
    isSmartInstallment || isStrategySales

  const total = getTotal(
    isSubscription,
    isStrategySales,
    isSmartInstallment,
    order,
    relevantPayment,
  )
  const subscription =
    isSubscription || isStrategySales
      ? { ...order.subscription, nextInvoiceAt }
      : null

  return {
    id: order.id,
    productTitle: mainItem.product.name,
    items,
    total,
    isSubscription,
    isStrategySales,
    createdAt,
    payments,
    relevantPayment,
    subscription,
    raw: order,
    isSmartInstallmentORStrategySales,
  }
}

export type FormattedOrderType = ReturnType<typeof formatOrderData>

export function useOrderQuery(orderId: string, isPublic?: boolean) {
  return useQuery(['orders', orderId], async () => {
    const fetchOrder = isPublic ? getPublicOrder : getOrder
    const { data } = await fetchOrder(orderId)
    return formatOrderData(data)
  })
}
