import { MyProductsSection } from '@components/Home/MyProducts/MyProductsSection'
import { useCallback, useEffect, useState } from 'react'
import { useGetUserEnrollments } from '@services/api/graphql/userEnrollments'
import { AppLayout } from '@layouts/AppLayout'
import { EmptyStateComponent } from '@components/EmptyStateComponent'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { getTokens, useMedia, useWindowDimensions, View, YStack } from 'tamagui'
import { IEnrollment } from '@dtos/Enrollment'
import { Banner } from '@components/Home/Banner'
import { HeroCarousel } from '@components/HeroCarousel'
import { ContinueLessonsSection } from '@components/Home/Continue/ContinueLessonsSection'
import { useMembersConfig } from '@hooks/useMembersConfig'
import { ContinueLessonsGhostLoading } from '@components/Home/Continue/ContinueLessonsGhostLoading'
import { BannerGhostLoading } from '@components/Home/Banner/BannerGhostLoading'
import { useGetContinueLessons } from '@services/api/graphql/continueLessons'
import { IContinueLessons } from '@dtos/User'
import { ContinueLessonsProvider } from '@contexts/ContinueLessonsContext'
import { CustomBanners } from '@components/Home/CustomBanners'
import { CustomProductList } from '@components/Home/CustomProductList'
import { BANNER_HEIGHT, BANNER_MAX_HEIGHT } from '@constants/homeBanners'
import { useMediaSize } from '@hooks/useMediaSize'

interface IMyProducts {
  totalProductsNumber: number
  products: IEnrollment[]
}

export default function Home() {
  const [continueLessons, setContinueLessons] = useState<
    IContinueLessons[] | null
  >(null)
  const [myProducts, setMyProducts] = useState<IMyProducts | null>(null)
  const toast = useToastController()
  const { height } = useWindowDimensions()
  const size = useMediaSize()
  const media = useMedia()
  const { t } = useTranslation('Home')
  const {
    data: membersConfigData,
    loading: membersConfigLoading,
    called: membersConfigCalled,
  } = useMembersConfig()
  const {
    getContinueLessons,
    loading: continueLessonsLoading,
    called: continueLessonsCalled,
  } = useGetContinueLessons()
  const {
    getUserEnrollments,
    error: errorUserEnrollments,
    loading: loadingUserEnrollments,
    called: calledUserEnrollments,
  } = useGetUserEnrollments({
    offset: 0,
    limit: 15,
  })

  const isSomethingLoading =
    membersConfigLoading ||
    (loadingUserEnrollments && !calledUserEnrollments) ||
    continueLessonsLoading
  const bannerHeight = height * (BANNER_HEIGHT[size] || BANNER_HEIGHT.xs)
  const bannerMaxHeight = BANNER_MAX_HEIGHT[size] || BANNER_MAX_HEIGHT.xs
  const filteredActiveBanners =
    membersConfigData?.header?.banners.filter((banner) => banner.active) || []
  const showBanners = filteredActiveBanners.length > 0
  const continueLessonsMarginTop = filteredActiveBanners.length > 0 ? 0 : 122
  const showProductsName = membersConfigData?.general?.showProductName ?? true
  const hasContinueLessons = continueLessons && continueLessons.length > 0
  const hasContentAboveTheSection =
    filteredActiveBanners.length > 0 || hasContinueLessons

  const myProductsMarginTop = hasContentAboveTheSection ? 0 : 122

  const setSectionsGaps = () => {
    const sizes = getTokens().size
    if (media.gtXxl) return sizes['$spacing-xxl'].val
    if (media.gtLg) return sizes['$spacing-xsl'].val
    if (media.gtMd) return sizes['$spacing-xl'].val
    return sizes['$spacing-lg'].val
  }

  const handleLoadMoreProducts = useCallback(
    async (offset: number) => {
      try {
        const { data, error } = await getUserEnrollments({
          variables: {
            offset,
            limit: 15,
          },
        })
        if (!error) {
          setMyProducts((prev) => {
            if (!prev) return null
            return {
              ...prev,
              products: [...prev.products, ...data.user.enrollments],
              totalProductsNumber: prev.totalProductsNumber,
            }
          })
        }
      } catch (error) {
        toast.show(t('myProducts.toast.error.title'), {
          message: t('myProducts.toast.error.message'),
          type: 'error',
        })
      }
    },
    [getUserEnrollments, toast, t],
  )

  useEffect(() => {
    const getLessons = async () => {
      try {
        const { data } = await getContinueLessons()
        if (data) {
          setContinueLessons(data.user.lastViewedContents || [])
        } else {
          setContinueLessons([])
        }
      } catch (error) {
        toast.show(t('myProducts.toast.error.title'), {
          message: t('myProducts.toast.error.message'),
          type: 'error',
        })
      }
    }

    if (continueLessons === null) {
      getLessons()
    }
  }, [continueLessons, getContinueLessons, toast, t])

  useEffect(() => {
    const getCourses = async () => {
      try {
        const { data } = await getUserEnrollments()
        if (data) {
          setMyProducts({
            totalProductsNumber: data.user.enrollmentsCount,
            products: data.user.enrollments,
          })
        }
      } catch (error) {
        toast.show(t('myProducts.toast.error.title'), {
          message: t('myProducts.toast.error.message'),
          type: 'error',
        })
      }
    }
    if (myProducts === null) {
      getCourses()
    }
  }, [myProducts, getUserEnrollments, toast, t])

  const hasActiveBanners =
    membersConfigData &&
    !!membersConfigData?.header?.banners.some((banner) => banner.active)
  const isSomethingLoadingAndNotCalled =
    isSomethingLoading && !calledUserEnrollments && !membersConfigCalled
  const hasContent =
    (myProducts && myProducts.products.length !== 0 && membersConfigCalled) ||
    (hasActiveBanners && membersConfigCalled && continueLessonsCalled)
  const displayLoading =
    isSomethingLoadingAndNotCalled ||
    myProducts === null ||
    continueLessons === null

  if (displayLoading)
    return (
      <AppLayout
        backRoute={{
          label: '',
          shouldDisplayLogo: true,
        }}
      >
        <BannerGhostLoading />
        <ContinueLessonsGhostLoading />
      </AppLayout>
    )

  if (errorUserEnrollments && errorUserEnrollments.message !== 'Empty page!') {
    toast.show(t('myProducts.toast.error.title'), {
      message: t('myProducts.toast.error.message'),
      type: 'error',
    })
  }

  return (
    <AppLayout
      backRoute={{
        label: '',
        shouldDisplayLogo: true,
      }}
    >
      {hasContent ? (
        <View gap={setSectionsGaps()} flexGrow={1}>
          {showBanners && (
            <View testID="hero-carousel">
              <HeroCarousel
                height={bannerHeight}
                maxHeight={bannerMaxHeight}
                numberOfSlides={filteredActiveBanners.length}
                displayControls={false}
                autoPlay={true}
                stopOnHover={false}
                autoPlayInterval={10000}
                indicatorsStyles={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 28,
                  zIndex: 1000,
                  flex: 1,
                }}
              >
                {filteredActiveBanners.map((item, index) => {
                  return (
                    <Banner
                      key={index}
                      bannerData={item}
                      height={bannerHeight}
                    />
                  )
                })}
              </HeroCarousel>
            </View>
          )}
          {continueLessons.length > 0 && (
            <ContinueLessonsProvider
              continueLessons={continueLessons}
              isLoading={continueLessonsLoading}
            >
              <View marginTop={continueLessonsMarginTop}>
                <ContinueLessonsSection showProductName={showProductsName} />
              </View>
            </ContinueLessonsProvider>
          )}
          {myProducts && myProducts.products.length > 0 && (
            <YStack
              marginTop={myProductsMarginTop}
              paddingLeft="$size.spacing-xs"
              $gtSm={{
                paddingLeft: '$size.spacing-xsl',
              }}
            >
              <MyProductsSection
                showProductName={showProductsName}
                data={myProducts.products}
                totalProductsNumber={myProducts.totalProductsNumber}
                handleLoadMoreProducts={() =>
                  handleLoadMoreProducts(myProducts.products.length)
                }
              />
            </YStack>
          )}
          <CustomProductList />
          <CustomBanners />
        </View>
      ) : (
        <View
          testID="empty-state-component"
          justifyContent="center"
          paddingHorizontal="$size.spacing-xs"
          $gtSm={{
            paddingHorizontal: '$size.spacing-xsl',
          }}
          flex={1}
          alignItems="center"
        >
          <EmptyStateComponent
            title={t('myProducts.emptyStateComponent.title')}
            description={t('myProducts.emptyStateComponent.description')}
            displayButton={false}
          />
        </View>
      )}
    </AppLayout>
  )
}
