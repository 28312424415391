import DarkNoContentSvg from '@assets/hero/dark-no-content.svg'
import LightNoContentSvg from '@assets/hero/light-no-content.svg'
import { H1, Text, View } from 'tamagui'
import { Button } from '@components/Button'
import { useHasFeature } from '@hooks/useThemeParams'
import { THEME_LIGHT_MODE } from '@constants/features'
import { useComponentTranslation } from '@hooks/useComponentTranslation'

interface IEmptyStateComponentProps {
  title: string
  description: string
  displayButton?: boolean
}

export function EmptyStateComponent({
  title,
  description,
  displayButton = true,
}: IEmptyStateComponentProps) {
  const isLightMode = useHasFeature(THEME_LIGHT_MODE)
  const { t } = useComponentTranslation('EmptyStateComponent')

  return (
    <View
      justifyContent="center"
      alignItems="center"
      flex={1}
      marginTop={100}
      gap={24}
    >
      {isLightMode ? (
        <LightNoContentSvg width={180} height={175} />
      ) : (
        <DarkNoContentSvg width={180} height={175} />
      )}

      <H1
        ff="$mulishBold"
        fontSize="$font-size-md"
        textAlign="center"
        color="$neutral-800"
      >
        {title}
      </H1>
      <Text
        textAlign="center"
        color="$neutral-800"
        ff="$mulishRegular"
        fontSize="$font-size-xxs"
        whiteSpace="break-spaces"
        lineHeight={22}
      >
        {description}
      </Text>
      {displayButton && (
        <Button
          variant="primary"
          aria-label="Back"
          onPress={() => window.location.replace('/enrollments')}
        >
          <Text color="$neutral-0">{t('cta')}</Text>
        </Button>
      )}
    </View>
  )
}
