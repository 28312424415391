import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core'
import { MediaType } from '@dtos/Course'

const DEFAULT_SPREADSHEET_ICON = 'file-spreadsheet'

const documentIcons: { [key: string]: IconName } = {
  '.pdf': 'file-pdf',
  '.xls': DEFAULT_SPREADSHEET_ICON,
  '.xlsm': DEFAULT_SPREADSHEET_ICON,
  '.xlsx': DEFAULT_SPREADSHEET_ICON,
  '.csv': DEFAULT_SPREADSHEET_ICON,
}

const mediaIcons: { [key in string]: IconName } = {
  Audio: 'microphone',
  Text: 'file-word',
  Video: 'play',
  Document: 'file',
  Image: 'image',
  WebinarHerospark: 'video',
  Quiz: 'file-signature',
}

export const LessonTypeTranslation = (
  mediaType: MediaType,
  fileExtension?: string | null,
): IconProp => {
  if (mediaType === 'Document' && fileExtension !== null) {
    return fileExtension
      ? ['fal', documentIcons[fileExtension] || DEFAULT_SPREADSHEET_ICON]
      : ['fal', 'file']
  }
  return mediaType ? ['fal', mediaIcons[mediaType]] : ['fal', 'file']
}
