import { ReactNode, createContext, useEffect, useState } from 'react'
import { IEnrollment, ILessonsProgresses } from '@dtos/Enrollment'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useEnrollment } from '@services/api/graphql/enrollment'
import { useGetLastLessonsViewedContent } from '@services/api/graphql/lastLessonsViewedContent'
import { ILesson } from '@dtos/Course'
import { useLocalSearchParams } from 'expo-router'

interface IEnrollmentData extends IEnrollment {
  lastLessonsViewedContent: { nodes: ILesson[] }
}

interface IEnrollmentProviderProps {
  enrollment?: IEnrollmentData | null
  enrollmentIsLoading: boolean
  error?: Error
  onGetLastLessonsViewed?: () => void
}

export const EnrollmentContext = createContext<IEnrollmentProviderProps>(
  {} as IEnrollmentProviderProps,
)

interface IUseEnrollmentInterface {
  data: {
    enrollment: IEnrollment
  } | null
  loading: boolean
  error: Error | undefined
}

export function EnrollmentProvider({ children }: { children: ReactNode }) {
  const [enrollmentIsLoading, setEnrollmentIsLoading] = useState<boolean>(true)
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { enrollmentId } = useLocalSearchParams()
  const { courseId } = useLocalSearchParams()
  const { data, loading, error } = useEnrollment(
    `${enrollmentId}`,
    'last_view_at',
    'desc',
    `${courseId}`,
  ) as IUseEnrollmentInterface
  const {
    getLastLessonsViewed,
    data: lastLessonsViewedContent,
    loading: lastLessonsViewedLoading,
    called,
  } = useGetLastLessonsViewedContent({
    id: `${courseId}`,
    offset: 0,
    limit: 5,
    lessonsIds: [],
  })

  useEffect(() => {
    if (
      data &&
      !called &&
      data?.enrollment?.progressCard.lessonsProgresses.length > 0
    ) {
      getLastLessonsViewed({
        variables: {
          offset: 0,
          limit: 5,
          lessonsIds: data?.enrollment?.progressCard.lessonsProgresses
            .map((lesson: ILessonsProgresses) => Number(lesson.lessonId))
            .slice(0, 5),
        },
      })
    }
  }, [data, called, getLastLessonsViewed])

  useEffect(() => {
    setEnrollmentIsLoading(loading || lastLessonsViewedLoading)
  }, [loading, lastLessonsViewedLoading])

  if (error) {
    toast.show(t('apolloErrors.enrollment.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (enrollmentIsLoading && !called) {
    return (
      <EnrollmentContext.Provider value={{ enrollmentIsLoading, error }}>
        {children}
      </EnrollmentContext.Provider>
    )
  }

  return (
    <EnrollmentContext.Provider
      value={{
        enrollment: data
          ? {
              ...data.enrollment,
              lastLessonsViewedContent: {
                ...lastLessonsViewedContent?.course.lessons,
              },
            }
          : null,
        enrollmentIsLoading,
        error,
      }}
    >
      {children}
    </EnrollmentContext.Provider>
  )
}
