import { gql } from '@apollo/client'

export const GET_THEME_PARAMS = gql`
  query School($url: String!) {
    school(url: $url) {
      accountType
      address
      cnpj
      companyName
      cpf
      createdAt
      domains
      email
      featuresAvailable
      id
      name
      organizationId
      phone
      logo
      status
      subdomain
      themeId
      updatedAt
    }
  }
`
