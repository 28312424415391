import { AppLayout } from '@layouts/AppLayout'
import { CourseProvider } from '@contexts/CourseContext'
import { ExtraContentContent } from '@components/enrollments/courses/extra_contents/ExtraContentContent'
import { router, useLocalSearchParams } from 'expo-router'
import { H1, Stack, useMedia, XStack } from 'tamagui'
import { LessonExtraContentsProvider } from '@contexts/LessonExtraContentsContext'
import { ExtraContentsProvider } from '@contexts/ExtraContentsContext'
import { EnrollmentProvider } from '@contexts/EnrollmentContext'

export default function ExtraContent() {
  const local = useLocalSearchParams<{ courseName: string }>()
  const media = useMedia()

  const backRoute = () => {
    router.canGoBack() ? router.back() : router.push('/')
  }

  return (
    <CourseProvider>
      <EnrollmentProvider>
        <LessonExtraContentsProvider>
          <ExtraContentsProvider>
            <AppLayout
              backRoute={{
                label: local.courseName || 'Voltar para o curso',
                back: backRoute,
              }}
            >
              <Stack>
                <XStack
                  backgroundColor="$neutral-0"
                  paddingHorizontal="$size.spacing-xs"
                  paddingVertical="$size.spacing-md"
                  paddingTop="$size.spacing-xxl"
                  $gtSm={{
                    paddingBottom: '$size.spacing-xl',
                  }}
                  $gtMd={{
                    paddingHorizontal: '$size.spacing-xsl',
                  }}
                >
                  <H1
                    fontSize={media.gtSm ? '$font-size-lg' : '$font-size-md'}
                    color="$neutral-800"
                    ff="$mulishBold"
                  >
                    Material Complementar
                  </H1>
                </XStack>
                <Stack
                  backgroundColor="$background-100"
                  p={'$size.spacing-xs'}
                  $gtSm={{
                    padding: '$size.spacing-xs',
                  }}
                  $gtMd={{
                    padding: '$size.spacing-xl',
                  }}
                >
                  <ExtraContentContent />
                </Stack>
              </Stack>
            </AppLayout>
          </ExtraContentsProvider>
        </LessonExtraContentsProvider>
      </EnrollmentProvider>
    </CourseProvider>
  )
}
