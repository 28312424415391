import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { AUTH_TOKEN } from '@constants/storages'
import { useAsyncStorage } from '@react-native-async-storage/async-storage'
import { router } from 'expo-router'
import { isWeb } from 'tamagui'

const GET_USER = gql`
  query getUser {
    user {
      firstName
      lastName
      coverImageUrl
      email
      id
    }
  }
`
const SIGN_OUT = gql`
  mutation UserSignOut {
    userSignOut(input: {}) {
      clientMutationId
      status
    }
  }
`

export const useUser = () => {
  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER)

  return { getUser, data, loading, error }
}

export const useSignOut = () => {
  const { removeItem: removeToken } = useAsyncStorage(AUTH_TOKEN)
  const [signOutMutate, { data, loading, error }] = useMutation(SIGN_OUT, {
    onCompleted() {
      removeToken().finally(() => {
        if (isWeb) window.location.href = '/users/sign_out/'
        else router.replace('/sign_in/qr')
      })
    },
  })

  return { signOutMutate, data, loading, error }
}
