import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { Modal } from '@components/Modal'
import { useIsMobile } from '@hooks/useIsMobile'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Circle, Dialog, Text, XStack, YStack } from 'tamagui'

const HERO_SPARK_EMAIL = 'suporte@herospark.com'

interface IPaymentRefundContactProducerModalProps {
  onClose: () => void
  open: boolean
  producerEmail?: string
  producerSchoolName: string
}

export function PaymentRefundContactProducerModal({
  onClose,
  open,
  producerEmail,
  producerSchoolName,
}: IPaymentRefundContactProducerModalProps) {
  const { t } = useTranslation('OrderDetails')
  const isMobile = useIsMobile()

  const handleEmailClick = () => {
    if (producerEmail) {
      window.location.href = `mailto:${producerEmail}`
    }
  }

  const handleHeroSparkEmailClick = () => {
    window.location.href = `mailto:${HERO_SPARK_EMAIL}`
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPoints={isMobile ? [490, 190] : [60, 100]}
      snapPointsMode={isMobile ? 'constant' : 'percent'}
      position={isMobile ? 0 : undefined}
      backdropOpacity={0.9}
      maxWidth={400}
      dismissOnSnapToBottom={isMobile}
      shouldAdapt={isMobile}
    >
      <YStack
        gap="$size.spacing-xs"
        paddingHorizontal="$size.spacing-xs"
        paddingVertical="$size.spacing-md"
        $gtSm={{
          p: '$size.spacing-lg',
          width: 400,
        }}
        height={isMobile ? 'auto' : '100%'}
        justifyContent="flex-start"
        alignItems="center"
      >
        <YStack
          gap={16}
          alignItems="center"
          width="100%"
          maxWidth={400}
          paddingBottom="$size.spacing-md"
        >
          <HeroIcon
            icon={['fal', 'user-headset']}
            color="$neutral-800"
            size={48}
          />
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-md"
            textAlign="center"
            whiteSpace="pre-line"
            $gtSm={{
              paddingHorizontal: 20,
            }}
          >
            {t('refundContactProducer.title')}
          </Dialog.Title>
          <Dialog.Description
            color="$neutral-600"
            fontFamily="$mulishRegular"
            fontSize="$font-size-xs"
            textAlign="center"
            whiteSpace="pre-line"
            lineHeight={21}
          >
            {t('refundContactProducer.description')}
          </Dialog.Description>

          <XStack
            gap={10}
            alignItems="center"
            justifyContent="flex-start"
            backgroundColor="$neutral-200"
            padding={20}
            borderRadius={12}
          >
            <Circle size={32} backgroundColor="$neutral-600">
              <HeroIcon
                icon={['fal', 'user-circle']}
                color="$neutral-300"
                size={16}
              />
            </Circle>
            <YStack gap={4} alignItems="flex-start">
              <Text
                color="$neutral-600"
                fontFamily="$mulishRegular"
                fontSize="$font-size-xxs"
                textAlign="center"
              >
                {producerSchoolName}
              </Text>
              <Text
                color="$primary-300"
                fontFamily="$mulishRegular"
                fontSize="$font-size-xxs"
                textDecorationLine="underline"
                onPress={handleEmailClick}
              >
                {producerEmail}
              </Text>
            </YStack>
          </XStack>

          <Dialog.Description
            color="$neutral-600"
            fontFamily="$mulishRegular"
            fontSize="$font-size-xxs"
            textAlign="center"
            whiteSpace="pre-line"
            lineHeight={21}
          >
            {t('refundContactProducer.otherDetails')}{' '}
            <Text
              color="$neutral-800"
              fontFamily="$mulishRegular"
              fontSize="$font-size-xxs"
              textDecorationLine="underline"
              onPress={handleHeroSparkEmailClick}
            >
              {HERO_SPARK_EMAIL}
            </Text>
          </Dialog.Description>

          <Button
            variant="primary"
            onPress={onClose}
            focusable={false}
            size="large"
            width="100%"
            style={{
              fontWeight: 700,
            }}
          >
            {t('refundContactProducer.closeButton')}
          </Button>
        </YStack>
      </YStack>
    </Modal>
  )
}
