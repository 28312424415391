import { ReactNode, createContext } from 'react'
import { ICourseModulesNodes } from '@dtos/Course'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useGetModuleCourseContents } from '@services/api/graphql/moduleCourseContents'
import { useLocalSearchParams } from 'expo-router'

interface ICourseContents {
  course: {
    courseModules: {
      nodes: ICourseModulesNodes[]
    } | null
  }
}

interface ICourseContentsProviderProps {
  isLoading: boolean
  error?: Error
  getCourseContents?: (
    moduleId: string,
    offset: number,
  ) => Promise<ICourseContents>
}

export const CourseContentsContext =
  createContext<ICourseContentsProviderProps>(
    {} as ICourseContentsProviderProps,
  )

export function CourseContentsProvider({ children }: { children: ReactNode }) {
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { courseId } = useLocalSearchParams()

  const { getModuleCourseContents, loading, error } =
    useGetModuleCourseContents({
      id: `${courseId}`,
      moduleId: '',
      offset: 0,
      limit: 10,
    })

  if (error && error.message !== 'Empty page!') {
    toast.show(t('apolloErrors.lessonExtraContents.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (loading) {
    return (
      <CourseContentsContext.Provider value={{ isLoading: loading, error }}>
        {children}
      </CourseContentsContext.Provider>
    )
  }

  return (
    <CourseContentsContext.Provider
      value={{
        isLoading: false,
        error,
        getCourseContents: async (moduleId: string, offset: number) => {
          try {
            const { data: courseContentsData } = await getModuleCourseContents({
              variables: {
                moduleId,
                offset,
              },
            })
            return courseContentsData
          } catch (error) {
            toast.show(t('apolloErrors.lessonExtraContents.title'), {
              message: t('genericRequestError.message'),
              type: 'error',
            })
          }
        },
      }}
    >
      {children}
    </CourseContentsContext.Provider>
  )
}
