import { gql } from '@apollo/client'

export const GET_PRODUCT_URL = gql`
  query ProductUrl($itemId: ID!, $productListId: ID!) {
    productUrl(
      itemId: $itemId
      productListId: $productListId
      version: "production"
    )
  }
`
