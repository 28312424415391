import { useEffect, useRef } from 'react'
import { Animated } from 'react-native'
import { View, ViewProps } from 'tamagui'

interface IProgressProps extends ViewProps {
  value: number
  height?: number
  backgroundColor?: string
  foregroundColor?: string
  borderRadius?: number
}

export function Progress({
  value,
  height = 8,
  backgroundColor = '$neutral-200',
  foregroundColor = '$info-300',
  borderRadius = 999,
  ...extraProps
}: IProgressProps) {
  const barWidth = useRef(new Animated.Value(0)).current
  const ProgressPercent = barWidth.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  })

  useEffect(() => {
    Animated.timing(barWidth, {
      toValue: value,
      duration: 1200,
      useNativeDriver: false,
    }).start()
  }, [value, barWidth])

  return (
    <View
      w="100%"
      flex={1}
      height={height}
      maxHeight={height}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      {...extraProps}
    >
      <Animated.View
        style={{
          width: ProgressPercent,
          height: '100%',
          borderRadius,
        }}
      >
        <View
          backgroundColor={foregroundColor}
          flex={1}
          borderRadius={borderRadius}
          paddingLeft={value ? 5 : 0}
        ></View>
      </Animated.View>
    </View>
  )
}
