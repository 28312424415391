import { useCallback } from 'react'
import { rudderClient } from '@configs/rudderstack'

type Props = {
  [key: string]: string | number | boolean | undefined
}

export const useRudderstack = () => {
  const identify = useCallback((id: string | number) => {
    rudderClient.identify(id.toString(), {}, {})
  }, [])

  const track = useCallback((event: string, properties?: Props) => {
    rudderClient.track(event, {
      ...properties,
    })
  }, [])
  return {
    track,
    identify,
  }
}
