const DUO_BANNER_HEIGHT = {
  xs: 328,
  sm: 193,
  md: 179,
  lg: 259,
  xl: 300,
  xxl: 300,
}

const TRIO_BANNER_HEIGHT = {
  xs: 167,
  sm: 318,
  md: 178,
  lg: 302,
  xl: 350,
  xxl: 350,
}

const SINGLE_BANNER_HEIGHT = {
  xs: 195,
  sm: 150,
  md: 150,
  lg: 216,
  xl: 250,
  xxl: 250,
}

const CUSTOM_BANNERS_HEIGHTS = {
  duo: DUO_BANNER_HEIGHT,
  trio: TRIO_BANNER_HEIGHT,
  single: SINGLE_BANNER_HEIGHT,
}

export {
  DUO_BANNER_HEIGHT,
  TRIO_BANNER_HEIGHT,
  SINGLE_BANNER_HEIGHT,
  CUSTOM_BANNERS_HEIGHTS,
}
