import { HeroIcon } from '@components/HeroIcon'
import {
  Toast as TamaguiToast,
  useToastController,
  useToastState,
} from '@tamagui/toast'
import { Button } from '@components/Button'
import { YStack, getToken, useWindowDimensions } from 'tamagui'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Container } from '@components/Toast/style'
import { IconName } from '@fortawesome/fontawesome-svg-core'

export function Toast() {
  const currentToast = useToastState()
  const toast = useToastController()
  const safeArea = useSafeAreaInsets()
  const { width } = useWindowDimensions()

  if (!currentToast || currentToast.isHandledNatively) return null

  const mapTypeToIconProps: {
    [key: string]: { icon: IconName; color: string }
  } = {
    success: { color: '$success-100', icon: 'check-circle' },
    error: { color: '$danger-100', icon: 'circle-exclamation' },
    warning: { color: '$warning-100', icon: 'triangle-exclamation' },
    info: { color: '$info-100', icon: 'circle-info' },
  }

  const iconConfig = mapTypeToIconProps[currentToast.type || 'success']

  return (
    <TamaguiToast
      key={currentToast.id}
      duration={currentToast.duration}
      enterStyle={{ opacity: 0, scale: 0.5, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="medium"
      viewportName={currentToast.viewportName}
      borderRadius="$spacing-nano"
      borderWidth={1}
      left={0}
      $gtSm={{
        width: 350,
      }}
      width={width}
    >
      <Container
        variant={currentToast.type}
        padding="$size.spacing-xs"
        paddingTop={safeArea.top + getToken('$spacing-xs', 'size', false)}
      >
        <YStack justifyContent="center">
          <HeroIcon
            icon={['fal', iconConfig.icon]}
            color={iconConfig.color}
            size={40}
          />
        </YStack>
        <YStack flex={1} gap="$size.spacing-nano">
          <TamaguiToast.Title
            ff="$mulishSemiBold"
            fontSize="$font-size-xxs"
            color="$neutral-800"
          >
            {currentToast.title}
          </TamaguiToast.Title>
          {!!currentToast.message && (
            <TamaguiToast.Description
              ff="$mulishRegular"
              fontSize="$font-size-xxs"
              color="$neutral-800"
              lineHeight="$line-height-default"
            >
              {currentToast.message}
            </TamaguiToast.Description>
          )}
        </YStack>
        <YStack justifyContent="flex-start">
          <Button onPress={toast.hide} p={0} top={-5} icon>
            <HeroIcon icon={['fal', 'xmark']} color="$neutral-700" size={16} />
          </Button>
        </YStack>
      </Container>
    </TamaguiToast>
  )
}
