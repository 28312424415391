import {
  ReactNode,
  createContext,
  useEffect,
  useCallback,
  useState,
} from 'react'
import { IThemeParams } from '@dtos/Theme'
import { isWeb } from 'tamagui'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_THEME_PARAMS } from '@services/api/graphql/queries/getThemeParams'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NATIVE_BASE_URL } from '@constants/storages'

interface IThemeParamsProviderProps {
  params: IThemeParams | null
  themeParamsIsLoading: boolean
  error: Error | undefined
}

export const ThemeParamsContext = createContext<IThemeParamsProviderProps>({
  params: null,
  themeParamsIsLoading: true,
  error: undefined,
})

export function ThemeParamsProvider({ children }: { children: ReactNode }) {
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const [themeParamsIsLoading, setThemeParamsIsLoading] = useState(true)
  const [getParams, { data: params, error }] = useLazyQuery(GET_THEME_PARAMS)

  const getURI = useCallback(async () => {
    if (isWeb) return window.location.hostname
    const url = await AsyncStorage.getItem(NATIVE_BASE_URL)
    if (!url) return ''
    return new URL(url).hostname
  }, [])

  useEffect(() => {
    getURI().then((url) =>
      getParams({ variables: { url } }).finally(() =>
        setThemeParamsIsLoading(false),
      ),
    )
  }, [getParams, getURI])

  useEffect(() => {
    if (!error) return
    toast.show(t('genericRequestError.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }, [error, t, toast])

  useEffect(() => {
    if (!params) return

    if (isWeb) document.title = params.school?.name
  }, [params])

  return (
    <ThemeParamsContext.Provider
      value={{
        params,
        themeParamsIsLoading,
        error,
      }}
    >
      {children}
    </ThemeParamsContext.Provider>
  )
}
