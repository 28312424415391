import { gql, useMutation } from '@apollo/client'

const REFUND_PAYMENT = gql`
  mutation PaymentRefund($paymentId: ID!) {
    paymentRefund(input: { paymentId: $paymentId }) {
      message
      status
    }
  }
`

export const useRefundPayment = () => {
  const [refundPayment, { loading, error }] = useMutation(REFUND_PAYMENT)

  const handleRefund = async (paymentId: string) => {
    const response = await refundPayment({
      variables: { paymentId },
    })

    return response.data.paymentRefund
  }

  return { handleRefund, loading, error }
}
