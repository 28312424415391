export const scale = {
  'spacing-nano': 4,
  'spacing-xxxs': 8,
  'spacing-xxs': 12,
  'spacing-xs': 16,
  'spacing-sm': 20,
  'spacing-md': 24,
  'spacing-lg': 32,
  'spacing-xl': 40,
  'spacing-xsl': 48,
  'spacing-xxl': 64,
  true: 4,
}

export const inset = {
  'spacing-inset-quarck': 4,
  'spacing-inset-nano': 8,
  'spacing-inset-xxs': 12,
  'spacing-inset-xs': 16,
  'spacing-inset-sm': 24,
  'spacing-inset-md': 32,
  'spacing-inset-lg': 40,
  true: 4,
}
