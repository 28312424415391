import { View, useMedia } from 'tamagui'
import React, { useState } from 'react'
import { Animated, ScrollView as ReactScrollView } from 'react-native'
import { IOScrollView } from 'react-native-intersection-observer'

interface IScrollBarProps {
  children: React.ReactNode
  maxHeight: number
  displayNativeIndicator?: boolean
}

interface IScrollProps {
  layoutMeasurement: { height: number }
  contentOffset: { y: number }
  contentSize: { height: number }
}

export function ScrollBar({
  children,
  maxHeight,
  displayNativeIndicator = true,
}: IScrollBarProps) {
  const [displayScrollBar, setDisplayScrollBar] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [yPosition, setYPosition] = useState(0)
  const media = useMedia()
  const scrollViewRef = React.useRef<ReactScrollView>(null)

  function calculateScroll({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: IScrollProps) {
    const value = (layoutMeasurement.height / contentSize.height) * 100
    const yPosition =
      contentOffset.y / (contentSize.height / layoutMeasurement.height)
    setScrollPosition(value)
    setYPosition(yPosition)
    if (value >= 100) setDisplayScrollBar(false)
  }

  function firstLoadScroll() {
    scrollViewRef.current?.scrollTo({ y: 1, animated: true })
  }

  const scrollBarPosition = media.gtSm ? 'relative' : 'absolute'
  const scrollBarMarginLeft = media.gtSm ? 14 : 10
  const scrollBarRight = media.gtSm ? 0 : 5
  const scrollBarDisplayNativeIndicator = media.gtSm
    ? false
    : displayNativeIndicator

  return (
    <View
      style={{ flex: 1, flexDirection: 'row', paddingHorizontal: 20 }}
      maxHeight={maxHeight}
    >
      <IOScrollView
        ref={scrollViewRef}
        showsVerticalScrollIndicator={scrollBarDisplayNativeIndicator}
        onLayout={({
          nativeEvent: {
            layout: { height, y },
          },
        }) => {
          firstLoadScroll()
          if (height > maxHeight) setDisplayScrollBar(true)
          calculateScroll({
            layoutMeasurement: { height: 1 },
            contentOffset: { y },
            contentSize: { height },
          })
        }}
        onScroll={(event) => {
          if (maxHeight < event.nativeEvent.contentSize.height && media.gtSm)
            setDisplayScrollBar(true)
          calculateScroll(event.nativeEvent)
        }}
        scrollEventThrottle={16}
      >
        {children}
      </IOScrollView>
      {displayScrollBar && (
        <View
          backgroundColor="$neutral-200"
          style={{
            height: '100%',
            width: 8,
            borderRadius: 8,
            marginLeft: scrollBarMarginLeft,
            right: scrollBarRight,
            position: scrollBarPosition,
          }}
        >
          <Animated.View
            style={{
              width: 8,
              borderRadius: 8,
              backgroundColor: '#FF6485',
              height: `${scrollPosition}%`,
              transform: [{ translateY: yPosition }],
              minHeight: `${scrollPosition}%`,
              maxHeight,
            }}
          />
        </View>
      )}
    </View>
  )
}
