/* eslint-disable camelcase */
import '../src/configs/locales'
import '../src/configs/reactotron'
import { Stack, useNavigationContainerRef } from 'expo-router'
import { StatusBar } from 'expo-status-bar'
import { isWeb } from 'tamagui'

import {
  Mulish_400Regular,
  Mulish_600SemiBold,
  Mulish_700Bold,
} from '@expo-google-fonts/mulish'
import { loadAsync } from 'expo-font'
import { ToastProvider, ToastViewport } from '@tamagui/toast'
import { Toast } from '@components/Toast'
import { SSLProvider } from '@contexts/ScreenLockerLoad'
import { SessionProvider } from '@contexts/SessionContext'
import { QueryClientProvider } from 'react-query'
import { queryClient } from '../src/configs/queryClient'
import { ThemeParamsProvider } from '@contexts/ThemeParamsContext'
import { ThemeProvider } from '@components/ThemeProvider'
import { apolloClient } from '@services/api/apollo'
import { ApolloProvider } from '@apollo/client'
import { LoadingScreen } from '@components/LoadingScreen'
import { useEffect, useState } from 'react'
import 'regenerator-runtime/runtime'
import { startHotjar } from 'src/vendors/hotjar'
import { navigationIntegration } from '@configs/sentry'
import * as Sentry from '@sentry/react-native'

if (isWeb) document.title = 'Carregando...'

function Layout() {
  const [fontsLoaded, setFontsLoaded] = useState(false)

  const ref = useNavigationContainerRef()

  useEffect(() => {
    if (ref) {
      navigationIntegration.registerNavigationContainer(ref)
    }
  }, [ref])

  useEffect(() => {
    if (isWeb) {
      setFontsLoaded(true)
      return
    }

    loadAsync({
      Mulish_400Regular,
      Mulish_600SemiBold,
      Mulish_700Bold,
    }).then(() => {
      setFontsLoaded(true)
    })
  }, [])

  useEffect(() => {
    startHotjar()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider>
          <ToastProvider>
            <SessionProvider>
              <ThemeParamsProvider>
                <SSLProvider>
                  {fontsLoaded ? (
                    <Stack screenOptions={{ headerShown: false }} />
                  ) : (
                    <LoadingScreen />
                  )}
                  <ToastViewport
                    $gtSm={{
                      top: 40,
                      right: 40,
                    }}
                  />
                  <Toast />
                  <StatusBar style="light" />
                </SSLProvider>
              </ThemeParamsProvider>
            </SessionProvider>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
    </QueryClientProvider>
  )
}

export default Sentry.wrap(Layout)
