import { ApolloClient, from, InMemoryCache, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { KeySpecifier } from '@apollo/client/cache/inmemory/policies'
import { isWeb } from 'tamagui'
import { router } from 'expo-router'

const errorLink = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (networkError && networkError.message === 'Failed to fetch') {
      if (isWeb) window.location.href = '/users/sign_in'
      else router.replace('/sign_in/qr')
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message === "Couldn't find User without an ID") {
          if (!isWeb) {
            router.replace('/sign_in/qr')
          }
          return forward(operation)
        }
        if (err.extensions?.code) {
          switch (err.extensions.code) {
            case 'unauthorized': {
              return forward(operation)
            }
            case 'empty_page': {
              return forward(operation)
            }
          }
        }
      }
    }
  },
)

const httpLink = new HttpLink({
  uri: process.env.EXPO_PUBLIC_GRAPHQL_URL,
})

const createFieldPolicy = (keyArgs: KeySpecifier) => ({
  keyArgs,
  merge(existing = { nodes: [] }, incoming: { nodes: [] }) {
    if (!incoming) return { ...existing, nodes: existing.nodes }
    return {
      ...incoming,
      nodes: [...existing.nodes, ...incoming.nodes],
    }
  },
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Course: {
        fields: {
          courseModules: createFieldPolicy(['id']),
        },
      },
    },
  }),
  link: from([errorLink, httpLink]),
})
