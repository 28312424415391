import {
  XStack,
  useMedia,
  Text,
  YStack,
  Circle,
  useWindowDimensions,
  Spinner,
} from 'tamagui'
import { useEffect, useMemo, useState } from 'react'
import { ExtraContentModal } from './ExtraContentModal'
import { MediaRender } from '@components/MediaRender'
import { IExtraContent, ILessons, IMedia } from '@dtos/Course'
import { HeroIcon } from '@components/HeroIcon'
import { isContentAvailable } from '@utils/contentAvailable'
import { useRudderstack } from '@hooks/useAnalytics'
import { useThemeParams } from '@hooks/useThemeParams'
import { useCourse } from '@hooks/useCourse'
import { useLessonExtraContents } from '@hooks/useLessonExtraContents'
import { useExtraContents } from '@hooks/useExtraContents'
import { useEnrollment } from '@hooks/useEnrollment'
import { Slider } from '@components/Slider'
import { ExtraContentCard } from './ExtraContentCard'
import { LessonTitleLink } from './LessonTitleLink'
import { GestureReponderEvent } from '@tamagui/core'
import { ExtraContentGhostLoading } from './ExtraContentGhostLoading'
import { NotSupported } from './NotSupported'
import { useTranslation } from 'react-i18next'
import { ExtraContentLessonSlider } from './ExtraContentLessonSlider'
import { openWebBrowser } from '@utils/openWebBrowser'

interface IDisableBtns {
  isFirstContent: boolean
  isLastContent: boolean
}

interface IModalExtraMediaProps extends IMedia {
  index?: number | null
  isMain?: boolean
  disableBtns?: IDisableBtns
}

type FileLink = string | null | undefined

export interface IOpenModalMediaProps extends IMedia {
  disableBtns?: IDisableBtns
}

export function ExtraContentContent() {
  const [openExtraContentModal, setOpenExtraContentModal] = useState(false)
  const [lessonsExtraContentsState, setLessonsExtraContentsState] = useState<
    ILessons | undefined
  >()
  const [extraMedia, setExtraMedia] = useState<IModalExtraMediaProps | null>(
    null,
  )
  const { course, isLoading } = useCourse()
  const { enrollment, enrollmentIsLoading } = useEnrollment()
  const { extraContents, onLoadMore: loadMoreMainExtraContents } =
    useExtraContents()
  const {
    lessonsExtraContents,
    onLoadMore,
    isLoading: isLoadingLessonExtraContents,
  } = useLessonExtraContents()
  const { data: themeParams, loading: themeParamsIsLoading } = useThemeParams()
  const { track } = useRudderstack()
  const { t } = useTranslation('ExtraContent')
  const media = useMedia()

  const { width } = useWindowDimensions()
  const mediaType =
    extraMedia?.fileExt?.toLowerCase() === 'pdf'
      ? 'pdf'
      : extraMedia?.type || ''

  const cardWidth = () => {
    if (media.gtSm) return 407
    if (media.gtMd) return 324
    return 242
  }
  const containerPadding = useMemo(() => {
    return media.gtMd ? 40 : 16
  }, [media.gtMd])

  const perView = (width - containerPadding) / (cardWidth() + 24)
  const TitlesFontSize = media.gtSm ? '$font-size-md' : '$font-size-sm'
  const someDataIsNull =
    !course || !lessonsExtraContentsState || !extraContents || !enrollment

  useEffect(() => {
    if (lessonsExtraContentsState) return
    setLessonsExtraContentsState(lessonsExtraContents)
  }, [lessonsExtraContentsState, lessonsExtraContents])

  const onLoadMoreLessonsExtraContents = async () => {
    const data = await onLoadMore?.(
      lessonsExtraContentsState?.nodes.length || 0,
    )
    if (data?.lessons) {
      setLessonsExtraContentsState((prevNodes) => {
        return {
          nodes: [...(prevNodes?.nodes || []), ...(data?.lessons?.nodes || [])],
        }
      })
    }
  }

  useEffect(() => {
    if (!course || !themeParams?.school?.id) return
    track('Members Page Extra Content Opened', {
      school_id: themeParams.school.id,
      product_name: course?.name,
      product_type: course?.kind,
    })
  }, [track, themeParams?.school?.id, course])

  const setExtraContentModal = (media: IModalExtraMediaProps) => {
    setExtraMedia(media)
    setOpenExtraContentModal(true)
  }

  const setExtraMediaState = (
    media: IModalExtraMediaProps,
    isMain: boolean,
    actualMediaIndex: number,
  ) => {
    if (!extraMedia || !extraContents) return
    const mediaIndex = extraMedia.index ?? 0
    const isFirstContent = media.index === 0
    const isLastContent =
      mediaIndex + actualMediaIndex === extraContents.nodes.length - 1
    setExtraMedia({
      ...media,
      title: media.title,
      type: media.type,
      index: media.index,
      isMain,
      disableBtns: {
        isFirstContent,
        isLastContent,
      },
    })
  }

  const checkIfContentIsAvailable = (node: IExtraContent) => {
    return isContentAvailable({
      releaseAt: node.releaseAt,
      releaseAfter: node.releaseAfter,
      lockAt: null,
      lockAfter: null,
      createdAt: node.createdAt,
    })
  }

  const changePreviewContent = (i: number) => {
    if (!extraContents) return
    if (
      extraMedia?.index !== undefined &&
      extraMedia?.index !== null &&
      extraMedia?.index + i >= 0
    ) {
      if (extraMedia?.isMain) {
        const isExtraContentAvailable = checkIfContentIsAvailable(
          extraContents.nodes[extraMedia?.index + i],
        )
        if (
          isExtraContentAvailable &&
          extraMedia?.index + i < extraContents.nodes.length
        ) {
          const extraContent = {
            ...extraContents.nodes[extraMedia?.index + i].media,
            index: extraMedia?.index + i,
          }
          setExtraMediaState(extraContent, true, i)
        } else {
          changePreviewContent(i + i)
        }
      } else {
        if (!lessonsExtraContentsState) return
        const lesson = lessonsExtraContentsState.nodes.find((lesson) => {
          return lesson.extraMedias.find((media) => media.id === extraMedia.id)
        })
        if (lesson && extraMedia?.index + i < lesson.extraMedias.length) {
          const nextMedia = lesson.extraMedias[extraMedia?.index + i]
          setExtraMedia({
            ...nextMedia,
            index: extraMedia?.index + i,
            isMain: false,
            disableBtns: {
              isFirstContent: extraMedia?.index + i === 0,
              isLastContent:
                extraMedia?.index + i === lesson.extraMedias.length - 1,
            },
          })
        }
      }
    }
  }

  const handlePressDownload = (e: GestureReponderEvent, link: FileLink) => {
    e.stopPropagation()
    if (link) {
      openWebBrowser(link)
    }
  }

  const handlePressOpenModal = (
    media: IMedia,
    index: number,
    isMain: boolean,
    nodes: number,
  ) => {
    const mediaToOpen = {
      ...media,
      id: media.id,
      type: media.type || null,
      fileUrl: media.fileUrl || null,
      fileExt: media.fileExt || null,
      text: media.text || null,
      title: media.title,
      index,
      isMain,
      disableBtns: {
        isFirstContent: index === 0,
        isLastContent: index === nodes - 1,
      },
    }

    setExtraContentModal(mediaToOpen)
  }

  const formattedData = useMemo(() => {
    if (someDataIsNull) return null
    const filteredLessonsExtraContents = lessonsExtraContentsState.nodes.filter(
      (lesson) => {
        const dataAvailable = {
          releaseAt: lesson.releaseAt,
          releaseAfter: lesson.releaseAfter,
          lockAt: lesson.lockAt,
          lockAfter: lesson.lockAfter,
          createdAt: enrollment.activatedAt,
        }
        return isContentAvailable(dataAvailable)
      },
    )
    return {
      title: course.name,
      extraContents,
      lessons: filteredLessonsExtraContents,
    }
  }, [
    someDataIsNull,
    lessonsExtraContentsState,
    course,
    extraContents,
    enrollment,
  ])

  const isLoadingSomething =
    isLoading ||
    !course ||
    !formattedData ||
    themeParamsIsLoading ||
    enrollmentIsLoading ||
    !enrollment

  if (isLoadingSomething) {
    return <ExtraContentGhostLoading />
  }

  const loadMoreMainSlides = () => {
    loadMoreMainExtraContents?.(formattedData.extraContents.nodes.length)
  }

  const hasExtraContents = formattedData?.extraContents.nodes.length > 0
  const hasLessonsExtraContents = formattedData?.lessons.length > 0

  return (
    <>
      <YStack>
        {hasExtraContents && (
          <>
            <XStack
              alignItems="center"
              marginBottom={16}
              testID="extra-content-title"
            >
              <Circle size={40} bg="$neutral-0" marginRight={8}>
                <HeroIcon
                  icon={['fal', 'books']}
                  color="$primary-300"
                  size={22}
                />
              </Circle>
              <Text
                color="$neutral-600"
                ff="$mulishSemiBold"
                fontSize={TitlesFontSize}
              >
                {t('extraContentTitle')}
              </Text>
            </XStack>
            <Slider
              slidesTotalNumber={formattedData.extraContents.nodes.length}
              slidesPerView={perView}
              displaySliderControls={
                formattedData.extraContents.nodes.length > perView
              }
              loadMoreContent={loadMoreMainSlides}
              sliderTitle={
                <LessonTitleLink
                  disabled={true}
                  handlePress={() => null}
                  title={formattedData.title}
                />
              }
            >
              {formattedData.extraContents.nodes.map(
                (extraContent: IExtraContent, index: number) => {
                  return (
                    <ExtraContentCard
                      key={extraContent.id}
                      extraContent={extraContent}
                      isLessonAvailable={true}
                      handlePressDownload={(
                        e: GestureReponderEvent,
                        link: string | null | undefined,
                      ) => handlePressDownload(e, link)}
                      handlePressOpenModal={() =>
                        handlePressOpenModal(
                          extraContent.media,
                          index,
                          true,
                          formattedData.extraContents.nodes.length,
                        )
                      }
                    />
                  )
                },
              )}
            </Slider>
          </>
        )}
      </YStack>
      {hasLessonsExtraContents && (
        <YStack marginTop={40} testID="lessons-extra-content-title">
          <XStack alignItems="center" marginBottom={16}>
            <Circle size={40} bg="$neutral-0" marginRight={8}>
              <HeroIcon
                icon={['fal', 'book-alt']}
                color="$primary-300"
                size={22}
              />
            </Circle>
            <Text
              color="$neutral-600"
              ff="$mulishSemiBold"
              fontSize={TitlesFontSize}
            >
              {t('lessonsExtraContentTitle')}
            </Text>
          </XStack>
        </YStack>
      )}

      {formattedData.lessons.map((lesson, index) => {
        return (
          <ExtraContentLessonSlider
            key={lesson.id}
            lesson={lesson}
            perView={perView}
            enrollmentActivatedAt={enrollment?.activatedAt}
            handlePressDownload={handlePressDownload}
            handlePressOpenModal={handlePressOpenModal}
            isLastLesson={index === formattedData.lessons.length - 1}
            loadMoreLessonsExtraContents={onLoadMoreLessonsExtraContents}
          />
        )
      })}

      {isLoadingLessonExtraContents && (
        <Spinner size="large" color="$secundary-400" />
      )}

      <ExtraContentModal
        mediaType={mediaType}
        open={openExtraContentModal}
        onClose={() => setOpenExtraContentModal(false)}
        title={extraMedia?.title || ''}
        onChangeContent={(i: number) => changePreviewContent(i)}
        disabledBtns={extraMedia?.disableBtns}
      >
        {extraMedia && (
          <MediaRender media={extraMedia}>
            <NotSupported media={extraMedia} />
          </MediaRender>
        )}
      </ExtraContentModal>
    </>
  )
}
