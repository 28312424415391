import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { Modal } from '@components/Modal'
import { useIsMobile } from '@hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { Dialog, YStack } from 'tamagui'

interface IPaymentRefundErrorModalProps {
  onClose: () => void
  open: boolean
}

export function PaymentRefundErrorModal({
  onClose,
  open,
}: IPaymentRefundErrorModalProps) {
  const { t } = useTranslation('OrderDetails')
  const helpDeskUrl =
    'https://ajuda.herospark.com/hc/pt-br/requests/new?ticket_form_id=15007043729293'

  const isMobile = useIsMobile()

  const handleOpenHelpDesk = () => {
    window.open(helpDeskUrl, '_blank')
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPoints={[60, 100]}
      position={isMobile ? 0 : undefined}
      snapPointsMode={isMobile ? 'constant' : 'percent'}
      backdropOpacity={0.9}
      maxWidth={400}
      dismissOnSnapToBottom={isMobile}
      shouldAdapt={isMobile}
    >
      <YStack
        gap="$size.spacing-xs"
        paddingHorizontal="$size.spacing-xs"
        paddingVertical="$size.spacing-md"
        $gtSm={{
          p: '$size.spacing-lg',
          width: 400,
        }}
        height={isMobile ? 'auto' : '100%'}
        justifyContent="flex-start"
        alignItems="center"
      >
        <YStack
          gap={16}
          alignItems="center"
          width="100%"
          maxWidth={400}
          paddingBottom="$size.spacing-md"
        >
          <HeroIcon
            icon={['fal', 'circle-exclamation']}
            color="$danger-100"
            size={48}
          />
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-md"
            textAlign="center"
            whiteSpace="pre-line"
            $gtSm={{
              paddingHorizontal: 20,
            }}
          >
            {t('refundError.title')}
          </Dialog.Title>
          <Dialog.Description
            color="$neutral-600"
            fontFamily="$mulishRegular"
            fontSize="$font-size-xs"
            textAlign="center"
            whiteSpace="pre-line"
            lineHeight={21}
          >
            {t('refundError.description')}
          </Dialog.Description>
          <Button
            variant="secondary"
            onPress={handleOpenHelpDesk}
            focusable={false}
            size="large"
            width="100%"
            style={{
              fontWeight: 700,
            }}
          >
            {t('refundError.openTicketButton')}
          </Button>
        </YStack>
      </YStack>
    </Modal>
  )
}
