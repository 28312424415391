interface IMoneyFormatOptions {
  useCurrencySymbol?: boolean
}

export function formatMoney(
  centsValue: number,
  options?: IMoneyFormatOptions,
): string {
  const value = centsValue / 100
  const useCurrencySymbol = options?.useCurrencySymbol ?? true

  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)

  if (useCurrencySymbol) return formatted

  return formatted.replace('R$', '').trim()
}
