import { HeroIcon } from '@components/HeroIcon'
import {
  isContentAvailable,
  releaseContentDateText,
} from '@utils/contentAvailable'
import { useMemo, useState } from 'react'
import { Card, XStack, YStack, Text, CardProps, isWeb } from 'tamagui'
import { LessonTypeTranslation } from '@utils/lessonMediaType'
import { GestureReponderEvent, useMedia } from '@tamagui/core'
import { HeroTooltip } from '@components/HeroTooltip'
import { Button } from '@components/Button'
import { IExtraContent } from '@dtos/Course'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IExtraContentCardProps extends CardProps {
  extraContent: IExtraContent
  handlePressDownload: (
    e: GestureReponderEvent,
    link: string | null | undefined,
  ) => void
  handlePressOpenModal: () => void
  isLessonAvailable?: boolean
  availableText?: string
}

export function ExtraContentCard({
  extraContent,
  handlePressDownload,
  handlePressOpenModal,
  isLessonAvailable,
  availableText,
  ...extraProps
}: IExtraContentCardProps) {
  const [isHoveringBtn, setIsHoveringBtn] = useState(false)
  const media = useMedia()
  const { t } = useTranslation('ExtraContent')
  const notDownloadable =
    extraContent.media.type === 'Video' ||
    extraContent.media.type === 'Text' ||
    extraContent.media.fileUrl === null ||
    !extraContent.downloadable

  const displayTitleTooltip = extraContent.title.length > 80

  const cardWidth = () => {
    if (media.gtSm) return 407
    if (media.gtMd) return 324
    return 242
  }

  const cardPadding = media.gtSm ? 24 : 16
  const data = {
    releaseAt: extraContent.releaseAt,
    releaseAfter: extraContent.releaseAfter,
    createdAt: extraContent.createdAt,
    lockAt: null,
    lockAfter: null,
  }
  const isAvailable = isContentAvailable(data) && isLessonAvailable === true
  const extraContentDateText =
    isLessonAvailable === false ? availableText : releaseContentDateText(data)

  const setColors = () => {
    if (notDownloadable) return '$neutral-500'
    return isHoveringBtn ? '$primary-200' : '$primary-300'
  }

  const cursorStyle = isAvailable ? 'pointer' : 'default'
  const cardFlexDirection = media.gtSm ? 'row' : 'column'
  const iconBgColor = isAvailable ? '$background-100' : '$warning-300'
  const iconMediaColor = isAvailable ? '$primary-300' : '#262626'

  const iconContainerHeight = useMemo(() => {
    if (media.gtMd) return 142
    if (media.gtSm) return 116
    return 48
  }, [media])

  const iconName = (): IconProp => {
    if (!isAvailable) return ['fal', 'lock-keyhole']
    return LessonTypeTranslation(
      extraContent.media.type,
      extraContent.media?.fileExt?.toLowerCase() || null,
    )
  }

  const setCardWidth = isWeb ? `${cardWidth()}px !important` : cardWidth()

  const iconContainerWidth = () => {
    if (media.gtSm) return 64
    if (media.gtMd) return 80
    return '100%'
  }

  const handleCardPress = () => {
    if (!isAvailable) return null
    handlePressOpenModal()
  }

  const hoverStyleData = isAvailable
    ? {
        borderColor: '$neutral-800',
        borderWidth: 2,
      }
    : {}

  const titleFontSize = () => {
    if (media.gtXl) return '$font-size-sm'
    if (media.gtSm) return '$font-size-xs'
    return '$font-size-xxs'
  }

  return (
    <Card
      {...extraProps}
      bg="$neutral-0"
      padding={cardPadding}
      flexDirection={cardFlexDirection}
      borderRadius={12}
      borderColor="transparent"
      borderWidth={2}
      gap={16}
      onPress={handleCardPress}
      cursor={cursorStyle}
      minWidth={setCardWidth}
      maxWidth={setCardWidth}
      hoverStyle={hoverStyleData}
    >
      <XStack
        borderRadius={8}
        bg={iconBgColor}
        alignItems="center"
        justifyContent="center"
        height={iconContainerHeight}
        width={iconContainerWidth()}
      >
        <HeroIcon icon={iconName()} color={iconMediaColor} size={24} />
      </XStack>
      <YStack
        flex={1}
        gap={12}
        justifyContent="space-between"
        paddingVertical={3}
      >
        <HeroTooltip
          text={extraContent.title}
          width={200}
          display={displayTitleTooltip}
        >
          <Text
            numberOfLines={3}
            color="$neutral-800"
            ff="$mulishSemiBold"
            fontSize={titleFontSize()}
          >
            {extraContent.title}
          </Text>
        </HeroTooltip>
        {!isAvailable && (
          <XStack gap={4} alignItems="center" testID="unavailable-content-date">
            <HeroIcon
              size={16}
              icon={['fal', 'calendar-alt']}
              color="$neutral-800"
            />
            <Text
              ff="$mulishRegular"
              fontSize="$font-size-xxs"
              color="$neutral-800"
              position="relative"
            >
              {extraContentDateText}
            </Text>
          </XStack>
        )}
        {isAvailable && (
          <HeroTooltip
            text={t('ExtraContentCard.tooltip')}
            width={158}
            display={notDownloadable}
          >
            <Button
              testID="extra-content-download-button"
              variant="primary"
              flex={1}
              aria-label="download"
              outline
              onPress={(e) =>
                handlePressDownload(e, extraContent.media.fileUrl)
              }
              disabled={notDownloadable}
              onHoverIn={() => setIsHoveringBtn(true)}
              onHoverOut={() => setIsHoveringBtn(false)}
            >
              <XStack gap={8} alignItems="center">
                <HeroIcon
                  size={19}
                  icon={['fal', 'download']}
                  color={setColors()}
                />
                <Text
                  ff="$mulishBold"
                  fontSize="$font-size-xxs"
                  marginTop={3}
                  color={setColors()}
                  position="relative"
                >
                  {t('ExtraContentCard.download')}
                </Text>
              </XStack>
            </Button>
          </HeroTooltip>
        )}
      </YStack>
    </Card>
  )
}
