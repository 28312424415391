import { Progress } from '@components/Progress'
import {
  CONTINUE_CARD_DIMENSIONS,
  CONTINUE_CARD_DIMENSIONS_MODAL,
} from '@constants/continueCardDimensions'
import { useMediaSize } from '@hooks/useMediaSize'
import { LessonTypeTranslation } from '@utils/lessonMediaType'
import { useEffect, useState } from 'react'
import { Animated } from 'react-native'
import { Image, View, Text, useMedia, XStack, isWeb } from 'tamagui'
import { LinearGradient } from 'expo-linear-gradient'
import { useOpacityAnimation } from '@hooks/useOpacityAnimation'
import { ExtendedCardContent } from './ExtendedCardContent'
import { HeroIcon } from '@components/HeroIcon'
import { MobileExtendedCardContent } from './MobileExtendedCardContent'
import { IContinueLessons } from '@dtos/User'
import { useTranslation } from 'react-i18next'
import { getVideoThumb } from '@utils/videoThumbs'
import { useScaleAnimation } from '@hooks/useScaleAnimation'
import { openWebBrowser } from '@utils/openWebBrowser'

interface IContinueCardProps {
  lessonProgress?: IContinueLessons
  shouldExtendCard: boolean
  isModalVersion?: boolean
  showProductName: boolean
  productTitle: string
  cardId: string
  customOnPress?: () => Promise<void>
  customThumbnail?: string
  displayPlayIcon?: boolean
  displayBuyTag?: boolean
}

export function ContinueCard({
  lessonProgress,
  shouldExtendCard,
  isModalVersion = false,
  showProductName,
  productTitle,
  cardId,
  customOnPress,
  customThumbnail,
  displayPlayIcon = true,
  displayBuyTag = false,
  ...extraProps
}: IContinueCardProps) {
  const lesson = lessonProgress?.lesson
  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation('CourseDetails')
  const tamaguiMedia = useMedia()
  const size = useMediaSize()
  const isYoutubeOrVimeo = ['Youtube', 'Vimeo'].includes(
    lesson?.media?.host || '',
  )
  const hasThumbnail =
    lesson?.coverImage?.cdnFileUrl || isYoutubeOrVimeo || customThumbnail
  const isActivity = Object.keys(lesson?.activity || {}).length > 0
  const lessonType = isActivity ? 'Quiz' : lesson?.media?.type || 'Text'
  const setIcon = LessonTypeTranslation(lessonType)
  const { interpolatedOpacity, startAnimation } = useOpacityAnimation(300)
  const isAVideo = lesson?.media?.type === 'Video'
  const displayProgress =
    (!shouldExtendCard || isModalVersion) && isAVideo && lessonProgress
  const {
    startAnimation: startScaleAnimation,
    endAnimation: endScaleAnimation,
    scaleValues,
  } = useScaleAnimation(cardId)
  const displayTitleOnHover = !shouldExtendCard
  const isMobile = size === 'sm' || size === 'xs'
  const extendedCardOnMobile = isMobile && isModalVersion
  const setBgColor = isModalVersion ? '$background-100' : '$neutral-0'
  const cardGap = extendedCardOnMobile ? 12 : 0
  const cardBorderRadius = !extendedCardOnMobile || isModalVersion ? 8 : 0
  const imageBorderRadius =
    extendedCardOnMobile || !tamaguiMedia.gtMd || shouldExtendCard ? 8 : 0
  const progressBarBorderRadius = extendedCardOnMobile ? 8 : 0
  const animatedOpacity = tamaguiMedia.gtMd ? interpolatedOpacity : 1
  const playIconOpacity = tamaguiMedia.gtMd ? 1 : 0
  const iconPaddingBottom = tamaguiMedia.gtMd ? 0 : 8
  const displayBottomCardTitle =
    showProductName && !shouldExtendCard && !isModalVersion
  const cardFlexDirection = displayBottomCardTitle ? 'column' : 'row'
  const progressBarHeight = tamaguiMedia.gtSm && !extendedCardOnMobile ? 8 : 4
  const borderCardWidth = tamaguiMedia.gtSm ? 2 : 0
  const title = lesson?.title || ''
  const productName = lessonProgress?.schoolProduct.title || ''

  const cardTittlePadding = () => {
    if (tamaguiMedia.gtMd) return 24
    if (tamaguiMedia.gtSm) return 16
    return 12
  }

  const titleBottomPosition = () => {
    if (!tamaguiMedia.gtMd) return -5
    return isHovered ? 0 : -20
  }

  const continueCardDimensions = () => {
    if (extendedCardOnMobile) return CONTINUE_CARD_DIMENSIONS.modal
    return CONTINUE_CARD_DIMENSIONS[size] || CONTINUE_CARD_DIMENSIONS.xs
  }

  const cardContainerDimensions = () => {
    if (extendedCardOnMobile) return CONTINUE_CARD_DIMENSIONS_MODAL[size]
    if (shouldExtendCard)
      return {
        width: 1208,
        height: continueCardDimensions().height,
      }
    return continueCardDimensions()
  }

  const setIconSize = () => {
    if (extendedCardOnMobile) return 28
    return 36
  }

  const borderColor = () => {
    if (extendedCardOnMobile) return 'transparent'
    return isHovered ? '$neutral-800' : 'transparent'
  }

  const getThumbnail = () => {
    if (customThumbnail)
      return {
        cdnFileUrl: customThumbnail,
      }
    return lesson?.coverImage || null
  }

  const handleLessonPress = async () => {
    if (customOnPress) {
      await customOnPress()
      return
    }
    const courseId = lessonProgress?.progressCard.courseId
    const contentUrl = `/enrollments/${lessonProgress?.enrollment.id}/courses/${courseId}/course_contents/${lesson?.courseContentId}`
    openWebBrowser(contentUrl)
  }

  const productType = () => {
    return t(
      `product_type.${lessonProgress?.schoolProduct.courses?.[0]?.kind || 'online_course'}`,
    )
  }

  const badgeIconsSize = () => {
    if (tamaguiMedia.gtLg) return 20
    if (tamaguiMedia.gtSm) return 16
    return 12
  }

  useEffect(() => {
    startAnimation(isHovered)
  }, [isHovered, startAnimation])

  const cardContainerWidth = isWeb
    ? `${cardContainerDimensions().width}px !important`
    : cardContainerDimensions().width

  return (
    <View
      {...extraProps}
      maxWidth={cardContainerWidth}
      minWidth={cardContainerWidth}
      height={cardContainerDimensions().height}
    >
      <View
        borderRadius={cardBorderRadius}
        borderWidth={borderCardWidth}
        borderColor={borderColor()}
        overflow="hidden"
        maxWidth={cardContainerWidth}
        minWidth={cardContainerWidth}
        minHeight={cardContainerDimensions().height}
        height="100%"
        testID="continue-card"
        onPress={() => handleLessonPress()}
        onHoverIn={() => tamaguiMedia.gtMd && setIsHovered(true)}
        onHoverOut={() => tamaguiMedia.gtMd && setIsHovered(false)}
        flexDirection={cardFlexDirection}
        onMouseEnter={() => startScaleAnimation(cardId)}
        onMouseLeave={() => endScaleAnimation(cardId)}
        cursor="pointer"
        gap={cardGap}
        style={{
          transition: 'all 0.7s ease',
        }}
      >
        <View
          position="relative"
          backgroundColor="$neutral-0"
          borderRadius={cardBorderRadius}
        >
          {displayBuyTag && (
            <XStack
              position="absolute"
              testID="buy-tag"
              right={12}
              top={12}
              alignItems="center"
              gap={8}
              backgroundColor="#262626"
              px={12}
              py={4}
              borderRadius={999}
              zIndex={1}
            >
              <HeroIcon
                icon={['fal', 'bag-shopping']}
                color="#fff"
                size={badgeIconsSize()}
              />
              <Text
                ff="$mulishSemiBold"
                color="#fff"
                fontSize={12}
                $gtLg={{ fontSize: 14 }}
                fontWeight={600}
              >
                {t('productCard.badges.buy')}
              </Text>
            </XStack>
          )}
          <Animated.View
            style={{
              transform: [
                {
                  scale: scaleValues[cardId] || 1,
                },
              ],
            }}
          >
            {hasThumbnail ? (
              <Image
                testID="thumbnail-image"
                source={{
                  uri:
                    getVideoThumb({
                      ...(lesson?.media || {
                        type: 'Video',
                        id: 1,
                        title: '',
                        duration: 0,
                        fileUrl: null,
                        fileExt: null,
                        text: null,
                        hostKey: null,
                        host: null,
                        organizationUuid: null,
                      }),
                      coverImage: getThumbnail(),
                    }) || undefined,
                }}
                style={{
                  width: continueCardDimensions().width,
                  height: continueCardDimensions().height,
                  borderRadius: imageBorderRadius,
                }}
                alt={lesson?.title || ''}
              />
            ) : (
              <View
                testID="media-icon"
                width={continueCardDimensions().width}
                height={continueCardDimensions().height}
                bg={setBgColor}
                alignItems="center"
                justifyContent="center"
                borderRadius="$spacing-xxxs"
              >
                <HeroIcon
                  icon={setIcon}
                  size={setIconSize()}
                  color="$primary-200"
                />
              </View>
            )}
          </Animated.View>
          {displayProgress && (
            <View
              testID="progress-bar"
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              overflow="hidden"
              zIndex={200}
              width={continueCardDimensions().width}
              borderBottomRightRadius={progressBarBorderRadius}
              borderBottomLeftRadius={progressBarBorderRadius}
            >
              <Progress
                height={progressBarHeight}
                borderRadius={0}
                value={lessonProgress?.progress || 0}
                foregroundColor="$info-300"
              />
            </View>
          )}
          {!extendedCardOnMobile && (
            <Animated.View
              style={{
                position: 'absolute',
                width: continueCardDimensions().width,
                bottom: 0,
                paddingHorizontal: cardTittlePadding(),
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                zIndex: 100,
                height: 55,
                opacity: animatedOpacity,
              }}
            >
              {displayPlayIcon && (
                <View
                  backgroundColor="rgba(82, 82, 82, 0.9)"
                  position="absolute"
                  alignItems="center"
                  justifyContent="center"
                  bottom={65}
                  height={55}
                  width={55}
                  borderRadius={100}
                  opacity={playIconOpacity}
                >
                  <HeroIcon
                    icon={['fas', 'play']}
                    size={24}
                    color="$neutral-800"
                    style={{
                      paddingBottom: iconPaddingBottom,
                    }}
                  />
                </View>
              )}
              {displayTitleOnHover && (
                <>
                  <Text
                    ff="$mulishSemiBold"
                    fontSize="$font-size-xxxs"
                    fontWeight={600}
                    color="$neutral-500"
                    numberOfLines={1}
                    position="relative"
                    zIndex={101}
                    bottom={titleBottomPosition()}
                    transition="bottom 0.5s ease"
                    $gtSm={{
                      fontSize: '$font-size-xs',
                    }}
                  >
                    {productTitle}
                  </Text>
                  <LinearGradient
                    colors={['transparent', 'rgba(23,23,23,0.9)']}
                    style={{
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      right: 0,
                      height: 55,
                      zIndex: 100,
                    }}
                  />
                </>
              )}
            </Animated.View>
          )}
        </View>
        {displayBottomCardTitle && (
          <View
            padding={cardTittlePadding()}
            backgroundColor="$neutral-0"
            width={cardContainerDimensions().width}
            flex={1}
            borderBottomLeftRadius={imageBorderRadius}
            borderBottomRightRadius={imageBorderRadius}
          >
            <Text
              ff="$mulishSemiBold"
              fontSize="$font-size-xxxs"
              flexWrap="wrap"
              color="$neutral-800"
              numberOfLines={2}
              ellipsizeMode="tail"
              width="100%"
              $gtSm={{
                fontSize: '$font-size-xs',
              }}
            >
              {title}
            </Text>
          </View>
        )}
        {extendedCardOnMobile ? (
          <MobileExtendedCardContent
            title={lesson?.title || productTitle}
            productName={productName}
          />
        ) : (
          shouldExtendCard && (
            <ExtendedCardContent
              title={title}
              progress={lessonProgress?.progress || 0}
              productName={productName}
              productType={productType()}
              contentType={lesson?.media?.type || null}
            />
          )
        )}
      </View>
    </View>
  )
}
