import { ReactNode, useMemo } from 'react'
import { ScrollView, YStack, getTokens, useMedia } from 'tamagui'

interface IOrdersLayoutProps {
  children: ReactNode
}
export function OrdersLayout({ children }: IOrdersLayoutProps) {
  const media = useMedia()

  const paddingHorizontal = useMemo(() => {
    const sizes = getTokens().size

    return sizes[media.gtSm ? '$spacing-xsl' : '$spacing-xs'].val
  }, [media.gtSm])

  return (
    <ScrollView
      backgroundColor="$background-100"
      contentContainerStyle={{
        paddingHorizontal,
      }}
    >
      <YStack
        marginTop="$spacing-inset-lg"
        p={'$size.spacing-xs'}
        backgroundColor="$neutral-0"
        gap="$size.spacing-md"
        br="$spacing-xxxs"
        width="100%"
        $gtSm={{
          padding: '$size.spacing-md',
        }}
        $gtLg={{
          maxWidth: 1608,
          marginHorizontal: 'auto',
          padding: '$size.spacing-lg',
        }}
      >
        {children}
      </YStack>
    </ScrollView>
  )
}
