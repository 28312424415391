import { createTokens } from 'tamagui'

import { scale, inset } from './space'
import { borderRadius, borderWidth } from './border'

export const tokens = createTokens({
  size: scale,
  space: inset,
  radius: borderRadius,
  borderWidth,
  zIndex: { 'spacing-nano': 0, 1: 100, 2: 200 },
  color: {},
})
