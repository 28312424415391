import { useToastController } from '@tamagui/toast'
import { Redirect } from 'expo-router'
import { useEffect, useMemo, useState } from 'react'
import QRCode from 'react-native-qrcode-svg'
import { H2, Stack, useTheme, isWeb, Spinner } from 'tamagui'
import { useAsyncStorage } from '@react-native-async-storage/async-storage'
import { AUTH_TOKEN, NATIVE_BASE_URL } from '@constants/storages'
import { CameraView, useCameraPermissions } from 'expo-camera'
import { Button } from '@components/Button'
import { useSession } from '@hooks/useSession'
import { AuthenticationInput } from '@components/AuthenticationInput'

export default function QRCodeLogin() {
  const theme = useTheme()
  const { setItem: setBaseURL } = useAsyncStorage(NATIVE_BASE_URL)
  const [permission, requestPermission] = useCameraPermissions()
  const { setItem: setStorageToken, getItem: getToken } =
    useAsyncStorage(AUTH_TOKEN)

  const [scanned, setScanned] = useState(false)
  const [token, setToken] = useState('')
  const toast = useToastController()
  const { signInByToken } = useSession()

  const qrcodeData = useMemo(() => {
    return JSON.stringify({
      token,
      baseURL: isWeb ? window.location.origin : null,
    })
  }, [token])

  // eslint-disable-next-line no-console
  // Console log necessário para copiarmos o QR Code para utilizar no emulador
  console.log('Token de Autenticação =>', qrcodeData)

  useEffect(() => {
    getToken().then((token) => token && setToken(token))
  }, [getToken])

  const handleBarCodeScanned = async ({ data }: { data: string }) => {
    const parsedData = JSON.parse(data) as {
      token: string
      baseURL: string
    }

    await setStorageToken(parsedData.token)
    await setBaseURL(parsedData.baseURL)

    signInByToken(parsedData.token)

    toast.show('Tudo certo!', {
      message: 'Login realizado com sucesso, logo você será redirecionado =)',
    })

    setScanned(true)
  }

  if (scanned && !isWeb) return <Redirect href="/" />

  if (!permission) {
    return (
      <Stack
        bg="$background-100"
        flex={1}
        jc="center"
        alignItems="center"
        gap={50}
      >
        <Spinner size="large" color="$secundary-400" mb="$size.spacing-xs" />
      </Stack>
    )
  }

  if (!isWeb && !permission.granted) {
    return (
      <Stack
        bg="$background-100"
        flex={1}
        jc="center"
        alignItems="center"
        gap={50}
      >
        <H2
          color="$neutral-800"
          fontSize={'$font-size-sm'}
          fontFamily="$mulishBold"
        >
          Você precisa permitir o acesso a câmera
        </H2>
        <Button onPress={requestPermission}>Permitir acesso</Button>
        <AuthenticationInput onTokenSubmit={handleBarCodeScanned} />
      </Stack>
    )
  }

  if (!isWeb && !scanned && permission.granted) {
    return (
      <CameraView
        barcodeScannerSettings={{ barcodeTypes: ['qr'] }}
        style={{ flex: 1 }}
        onBarcodeScanned={handleBarCodeScanned}
        facing="back"
      ></CameraView>
    )
  }

  return (
    <Stack
      bg="$background-100"
      flex={1}
      jc="center"
      alignItems="center"
      gap={50}
    >
      <H2
        color="$neutral-800"
        fontSize={'$font-size-sm'}
        fontFamily="$mulishBold"
      >
        Abra o app do aluno e escaneie o QR Code
      </H2>

      {token ? (
        <QRCode
          value={qrcodeData}
          size={500}
          color={theme['$primary-200']?.val}
          backgroundColor={theme['$neutral-0']?.val}
        />
      ) : (
        <H2
          color="$neutral-800"
          fontSize={'$font-size-sm'}
          fontFamily="$mulishBold"
        >
          Carregando...
        </H2>
      )}
    </Stack>
  )
}
