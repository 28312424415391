import { BadgeProps } from '@components/Badge'
import { PaymentStatusType, SubscriptionStatus } from '@dtos/Order'

type HasOutline = boolean

export type GetStylePropsType = [
  Exclude<BadgeProps['variant'], undefined>,
  HasOutline,
]

const mapGeneralSubscriptionStatusToStyles: {
  [key in SubscriptionStatus]: GetStylePropsType
} = {
  initiated: ['info', true],
  active: ['success', false],
  past_due: ['warning', false],
  inactive: ['disable', false],
  ended: ['disable', true],
  cancelled: ['danger', false],
}

const mapPaymentStatusToStyles: {
  [key in PaymentStatusType]: GetStylePropsType
} = {
  initiated: ['info', true],
  error: ['danger', true],

  paid: ['success', false],
  refused: ['danger', true],
  cancelled: ['danger', false],
  waiting_payment: ['warning', true],
  scheduled: ['info', false],
  overdue: ['disable', false],
  refund_analyzing: ['primary', true],
  chargeback: ['primary', false],
  refunded: ['success', true],
}

export type GetStylePropsParams = {
  status: PaymentStatusType | SubscriptionStatus
  isSubscription: boolean
}
export function getStyleProps({
  status,
  isSubscription,
}: GetStylePropsParams): GetStylePropsType {
  if (!isSubscription) {
    return mapPaymentStatusToStyles[status as PaymentStatusType]
  }

  return mapGeneralSubscriptionStatusToStyles[status as SubscriptionStatus]
}
