import { ReactNode, createContext } from 'react'
import { useGetCourse } from '@services/api/graphql/course'
import { ICourse } from '@dtos/Course'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useLocalSearchParams } from 'expo-router'

interface ICourseProviderProps {
  course?: ICourse
  isLoading: boolean
  error?: Error
  onLoadMore?: () => void
}

export const CourseContext = createContext<ICourseProviderProps>(
  {} as ICourseProviderProps,
)

export function CourseProvider({ children }: { children: ReactNode }) {
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { courseId } = useLocalSearchParams()

  const { data, loading, error } = useGetCourse(`${courseId}`)

  if (error) {
    toast.show(t('apolloErrors.course.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (loading) {
    return (
      <CourseContext.Provider value={{ isLoading: loading, error }}>
        {children}
      </CourseContext.Provider>
    )
  }

  return (
    <CourseContext.Provider
      value={{
        ...data,
        isLoading: false,
        error,
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}
