import { Order } from '@components/order/Order'
import { Subscription } from '@components/order/Subscription'
import { PaymentMethod } from '@components/order/PaymentMethod'
import { PaymentList } from '@components/order/PaymentList'
import { useLocalSearchParams } from 'expo-router'
import { OrdersLayout } from '@layouts/OrdersLayout'
import { OrderDetailsProvider } from '@contexts/OrderDetailsContext'

export default function PublicOrderDetails() {
  const { orderId } = useLocalSearchParams<{ orderId: string }>()

  return (
    <OrderDetailsProvider isPublic orderId={orderId as string}>
      <OrdersLayout>
        <Order />
        <Subscription />
        <PaymentMethod />
        <PaymentList />
      </OrdersLayout>
    </OrderDetailsProvider>
  )
}
