import { MediaType } from '@dtos/Course'
import { useMedia, View, XStack, Text } from 'tamagui'
import { HeroIcon } from '@components/HeroIcon'
import { Button } from '@components/Button'
import { useTranslation } from 'react-i18next'
import { openWebBrowser } from '@utils/openWebBrowser'

interface INotSupportedProps {
  media: {
    title: string
    type: MediaType
    fileUrl?: string | null
    text?: string | null
  } | null
}

export function NotSupported({ media }: INotSupportedProps) {
  const { t } = useTranslation('ExtraContent')
  const mediaT = useMedia()
  const handlePressDownload = () => {
    if (media?.fileUrl) {
      openWebBrowser(media.fileUrl)
    }
  }
  const isVideo = media?.type === 'Video'
  const setColor = isVideo ? '$neutral-500' : '$primary-300'
  const paddingHorizontal = mediaT.gtSm ? 200 : 0
  const paddingVertical = mediaT.gtSm ? 50 : 0

  return (
    <View
      alignItems="center"
      gap={16}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
    >
      <HeroIcon icon={['fal', 'download']} size={40} color="$neutral-800" />
      <Text
        color="$neutral-800"
        ff="$mulishRegular"
        fontSize="$font-size-xs"
        textAlign="center"
        whiteSpace="pre-line"
      >
        {t('NotSupported.title')}
      </Text>
      {media && (
        <Button
          variant="primary"
          aria-label="download"
          outline
          onPress={handlePressDownload}
          disabled={isVideo}
        >
          <XStack gap={8} alignItems="center">
            <HeroIcon size={20} icon={['fal', 'download']} color={setColor} />
            <Text
              fontSize="$font-size-xxs"
              ff="$mulishBold"
              color={setColor}
              position="relative"
            >
              {t('NotSupported.download')}
            </Text>
          </XStack>
        </Button>
      )}
    </View>
  )
}
