import { router } from 'expo-router'
import { isWeb } from 'tamagui'

interface IProductRedirectToEnrollment {
  enrollmentId: string
  courseId?: string | null
  courseContentId?: number | null
  pathId?: number | null
  kind?: string | null
}

export const productRedirectToEnrollment = ({
  enrollmentId,
  courseId,
  courseContentId,
  pathId,
  kind,
}: IProductRedirectToEnrollment) => {
  const baseEnrollmentUrl = `/enrollments/${enrollmentId}`
  const baseCourseUrl = `${baseEnrollmentUrl}/courses/${courseId}`
  const isLiveProduct = kind === 'live_meet'
  const redirectToContentTypes = ['ebook', 'file', 'webinar']
  const shouldRedirectToContent = kind
    ? redirectToContentTypes.includes(kind)
    : false
  const productRedirectToLive = isLiveProduct ? '' : '/online_course'
  const productRedirectUrl = `${baseCourseUrl}${productRedirectToLive}`
  const ebookRedirectToContent = `${baseCourseUrl}/course_contents/${courseContentId}`
  const pathRedirectUrl = `${baseEnrollmentUrl}/paths/${pathId}`

  const redirect = (url: string) => {
    if (isWeb) window.location.href = url
    else router.push(url)
  }

  const getRedirectUrl = () => {
    if (shouldRedirectToContent) {
      return ebookRedirectToContent
    }

    if (pathId) {
      return pathRedirectUrl
    }

    return productRedirectUrl
  }

  redirect(getRedirectUrl())
}
