import { gql } from '@apollo/client'

export const GET_ENROLLMENT = gql`
  query getEnrollment(
    $id: ID!
    $orderBy: String
    $orderDirection: String
    $courseId: ID!
  ) {
    enrollment(id: $id) {
      id
      lessonsCompletedPerModule
      activatedAt
      availableUntil
      certificates(courseId: $courseId) {
        fileUrl
      }
      schoolProduct {
        id
        certification
        certificationMinProgress
        availableTimeLength
        expireDate
      }
      progressCard(courseId: $courseId) {
        id
        progress
        total
        completed
        lessonsProgresses(orderBy: $orderBy, orderDirection: $orderDirection) {
          id
          completed
          progress
          lastViewAt
          lessonId
        }
      }
    }
  }
`
export const GET_ENROLLMENT_IDS_INFO = gql`
  query getEnrollmentIdsInfo($id: ID!) {
    enrollment(id: $id) {
      id
      schoolProduct {
        id
        courses {
          id
          kind
          courseModules(pagination: { offset: 0, limit: 1 }) {
            nodes {
              id
              courseContents(pagination: { offset: 0, limit: 1 }) {
                nodes {
                  id
                }
              }
            }
          }
        }
        path {
          id
        }
      }
    }
  }
`
