import { useState } from 'react'
import { Input } from 'tamagui'

export function AuthenticationInput({
  onTokenSubmit,
}: {
  onTokenSubmit: ({ data }: { data: string }) => Promise<void>
}) {
  const [authToken, setAuthToken] = useState('')

  return (
    <Input
      height={40}
      width="80%"
      paddingHorizontal={16}
      borderWidth={1}
      borderColor="$neutral-300"
      borderRadius={4}
      backgroundColor="$neutral-0"
      placeholder="Digite o código de autenticação"
      placeholderTextColor="$neutral-500"
      selectionColor="$primary-400"
      color="$neutral-800"
      value={authToken}
      onChangeText={setAuthToken}
      onSubmitEditing={() => onTokenSubmit({ data: authToken })}
    />
  )
}
