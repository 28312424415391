import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { Modal } from '@components/Modal'
import { useIsMobile } from '@hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { Dialog, Text, YStack } from 'tamagui'

interface IPaymentRefundGenericErrorModalProps {
  onClose: () => void
  open: boolean
}

export function PaymentRefundGenericErrorModal({
  onClose,
  open,
}: IPaymentRefundGenericErrorModalProps) {
  const { t } = useTranslation('OrderDetails')
  const helpDeskUrl =
    'https://ajuda.herospark.com/hc/pt-br/requests/new?ticket_form_id=15007043729293'

  const isMobile = useIsMobile()

  const handleOpenHelpDesk = () => {
    window.open(helpDeskUrl, '_blank')
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPoints={isMobile ? [370, 190] : [60, 100]}
      snapPointsMode={isMobile ? 'constant' : 'percent'}
      position={isMobile ? 0 : undefined}
      backdropOpacity={0.9}
      maxWidth={400}
      dismissOnSnapToBottom={isMobile}
      shouldAdapt={isMobile}
    >
      <YStack
        gap="$size.spacing-xs"
        paddingHorizontal="$size.spacing-xs"
        paddingVertical="$size.spacing-md"
        $gtSm={{
          p: '$size.spacing-lg',
          width: 400,
        }}
        height={isMobile ? 'auto' : '100%'}
        justifyContent="flex-start"
        alignItems="center"
      >
        <YStack
          gap={16}
          alignItems="center"
          width="100%"
          maxWidth={400}
          paddingBottom="$size.spacing-md"
        >
          <HeroIcon
            icon={['fal', 'circle-exclamation']}
            color="$danger-100"
            size={48}
          />
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-md"
            textAlign="center"
            whiteSpace="pre-line"
            $gtSm={{
              paddingHorizontal: 20,
            }}
          >
            {t('refundGenericError.title')}
          </Dialog.Title>
          <Dialog.Description
            color="$neutral-600"
            fontFamily="$mulishRegular"
            fontSize="$font-size-xs"
            textAlign="center"
            whiteSpace="pre-line"
            lineHeight={21}
          >
            {t('refundGenericError.descriptionPart1')}
            {'\n'}
            <Text
              color="$primary-300"
              fontFamily="$mulishRegular"
              fontSize="$font-size-xs"
              textDecorationLine="underline"
              onPress={handleOpenHelpDesk}
            >
              {t('refundGenericError.openTicketLink')}
            </Text>{' '}
            {t('refundGenericError.descriptionPart2')}
          </Dialog.Description>
          <Button
            variant="primary"
            onPress={onClose}
            focusable={false}
            size="large"
            width="100%"
            style={{
              fontWeight: 700,
            }}
          >
            {t('refundGenericError.openTicketButton')}
          </Button>
        </YStack>
      </YStack>
    </Modal>
  )
}
