export const CARD_DIMENSIONS = {
  horizontal: {
    xs: { height: 115, width: 204 },
    sm: { height: 178, width: 316 },
    md: { height: 168, width: 298 },
    lg: { height: 194, width: 345 },
    xxl: { height: 226, width: 401 },
  },
  vertical: {
    xs: { height: 256, width: 148 },
    sm: { height: 352, width: 204 },
    md: { height: 416, width: 241 },
    lg: { height: 416, width: 241 },
    xxl: { height: 485, width: 281 },
  },
} as const
