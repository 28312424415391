import {
  Dialog,
  YStack,
  XStack,
  useMedia,
  useWindowDimensions,
  View,
  isWeb,
} from 'tamagui'
import { Modal } from '@components/Modal'
import { ScrollBar } from '@components/ScrollBar'
import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { CONTINUE_CARD_DIMENSIONS } from '@constants/continueCardDimensions'
import { useMediaSize } from '@hooks/useMediaSize'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface IContinueLessonsModalProps {
  open: boolean
  onClose: () => void
  lessonCards: JSX.Element[]
  title: string
}

export function ContinueLessonsModal({
  open,
  onClose,
  lessonCards,
  title,
}: IContinueLessonsModalProps) {
  const media = useMedia()
  const safeArea = useSafeAreaInsets()
  const { height, width } = useWindowDimensions()
  const modalPaddingBottom = media.gtSm ? 10 : 50
  const modalMaxPosition = isWeb ? 100 : safeArea.top + safeArea.bottom - 3
  const calcScrollBarMaxHeight = media.gtSm
    ? height * 0.9
    : height - safeArea.top
  const modalCardsGap = media.gtMd ? 20 : 12
  const titlePaddingVertical = media.gtSm
    ? '$size.spacing-md'
    : '$size.spacing-xs'
  const size = useMediaSize()

  const calcModalMaxWidth = () => {
    if (media.gtLg) return 1544
    if (media.gtMd) return 1100
    if (media.gtSm) return 608
    return width
  }

  const cardsPerRow = media.gtLg ? 4 : 3

  const setContainerWidth = () => {
    const gapTotalSpacing = modalCardsGap * cardsPerRow
    const cardsTotalWidth =
      (CONTINUE_CARD_DIMENSIONS[size].width ||
        CONTINUE_CARD_DIMENSIONS.xs.width) * cardsPerRow
    return cardsTotalWidth + gapTotalSpacing
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPointsMode="percent"
      snapPoints={[modalMaxPosition, modalMaxPosition]}
      backdropOpacity={0.64}
      shouldAdapt={false}
      maxWidth={calcModalMaxWidth()}
    >
      <YStack
        paddingLeft="$size.spacing-xs"
        height={calcScrollBarMaxHeight}
        $gtSm={{
          padding: '$size.spacing-lg',
        }}
      >
        <View
          paddingVertical={titlePaddingVertical}
          flexDirection="row"
          justifyContent="space-between"
          $gtMd={{
            paddingTop: 0,
          }}
        >
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishSemiBold"
            fontSize="$font-size-sm"
          >
            {title}
          </Dialog.Title>
          {media.sm && (
            <Button onPress={onClose} icon>
              <HeroIcon
                icon={['fal', 'xmark']}
                color="$neutral-800"
                size={12}
              />
            </Button>
          )}
        </View>
        <ScrollBar
          maxHeight={calcScrollBarMaxHeight}
          displayNativeIndicator={false}
        >
          <XStack
            flexWrap="wrap"
            gap={modalCardsGap}
            paddingBottom={modalPaddingBottom}
            $gtMd={{
              width: setContainerWidth(),
            }}
          >
            {lessonCards}
          </XStack>
        </ScrollBar>
      </YStack>
    </Modal>
  )
}
