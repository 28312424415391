import { isWeb } from 'tamagui'
import * as WebBrowser from 'expo-web-browser'

export function openWebBrowser(url: string, shouldOpenInNewTab = false) {
  if (isWeb) {
    if (shouldOpenInNewTab) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
  } else {
    WebBrowser.openBrowserAsync(url)
  }
}
