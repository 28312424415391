import { Button } from '@components/Button'
import { HeroCarousel } from '@components/HeroCarousel'
import {
  YStack,
  Text,
  XStack,
  Stack,
  useMedia,
  Circle,
  Image,
  isWeb,
} from 'tamagui'
import { useTranslation } from 'react-i18next'
import { HeroTooltip } from '@components/HeroTooltip'
import { ICourseContentsNodes, ILesson } from '@dtos/Course'
import { HeroIcon } from '@components/HeroIcon'
import {
  formatDateShort,
  differenceInDays,
  addDays,
} from '@utils/dateFormatter'
import { LessonTypeTranslation } from '@utils/lessonMediaType'
import { useLocalSearchParams, useRouter } from 'expo-router'
import { IEnrollment } from '@dtos/Enrollment'
import { useCourse } from '@hooks/useCourse'
import { useModules } from '@hooks/useModules'
import { useLessonExtraContents } from '@hooks/useLessonExtraContents'
import { useExtraContents } from '@hooks/useExtraContents'
import { CourseDescription } from './CourseDescription'
import { useCallback, useMemo } from 'react'
import { isContentAvailable } from '@utils/contentAvailable'
import { getVideoThumb } from '@utils/videoThumbs'
import { LastViewedLessonSlide } from './LastViewedLessonSlide'
import { useMediaSize } from '@hooks/useMediaSize'
import { openWebBrowser } from '@utils/openWebBrowser'
import { Progress } from '@components/Progress'

const SLIDE_DIMENSIONS = {
  xs: { height: 141, width: 256 },
  sm: { height: 141, width: 256 },
  md: { height: 296, width: 490 },
  lg: { height: 400, width: 680 },
  xxl: { height: 400, width: 680 },
} as const

interface ICourseDetailsProps extends IEnrollment {
  lastLessonsViewedContent: ILesson[] | undefined
}

export function CourseDetails({
  enrollment,
}: {
  enrollment: ICourseDetailsProps
}) {
  const { course } = useCourse()
  const { modules } = useModules()
  const { extraContents } = useExtraContents()
  const { courseId, enrollmentId } = useLocalSearchParams()
  const { lessonsExtraContents } = useLessonExtraContents()
  const { t } = useTranslation('CourseDetails')
  const router = useRouter()
  const media = useMedia()
  const size = useMediaSize()
  const textTransform = isWeb ? 'translate(-49%, 0)' : 'unset'
  const lastLessonViewedMarginTop = media.gtSm ? 0 : 74
  const courseDetailsMarginBottom = media.gtSm ? 100 : 44

  const courseNameFontSize = () => {
    if (media.gtLg) return '$font-size-xxl'
    if (media.gtSm) return '$font-size-lg'
    return '$font-size-md'
  }

  const firstAvailableLessonFontSize = () => {
    if (media.gtXl) return '$font-size-lg'
    if (media.gtSm) return '$font-size-md'
    return '$font-size-xs'
  }

  const handleExtraContentRedirect = () => {
    router.push({
      pathname: `/enrollments/${enrollmentId}/courses/${courseId}/online_course/extra_contents`,
      params: { courseName: course?.name },
    })
  }
  const handleFirstAccess = (id: number) => {
    const formattedPath = window.location.pathname.split('/online_course')[0]
    window.location.href = `${window.location.origin}${formattedPath}/course_contents/${id}`
  }

  const courseCompleted: boolean = useMemo(() => {
    if (!enrollment) return false
    return (
      enrollment.progressCard.progress >=
      enrollment.schoolProduct.certificationMinProgress
    )
  }, [enrollment])

  const certificateButtonVariant = courseCompleted ? 'secondary' : 'disabled'

  const accessDays = useMemo(() => {
    if (enrollment.availableUntil) {
      return {
        days: differenceInDays(
          new Date().toDateString(),
          enrollment.availableUntil,
        ),
        expireDate: enrollment.availableUntil,
      }
    }
    if (enrollment.schoolProduct.expireDate) {
      return {
        days: differenceInDays(
          new Date().toDateString(),
          enrollment.schoolProduct.expireDate,
        ),
        expireDate: enrollment.schoolProduct.expireDate,
      }
    } else if (enrollment.schoolProduct.availableTimeLength) {
      return {
        days: differenceInDays(
          new Date().toDateString(),
          addDays(
            enrollment.activatedAt,
            enrollment.schoolProduct.availableTimeLength,
          ),
        ),
        expireDate: addDays(
          enrollment.activatedAt,
          enrollment.schoolProduct.availableTimeLength,
        ),
      }
    }
    return null
  }, [
    enrollment?.schoolProduct.expireDate,
    enrollment?.activatedAt,
    enrollment?.schoolProduct.availableTimeLength,
    enrollment?.availableUntil,
  ])

  const badgeStyle =
    accessDays && accessDays.days <= 7 ? '$danger-300' : '$info-300'
  const accessDaysLabel =
    accessDays && accessDays.days <= 7
      ? 'Atenção! acesso acaba em:'
      : 'Acesso liberado até:'

  const checkIfContentIsAvailable = useCallback(
    (lesson: ILesson) => {
      const dataAvailable = {
        releaseAt: lesson.releaseAt,
        releaseAfter: lesson.releaseAfter,
        lockAt: lesson.lockAt,
        lockAfter: lesson.lockAfter,
        createdAt: enrollment.activatedAt,
      }
      return isContentAvailable(dataAvailable)
    },
    [enrollment],
  )

  const firstAvailableLesson = useMemo(() => {
    if (!modules) return null

    const getAvailableLessons = (contents: ICourseContentsNodes[]) =>
      contents.filter(
        (content) =>
          content.available !== false &&
          checkIfContentIsAvailable(content.lesson),
      )

    for (const module of modules.nodes) {
      if (module.available === false) continue

      const availableLessons = getAvailableLessons(module.courseContents.nodes)
      if (availableLessons.length) return availableLessons[0]

      for (const submodule of module.courseModules) {
        const availableSubLessons = getAvailableLessons(
          submodule.courseContents.nodes,
        )
        if (availableSubLessons.length) return availableSubLessons[0]
      }
    }

    return null
  }, [modules, checkIfContentIsAvailable])

  const lastViewedLessonsNumber =
    enrollment.lastLessonsViewedContent?.length || 0
  const isYoutubeOrVimeo = ['Youtube', 'Vimeo'].includes(
    firstAvailableLesson?.lesson.media?.host || '',
  )
  const displayThumbnail =
    firstAvailableLesson?.lesson.coverImage?.cdnFileUrl || isYoutubeOrVimeo
  const lastViewedSlideSizes = () => {
    return SLIDE_DIMENSIONS[size] || SLIDE_DIMENSIONS.xs
  }

  const hasExtraContent: boolean = useMemo(() => {
    if (
      !lessonsExtraContents ||
      !extraContents ||
      !Object.keys(extraContents).length
    )
      return false
    const lessonsExtraContent = lessonsExtraContents.nodes.find((lesson) => {
      if (!lesson.extraMedias) return false
      const dataAvailable = {
        releaseAt: lesson.releaseAt,
        releaseAfter: lesson.releaseAfter,
        lockAt: lesson.lockAt,
        lockAfter: lesson.lockAfter,
        createdAt: enrollment.activatedAt,
      }
      return isContentAvailable(dataAvailable)
    })
    return extraContents.nodes.length > 0 || !!lessonsExtraContent
  }, [lessonsExtraContents, extraContents, enrollment])

  if (!course || !modules) return null

  const marginWithoutDescription = media.gtSm && !course.description ? 40 : 0
  const setProgressColor =
    enrollment.progressCard.progress === 100 ? '$success-300' : '$info-300'

  const redirectToLesson = (args: ILesson) => {
    const formattedPath = window.location.pathname.split('/online_course')[0]
    window.location.href = `${window.location.origin}${formattedPath}/course_contents/${args.courseContentId}`
  }

  const getMainButtonConfig = () => {
    if (
      enrollment.lastLessonsViewedContent &&
      enrollment.lastLessonsViewedContent.length
    ) {
      const id = enrollment.lastLessonsViewedContent[0].courseContentId
      return {
        label: 'Continuar',
        onPress: () => handleFirstAccess(Number(id)),
      }
    }
    if (firstAvailableLesson) {
      return {
        label: 'Iniciar',
        onPress: () => handleFirstAccess(firstAvailableLesson.id),
      }
    }
  }

  const handleCertificatePress = () => {
    if (!enrollment.certificates?.length) return
    openWebBrowser(enrollment.certificates[0]?.fileUrl)
  }

  const buttonConfig = getMainButtonConfig()

  return (
    <Stack
      flexDirection="column"
      marginBottom={marginWithoutDescription}
      paddingHorizontal={'$size.spacing-xs'}
      flex={1}
      mt={0}
      mb={courseDetailsMarginBottom}
      $gtSm={{
        paddingHorizontal: '$size.spacing-xs',
      }}
      $gtMd={{
        marginTop: 40,
        paddingHorizontal: '$size.spacing-xsl',
      }}
      $gtLg={{
        width: '100%',
        maxWidth: 1608,
        marginHorizontal: 'auto',
        paddingHorizontal: '$size.spacing-lg',
      }}
    >
      <Stack
        $gtSm={{
          flexDirection: 'row',
        }}
      >
        <YStack
          marginBottom={marginWithoutDescription}
          $gtSm={{ width: '50%' }}
          $gtMd={{ alignSelf: 'baseline' }}
        >
          <Text
            color="$neutral-700"
            ff="$mulishRegular"
            fontSize="$font-size-xxxs"
            marginVertical={12}
          >
            {t(`product_type.${course.kind}`)}
          </Text>
          {accessDays && (
            <XStack
              borderRadius="$border-radius-pill"
              padding={8}
              marginBottom={12}
              bg="$neutral-100"
              alignItems="center"
            >
              <Circle bg={badgeStyle} size="$spacing-md" marginRight={8}>
                <HeroIcon
                  icon={['fal', 'calendar-alt']}
                  size={16}
                  color="$neutral-0"
                />
              </Circle>
              <Text
                color="$neutral-800"
                ff="$mulishBold"
                fontSize="$font-size-xs"
                marginRight={5}
              >
                {accessDaysLabel}
              </Text>
              <Text
                color={badgeStyle}
                ff="$mulishBold"
                fontSize="$font-size-xs"
              >
                {formatDateShort(accessDays.expireDate)}
              </Text>
            </XStack>
          )}
          <Text
            color="$neutral-800"
            ff="$mulishBold"
            fontSize={courseNameFontSize()}
            marginBottom={24}
          >
            {course.name}
          </Text>
          <YStack>
            <Progress
              value={enrollment.progressCard.progress}
              height={8}
              foregroundColor={setProgressColor}
            />
            <XStack marginTop={8} flex={1} justifyContent="space-between">
              <XStack gap={5}>
                <Text
                  color="$success-300"
                  ff="$mulishBold"
                  fontSize="$font-size-xs"
                >
                  {enrollment.progressCard.completed}
                </Text>
                <Text
                  color="$neutral-600"
                  ff="$mulishBold"
                  fontSize="$font-size-xs"
                >
                  de {enrollment.progressCard.total} conteúdos
                </Text>
              </XStack>
              {enrollment.progressCard.progress > 0 && (
                <XStack gap={5}>
                  <Text
                    color="$success-300"
                    ff="$mulishBold"
                    fontSize="$font-size-xs"
                  >
                    {Math.floor(enrollment.progressCard.progress)}%
                  </Text>
                  <Text
                    color="$neutral-600"
                    ff="$mulishBold"
                    fontSize="$font-size-xs"
                  >
                    concluído
                  </Text>
                </XStack>
              )}
            </XStack>
          </YStack>
          <XStack
            gap={12}
            marginTop={24}
            $sm={{
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
          >
            {enrollment.progressCard.progress !== 100 && (
              <Button variant="primary" onPress={buttonConfig?.onPress}>
                {buttonConfig?.label}
              </Button>
            )}

            {hasExtraContent && enrollment?.schoolProduct.certification ? (
              <XStack gap={12}>
                <Button
                  variant="secondary"
                  flex={1}
                  onPress={handleExtraContentRedirect}
                  $xs={{
                    paddingHorizontal: '2px',
                  }}
                >
                  Material complementar
                </Button>

                <HeroTooltip
                  text={`Complete ${enrollment.schoolProduct.certificationMinProgress}% de progresso para receber o certificado`}
                  width={158}
                  display={!courseCompleted}
                >
                  <Button
                    variant={certificateButtonVariant}
                    outline={!courseCompleted}
                    disabled={!courseCompleted}
                    onPress={handleCertificatePress}
                  >
                    Certificado
                  </Button>
                </HeroTooltip>
              </XStack>
            ) : hasExtraContent ? (
              <Button
                variant="secondary"
                onPress={handleExtraContentRedirect}
                $xs={{
                  paddingHorizontal: '2px',
                }}
              >
                Material complementar
              </Button>
            ) : enrollment?.schoolProduct.certification ? (
              <HeroTooltip
                text={`Complete ${enrollment.schoolProduct.certificationMinProgress}% de progresso para receber o certificado`}
                width={158}
                display={!courseCompleted}
              >
                <Button
                  flex={1}
                  variant={certificateButtonVariant}
                  outline={!courseCompleted}
                  disabled={!courseCompleted}
                  onPress={handleCertificatePress}
                >
                  Certificado
                </Button>
              </HeroTooltip>
            ) : null}
          </XStack>
          {(!media.gtSm || media.gtMd) && course.description && (
            <CourseDescription description={course.description} />
          )}
        </YStack>
        <YStack
          marginTop={lastLessonViewedMarginTop}
          $gtSm={{ width: '50%', alignSelf: 'flex-start', paddingLeft: 12 }}
        >
          {enrollment.lastLessonsViewedContent?.length ? (
            <>
              <HeroCarousel
                height={lastViewedSlideSizes().height}
                numberOfSlides={lastViewedLessonsNumber}
                displayControls={!!isWeb}
                isCarousel={true}
                sliderTitle={
                  <Text
                    width={lastViewedSlideSizes().width}
                    left="10%"
                    transform={textTransform}
                    position="absolute"
                    marginBottom={16}
                    fontSize="$font-size-xs"
                    ff="$mulishSemiBold"
                    top={-30}
                    color="$neutral-600"
                    $gtSm={{ left: '50%' }}
                  >
                    Visto por último
                  </Text>
                }
              >
                {enrollment.lastLessonsViewedContent.map((item, index) => (
                  <LastViewedLessonSlide
                    key={item.id}
                    item={item}
                    width={lastViewedSlideSizes().width}
                    height={lastViewedSlideSizes().height}
                    idx={index}
                    isClickable={true}
                    onPressSlide={redirectToLesson}
                  />
                ))}
              </HeroCarousel>
            </>
          ) : firstAvailableLesson ? (
            <YStack paddingHorizontal={40}>
              <Text
                fontSize="$font-size-xxs"
                ff="$mulishBold"
                color="$neutral-600"
                marginBottom={32}
              >
                Comece por aqui:
              </Text>
              {displayThumbnail ? (
                <Image
                  style={{ cursor: 'pointer' }}
                  onPress={() => handleFirstAccess(firstAvailableLesson.id)}
                  width={256}
                  height={141}
                  alt="Imagem do curso"
                  borderRadius={8}
                  source={{
                    width: lastViewedSlideSizes().width,
                    height: lastViewedSlideSizes().height,
                    uri:
                      getVideoThumb({
                        ...firstAvailableLesson.lesson.media,
                        coverImage: firstAvailableLesson.lesson.coverImage,
                      }) || undefined,
                  }}
                />
              ) : (
                <XStack
                  bg="$background-100"
                  alignItems="center"
                  borderRadius={8}
                  justifyContent="center"
                  style={{ cursor: 'pointer' }}
                  onPress={() => handleFirstAccess(firstAvailableLesson.id)}
                  height={lastViewedSlideSizes().height}
                >
                  <HeroIcon
                    icon={LessonTypeTranslation(
                      firstAvailableLesson.lesson.activity !== null
                        ? 'Quiz'
                        : firstAvailableLesson.lesson.media?.type,
                    )}
                    size={70}
                    color="$primary-300"
                  />
                </XStack>
              )}

              <Text
                marginTop={16}
                fontSize={firstAvailableLessonFontSize()}
                color="$neutral-800"
                ff="$mulishSemiBold"
              >
                {firstAvailableLesson.lesson.title}
              </Text>
            </YStack>
          ) : null}
        </YStack>
      </Stack>
    </Stack>
  )
}
