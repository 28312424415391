import { gql } from '@apollo/client'

export const GET_PRODUCTS_CUSTOM_LIST = gql`
  query ProductsCustomList($id: ID!, $offset: Int!, $limit: Int!) {
    membersConfig(version: "production") {
      id
      customProductLists(id: $id) {
        active
        id
        kind
        order
        title
        items(pagination: { limit: $limit, offset: $offset }) {
          customPageUrl
          kind
          order
          activeForUser
          schoolProductId
          title
          logo
          id
          enrollmentId
          logoVertical
        }
      }
    }
  }
`
