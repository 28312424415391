import {
  ApolloError,
  OperationVariables,
  QueryResult,
  useLazyQuery,
  useQuery,
} from '@apollo/client'
import {
  GET_ENROLLMENT,
  GET_ENROLLMENT_IDS_INFO,
} from './queries/getEnrollment'
import { IEnrollment } from '@dtos/Enrollment'

export const useEnrollment = (
  id: string,
  orderBy: string,
  orderDirection: string,
  courseId: string,
) => {
  const { data, loading, error } = useQuery(GET_ENROLLMENT, {
    variables: { id, orderBy, orderDirection, courseId },
  })

  return { data, loading, error } as {
    data: IEnrollment | null
    loading: boolean
    error: ApolloError
  }
}

export const useEnrollmentIdsInfo = () => {
  const [getEnrollmentIdsInfo, { data, loading, error }] = useLazyQuery(
    GET_ENROLLMENT_IDS_INFO,
  )

  return { getEnrollmentIdsInfo, data, loading, error } as {
    getEnrollmentIdsInfo: (
      variables: OperationVariables,
    ) => Promise<QueryResult<{ enrollment: IEnrollment }, OperationVariables>>
    data: IEnrollment | null
    loading: boolean
    error: ApolloError
  }
}
