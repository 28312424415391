import { HeroIcon } from '@components/HeroIcon'
import { ProductCardContent } from './ProductCardContent'
import { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'
import { XStack, Text, View, Image, useMedia, isWeb } from 'tamagui'
import { LinearGradient } from 'expo-linear-gradient'
import { Progress } from '@components/Progress'
import { useScaleAnimation } from '@hooks/useScaleAnimation'
import { useMediaSize } from '@hooks/useMediaSize'
import { isContentAvailable } from '@utils/contentAvailable'
import { formatDateShort } from '@utils/dateFormatter'
import { useTranslation } from 'react-i18next'
import { CARD_DIMENSIONS } from '@constants/productCardDimension'
import { productRedirectToEnrollment } from '@utils/productRedirectToEnrollment'

interface IProductCardProps {
  data: {
    enrollmentId?: string
    id: string
    courseId?: string | null
    availableUntil?: string
    activatedAt?: string
    progress?: number
    completedLessons?: number
    totalLessons?: number
    title?: string
    logo?: string
    logoVertical?: string
    kind?: string | undefined | null
    hasLiveSoon?: boolean
    pathId?: number | null
    courseContentId?: number | null
  }
  hideProgressInformation?: boolean
  isHorizontal?: boolean
  hasAuxiliarTitle?: boolean
  displayBuyTag?: boolean
  customOnPress?: () => Promise<void>
}

export function ProductCard({
  isHorizontal = true,
  data,
  hasAuxiliarTitle,
  displayBuyTag,
  customOnPress,
  hideProgressInformation = false,
  ...extraProps
}: IProductCardProps) {
  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation('Home')
  const media = useMedia()
  const animatedOpacity = useRef(new Animated.Value(0)).current
  const interpolatedOpacity = animatedOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  })
  const size = useMediaSize()
  const { startAnimation, endAnimation, scaleValues } = useScaleAnimation(
    data.enrollmentId || data.id,
  )

  const cardDimensions = () => {
    const orientation = isHorizontal ? 'horizontal' : 'vertical'

    return CARD_DIMENSIONS[orientation][size] || CARD_DIMENSIONS[orientation].xs
  }

  const isProductAvailable = data.activatedAt
    ? isContentAvailable({
        createdAt: data.activatedAt,
        releaseAfter: null,
        releaseAt: null,
        lockAfter: null,
        lockAt: null,
        availableUntil: data.availableUntil,
      })
    : true
  const isLiveProduct = data.kind === 'live_meet'
  const shouldShowIndicatorOnHover =
    isProductAvailable && !hasAuxiliarTitle && media.gtSm
  const shouldRenderLockBadge = !isProductAvailable && !displayBuyTag
  const shouldRenderLiveBadge =
    isProductAvailable && isLiveProduct && !displayBuyTag && data.hasLiveSoon
  const shouldRenderProgress =
    media.md &&
    !hasAuxiliarTitle &&
    isProductAvailable &&
    !hideProgressInformation
  const cardImageBorderRadius = hasAuxiliarTitle ? 0 : 8
  const displayProgressOnHover =
    media.gtSm && isHovered && !hideProgressInformation

  const showIndicatorOnHover = () => {
    if (!shouldShowIndicatorOnHover) return
    setIsHovered(true)
  }
  const isCourseIdValid = data.courseId || data.pathId

  const handleCardPress = async () => {
    if (customOnPress) {
      await customOnPress()
      return
    }
    if (!isCourseIdValid || !isProductAvailable) return

    productRedirectToEnrollment({
      enrollmentId: data.enrollmentId || '',
      courseId: data.courseId,
      courseContentId: data.courseContentId,
      pathId: data.pathId,
      kind: data.kind,
    })
  }

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedOpacity, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }),
        Animated.timing(animatedOpacity, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: false,
        }),
      ]),
    ).start()
  }, [animatedOpacity])

  const isCompleted = data.progress === 100
  const getProgress = isProductAvailable && data.progress ? data.progress : 0
  const getProgressIndicatorColor = isCompleted ? '$success-300' : '$info-300'
  const isStarted = (data.progress ?? 0) > 0
  const lessonsQuantityFontSize = media.gtMd
    ? '$font-size-xxs'
    : '$font-size-xxxs'
  const progressBarHeight = media.gtSm ? 8 : 6
  const isNativeAndNotAvailable = !isProductAvailable && !isWeb
  const productLogo =
    !isHorizontal && data.logoVertical ? data.logoVertical : data.logo

  const badgeIconsSize = () => {
    if (media.gtLg) return 20
    if (media.gtSm) return 16
    return 12
  }

  const cardWidth = isWeb
    ? `${cardDimensions().width}px !important`
    : cardDimensions().width

  return (
    <View
      {...extraProps}
      maxWidth={cardWidth}
      minWidth={cardWidth}
      onPress={handleCardPress}
    >
      <View
        onHoverIn={showIndicatorOnHover}
        testID="product-card"
        onHoverOut={() => setIsHovered(false)}
        cursor={isProductAvailable ? 'pointer' : 'default'}
        overflow="hidden"
        position="relative"
        shadowRadius={8}
        borderRadius={8}
        borderWidth={2}
        flex={1}
        borderColor="transparent"
        maxWidth={cardDimensions().width}
        minWidth={cardDimensions().width}
        shadowOffset={{ width: 0, height: 4 }}
        scale={1}
        hoverStyle={
          isProductAvailable
            ? {
                shadowColor: 'rgba(63, 63, 63, 0.24)',
                shadowRadius: 48,
                shadowOffset: { width: 0, height: 16 },
                borderColor: '$neutral-800',
                transition: 'all 0.7s ease',
              }
            : {}
        }
        onMouseEnter={() =>
          isProductAvailable && startAnimation(data.enrollmentId || data.id)
        }
        onMouseLeave={() =>
          isProductAvailable && endAnimation(data.enrollmentId || data.id)
        }
      >
        <View>
          <Animated.View
            style={{
              transform: [
                { scale: scaleValues[data.enrollmentId || data.id] || 1 },
              ],
            }}
          >
            <Image
              source={{
                uri: productLogo,
                height: cardDimensions().height,
              }}
              style={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                borderBottomLeftRadius: cardImageBorderRadius,
                borderBottomRightRadius: cardImageBorderRadius,
                filter: isProductAvailable ? 'unset' : 'grayscale(100%)',
                opacity: isNativeAndNotAvailable ? 0.2 : 1,
              }}
              alt={data.title}
            />
          </Animated.View>
          {displayBuyTag && (
            <XStack
              position="absolute"
              testID="buy-tag"
              right={12}
              top={12}
              alignItems="center"
              gap={8}
              backgroundColor="#262626"
              px={12}
              py={4}
              borderRadius={999}
            >
              <HeroIcon
                icon={['fal', 'bag-shopping']}
                color="#fff"
                size={badgeIconsSize()}
              />
              <Text
                ff="$mulishSemiBold"
                color="#fff"
                fontSize={12}
                $gtLg={{ fontSize: 14 }}
                fontWeight={600}
              >
                {t('myProducts.productCard.badges.buy')}
              </Text>
            </XStack>
          )}
          {shouldRenderLockBadge && (
            <XStack
              position="absolute"
              testID="lock-badge"
              right={12}
              top={12}
              alignItems="center"
              gap={8}
              backgroundColor="$warning-300"
              px={8}
              py={2}
              borderRadius={999}
              $gtSm={{ py: 4, px: 12 }}
            >
              <HeroIcon
                icon={['fal', 'lock-keyhole']}
                color="#262626"
                size={badgeIconsSize()}
              />
              <Text
                ff="$mulishSemiBold"
                color="#262626"
                fontSize={12}
                $gtLg={{ fontSize: 14 }}
                fontWeight={600}
              >
                {`${formatDateShort(data.availableUntil || '')}`}
              </Text>
            </XStack>
          )}
          {shouldRenderLiveBadge && (
            <XStack
              position="absolute"
              testID="live-badge"
              right={12}
              top={12}
              alignItems="center"
              gap={8}
              backgroundColor="$danger-300"
              px={8}
              py={2}
              borderRadius={999}
              $gtSm={{ py: 4, px: 12 }}
            >
              <Animated.View
                style={{
                  opacity: interpolatedOpacity,
                }}
              >
                <HeroIcon
                  icon={['fal', 'signal-stream']}
                  color="#fff"
                  size={badgeIconsSize()}
                />
              </Animated.View>
              <Text
                ff="$mulishSemiBold"
                paddingBottom={1.5}
                color="#fff"
                fontSize={12}
                $gtMd={{ fontSize: 14 }}
                fontWeight={600}
              >
                {t('myProducts.productCard.badges.live_meet')}
              </Text>
            </XStack>
          )}
          {isHovered && (
            <LinearGradient
              colors={['transparent', 'rgba(0,0,0,0.9)']}
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
                height: 79,
              }}
            />
          )}
          {shouldRenderProgress && (
            <>
              <LinearGradient
                colors={['transparent', 'rgba(0,0,0,0.9)']}
                style={{
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  right: 0,
                  height: 79,
                  borderBottomLeftRadius: cardImageBorderRadius,
                  borderBottomRightRadius: cardImageBorderRadius,
                }}
              />
              <XStack
                position="absolute"
                width="100%"
                bottom={12}
                alignItems="center"
                gap={8}
                px={12}
                py={4}
                borderRadius={999}
              >
                <Progress
                  value={getProgress}
                  height={progressBarHeight}
                  foregroundColor={getProgressIndicatorColor}
                />
                <XStack gap={6}>
                  {data.progress === 100 ? (
                    <HeroIcon
                      icon={['fal', 'check-circle']}
                      color="$success-300"
                      size={media.gtSm ? 16 : 14}
                    />
                  ) : (
                    <>
                      {isStarted && (
                        <Text
                          color="$success-300"
                          ff="$mulishRegular"
                          fontWeight={400}
                          fontSize={lessonsQuantityFontSize}
                        >
                          {data.completedLessons}
                        </Text>
                      )}
                      <Text
                        color="#fff"
                        ff="$mulishRegular"
                        fontWeight={400}
                        fontSize={lessonsQuantityFontSize}
                      >
                        {isStarted ? t('myProducts.productCard.of') : ''}{' '}
                        {data.totalLessons}
                      </Text>
                    </>
                  )}
                </XStack>
              </XStack>
            </>
          )}
          {displayProgressOnHover && (
            <XStack
              position="absolute"
              width="100%"
              bottom={12}
              alignItems="center"
              gap={8}
              px={12}
              py={4}
              borderRadius={999}
            >
              <Progress
                value={getProgress}
                height={progressBarHeight}
                foregroundColor={getProgressIndicatorColor}
              />
              <XStack gap={6}>
                {data.progress === 100 ? (
                  <HeroIcon
                    icon={['fal', 'check-circle']}
                    color="$success-300"
                    size={16}
                  />
                ) : (
                  <>
                    {isStarted && (
                      <Text
                        color="$success-300"
                        ff="$mulishRegular"
                        fontWeight={400}
                        fontSize={lessonsQuantityFontSize}
                      >
                        {data.completedLessons}
                      </Text>
                    )}
                    <Text
                      color="#fff"
                      ff="$mulishRegular"
                      fontWeight={400}
                      fontSize={lessonsQuantityFontSize}
                    >
                      {isStarted ? t('myProducts.productCard.of') : ''}{' '}
                      {data.totalLessons}
                    </Text>
                  </>
                )}
              </XStack>
            </XStack>
          )}
        </View>
        {hasAuxiliarTitle && (
          <ProductCardContent
            testID="auxiliar-title"
            isContentAvailable={isProductAvailable}
            completedLessons={data.completedLessons}
            title={data.title}
            progress={data.progress}
            totalLessons={data.totalLessons}
            isHorizontal={isHorizontal}
          />
        )}
      </View>
    </View>
  )
}
