import ContentLoader, { Rect } from 'react-content-loader/native'
import { GetProps, Stack, useTheme } from 'tamagui'

type ShimmerProps = GetProps<typeof Stack>

export function Shimmer(props: ShimmerProps) {
  const theme = useTheme()
  return (
    <Stack {...props}>
      <ContentLoader
        animate
        title=""
        backgroundColor={theme['$neutral-100']?.val}
        foregroundColor={'#929292'}
      >
        <Rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </Stack>
  )
}
