import { ActivityIndicator, Dimensions } from 'react-native'
import { ScrollView } from 'tamagui'

export function LoadingScreen() {
  const height = Dimensions.get('window').height
  return (
    <ScrollView style={{ backgroundColor: '#171717' }}>
      <ActivityIndicator size="large" color="#FF6485" style={{ height }} />
    </ScrollView>
  )
}
