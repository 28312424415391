import { Image, useMedia, View } from 'tamagui'
import { ICustomBannersItem } from '@dtos/MembersConfig'
import { openWebBrowser } from '@utils/openWebBrowser'
interface IBannerProps {
  height: number
  item: ICustomBannersItem
  kind: 'duo' | 'single' | 'trio'
}

export function Banner({ height, item, kind }: IBannerProps) {
  const media = useMedia()
  const cursorPointer = item.linkUrl ? 'pointer' : 'default'

  const singleBannerUrl = () => {
    if (media.gtMd) return item.logoDesktopUrl
    if (media.gtSm) return item.logoTabletUrl
    return item.logoMobileUrl
  }

  const duoBannerUrl = () => {
    if (media.gtSm) return item.logoDesktopUrl
    return item.logoMobileUrl
  }

  const trioBannerUrl = () => {
    if (media.gtMd) return item.logoDesktopUrl
    if (media.gtSm) return item.logoTabletUrl
    return item.logoDesktopUrl
  }

  const bannerUrlByKind = () => {
    if (kind === 'single') return singleBannerUrl()
    if (kind === 'duo') return duoBannerUrl()
    return trioBannerUrl()
  }

  const handleBannerPress = () => {
    if (!item.linkUrl) return
    openWebBrowser(item.linkUrl)
  }

  return (
    <View flex={1} onPress={handleBannerPress} role="button">
      <Image
        source={{ uri: bannerUrlByKind() }}
        testID="banner-image"
        alt={item.altText}
        style={{
          borderRadius: 8,
          width: '100%',
          height,
          cursor: cursorPointer,
        }}
      />
    </View>
  )
}
