import { IMedia } from '@dtos/Course'
import React, { useMemo, useRef } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { Image, isWeb, useMedia } from 'tamagui'
import YouTube from 'react-youtube'
import RenderHtml from 'react-native-render-html'
import { Video, ResizeMode } from 'expo-av'
import Vimeo from 'vimeo-reactjs'
import PDFReader from 'rn-pdf-reader-js'
import { THEME_LIGHT_MODE } from '@constants/features'
import { useHasFeature } from '@hooks/useThemeParams'

interface IMediaRenderProps {
  media: IMedia
  children?: React.ReactNode
}

export function MediaRender({ media, children }: IMediaRenderProps) {
  const isLightMode = useHasFeature(THEME_LIGHT_MODE)
  const video = useRef(null)
  const Tmedia = useMedia()
  const windowWidth = Dimensions.get('window').width
  const windowHeight = Dimensions.get('window').height

  const { width, height } = useMemo(() => {
    const width = Tmedia.gtSm ? (Tmedia.gtMd ? 958 : 576) : windowWidth - 40
    const height = Tmedia.gtSm
      ? windowHeight * 0.6
      : media?.type === 'Video'
        ? 300
        : 450
    return { width, height }
  }, [Tmedia, windowWidth, windowHeight, media])
  const isMediaPdf = media?.fileExt?.toLowerCase() === 'pdf'

  const opts = {
    height: height.toString(),
    width: width.toString(),
    playerVars: {
      controls: 1,
    },
  }

  const sparkVideosUrl = (media: IMedia) => {
    const mountSparkVideosUrl = `${process.env.EXPO_PUBLIC_SPARKVIDEOS_URL}/api/player/${media.organizationUuid}/${media.hostKey}`
    return mountSparkVideosUrl
  }

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        width,
        height,
      },
      video: {
        alignSelf: 'center',
        width,
        height,
      },
    })
  }, [width, height])

  const youtubeVideoId = useMemo(() => {
    return media?.fileUrl?.split('v=')[1]
  }, [media])

  const vimeoVideoId = useMemo(() => {
    return media?.fileUrl?.split('vimeo.com/')[1]
  }, [media])

  if (media === null) return null
  if (media.type === 'Document' && media.fileUrl) {
    if (isMediaPdf) {
      if (isWeb) {
        return (
          <View>
            <iframe
              src={
                'https://static-cdn.myedools.com/pdfjs/web/viewer.html?file=' +
                encodeURIComponent(media.fileUrl)
              }
              style={{ width, height: height < 700 ? 450 : height }}
            />
          </View>
        )
      } else {
        return <PDFReader source={{ uri: media.fileUrl }} />
      }
    }
  }
  if (media.type === 'Audio' && media.fileUrl) {
    return (
      <View style={(styles.container, { justifyContent: 'center' })}>
        <audio controls style={{ width }}>
          <source src={media.fileUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </View>
    )
  }
  if (media.type === 'Text' && media.text) {
    return (
      <View style={styles.container}>
        <RenderHtml
          contentWidth={width}
          source={{ html: media.text }}
          baseStyle={{
            maxHeight: height < 700 ? windowHeight * 0.5 : height,
            color: isLightMode ? '#262626' : '#F2F2F2',
          }}
        />
      </View>
    )
  }
  if (media.type === 'Image' && media.fileUrl) {
    return (
      <View>
        <Image
          alt=""
          source={{
            uri: media.fileUrl,
          }}
          style={{ height, width }}
        />
      </View>
    )
  }
  if (media && media.type === 'Video' && media.fileUrl) {
    if (isWeb) {
      if (media.fileUrl.includes('youtube')) {
        return (
          <View style={(styles.container, { alignSelf: 'center' })}>
            <YouTube
              videoId={youtubeVideoId}
              id="video"
              opts={opts}
              style={{ display: 'flex', flex: 1 }}
            />
          </View>
        )
      } else if (media.fileUrl.includes('vimeo')) {
        return (
          <View style={(styles.container, { alignSelf: 'center' })}>
            <Vimeo video={vimeoVideoId} height={height} width={width} />
          </View>
        )
      } else {
        return (
          <View style={(styles.container, { alignSelf: 'center' })}>
            {isWeb ? (
              <iframe
                src={sparkVideosUrl(media)}
                style={{ width, height }}
              ></iframe>
            ) : (
              <Video
                ref={video}
                style={styles.video}
                videoStyle={styles.video}
                source={{
                  uri: sparkVideosUrl(media),
                }}
                useNativeControls
                resizeMode={ResizeMode.CONTAIN}
              />
            )}
          </View>
        )
      }
    }
  }
  return (
    <View style={(styles.container, { justifyContent: 'center' })}>
      {children}
    </View>
  )
}
