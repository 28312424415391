const BANNER_CONTENT_MAX_WIDTH = {
  xs: 328,
  sm: 440,
  md: 515,
  lg: 746,
  xl: 825,
  xxl: 825,
}

const BANNER_HEIGHT = {
  xs: 0.85,
  sm: 0.85,
  md: 0.85,
  lg: 0.9,
  xl: 0.9,
  xxl: 0.9,
}

const BANNER_MAX_HEIGHT = {
  xs: 720,
  sm: 360,
  md: 640,
  lg: 900,
  xl: 1031,
  xxl: 1031,
}

export { BANNER_CONTENT_MAX_WIDTH, BANNER_HEIGHT, BANNER_MAX_HEIGHT }
