import { useLazyQuery } from '@apollo/client'
import { GET_EXTRA_CONTENTS } from './queries/getExtraContents'
import { IGraphqlQueryProps } from '@dtos/Pagination'

export const useGetExtraContents = ({
  id,
  offset,
  limit,
}: IGraphqlQueryProps) => {
  const [getExtraContents, { data, loading, error, variables }] = useLazyQuery(
    GET_EXTRA_CONTENTS,
    {
      variables: { id, offset, limit },
    },
  )

  return { getExtraContents, data, loading, error, variables }
}
