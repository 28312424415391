import React, { useCallback, useState, useEffect } from 'react'
import {
  Circle,
  View,
  XStack,
  YStack,
  useMedia,
  useWindowDimensions,
} from 'tamagui'
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { SliderControl } from '@components/SliderControl'
import { Animated } from 'react-native'
import { useOpacityAnimation } from '@hooks/useOpacityAnimation'
import { LinearGradient } from 'expo-linear-gradient'

export interface ISliderProps {
  slidesTotalNumber: number
  displaySliderControls: boolean
  displaySliderControlsHorizontal?: boolean
  slidesPerView: number
  sliderTitle?: React.ReactNode
  sliderTitleContainerStyles?: React.CSSProperties
  slidesSpacing?: number
  sliderControlVariation?: 'chevron' | 'dots'
  children: React.ReactNode
  loadMoreContent?: (args?: string | number) => Promise<void> | void
  fullScreen?: boolean
  autoPlay?: boolean
  autoPlayInterval?: number
  displayIndicators?: boolean
  isCarousel?: boolean
}

export function Slider({
  children,
  sliderTitle,
  slidesPerView,
  displaySliderControls = false,
  slidesSpacing = 24,
  slidesTotalNumber,
  loadMoreContent,
  sliderControlVariation = 'chevron',
  displaySliderControlsHorizontal = false,
}: ISliderProps) {
  const [contentsLength, setContentsLength] = useState(slidesTotalNumber)
  const [isSliderMoving, setIsSliderMoving] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [horizontalControlsPosition, setHorizontalControlsPosition] = useState({
    width: 0,
    height: 0,
  })
  const [sliderIndexPosition, setSliderIndexPosition] = useState(0)
  const { width } = useWindowDimensions()
  const media = useMedia()
  const { interpolatedOpacity, startAnimation } = useOpacityAnimation()
  useEffect(() => {
    setContentsLength(slidesTotalNumber)
  }, [slidesTotalNumber])

  const sliderControl = {
    first: sliderIndexPosition === 0,
    last: sliderIndexPosition + slidesPerView >= contentsLength,
  } as { first: boolean; last: boolean }

  const indexPace = slidesPerView
  const calculateContainerTitleGap = () => {
    if (media.gtLg) return 160
    if (media.gtMd) return 80
    if (media.gtSm) return 139
    return 16
  }

  const isSliderVariationChevron = sliderControlVariation === 'chevron'
  const dotsQuantityToDisplay = Math.ceil(contentsLength / indexPace)
  const canDisplayDotsControl = media.gtMd && dotsQuantityToDisplay <= 5
  const dotIndexHighlight = Math.ceil(sliderIndexPosition / indexPace)
  const chevronIconSize = media.gtSm ? 24 : 16

  const shouldHighlightDot = (index: number) => {
    return index === dotIndexHighlight ? '$neutral-800' : '$neutral-600'
  }

  const MutationPlugin = (slider: KeenSliderInstance) => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes.length) {
          slider.update()
        }
      })
    })
    const config = { childList: true }

    slider.on('created', () => {
      observer.observe(slider.container, config)
    })

    slider.on('destroyed', () => {
      observer.disconnect()
    })
  }

  const shouldLoadMore = (indexPosition: number, slidesLength: number) => {
    return slidesLength - (indexPace + indexPosition) <= indexPace
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      mode: 'snap',
      slides: {
        origin: 'auto',
        perView: slidesPerView,
        spacing: slidesSpacing,
      },
      animationEnded: () => {
        setIsSliderMoving(false)
      },
      animationStarted: () => {
        setIsSliderMoving(true)
      },
      animationStopped: () => {
        setIsSliderMoving(false)
      },
      slideChanged(s) {
        const slidePosition = s.track.details.abs
        const shouldLoad = shouldLoadMore(slidePosition, s.slides.length)
        if (slidePosition === s.animator.targetIdx && shouldLoad) {
          if (canLoadMore === false) {
            setCanLoadMore(true)
          }
        }
        setSliderIndexPosition(slidePosition)
      },
    },
    [MutationPlugin],
  )

  const moveSlider = useCallback(
    (
      sliderRef: React.MutableRefObject<KeenSliderInstance | null>,
      direction: 'left' | 'right',
    ) => {
      if (!sliderRef.current) return
      const slidePosition = sliderRef.current?.track.details.position
      const arrayLength = contentsLength
      let indexToMove
      if (direction === 'left') {
        if (slidePosition - indexPace - 1 < 0) {
          indexToMove = 0
        } else {
          indexToMove = (slidePosition || 0) - indexPace
        }
      } else {
        const remnantSlidesNumber = arrayLength - (slidePosition + indexPace)
        if (
          slidePosition + indexPace < arrayLength &&
          remnantSlidesNumber >= indexPace
        ) {
          indexToMove = slidePosition + indexPace
        } else {
          indexToMove = slidePosition + remnantSlidesNumber
        }
      }
      sliderRef.current.moveToIdx(indexToMove)
    },
    [contentsLength, indexPace],
  )

  useEffect(() => {
    const loadMore = async () => {
      if (loadMoreContent) {
        await loadMoreContent()
      }
    }

    if (canLoadMore) {
      loadMore()
      setCanLoadMore(false)
    }
  }, [canLoadMore, loadMoreContent])

  useEffect(() => {
    startAnimation(displaySliderControlsHorizontal)
  }, [displaySliderControlsHorizontal, startAnimation])

  return (
    <YStack testID="slider">
      <XStack
        justifyContent="space-between"
        gap={calculateContainerTitleGap()}
        position="relative"
      >
        {sliderTitle}
        {displaySliderControls && (
          <XStack
            alignItems="center"
            $gtMd={{ width: '10%', justifyContent: 'flex-end' }}
            testID="slider-controls"
            marginBottom="$size.spacing-xs"
          >
            {isSliderVariationChevron ? (
              <>
                <SliderControl
                  action={() => moveSlider(instanceRef, 'left')}
                  chevronDirection="left"
                  disabled={sliderControl.first}
                  size={chevronIconSize}
                />
                <SliderControl
                  action={() => moveSlider(instanceRef, 'right')}
                  chevronDirection="right"
                  disabled={sliderControl.last}
                  size={chevronIconSize}
                />
              </>
            ) : (
              <>
                {canDisplayDotsControl && (
                  <XStack gap={8} alignSelf="flex-end" paddingRight={50}>
                    {Array.from({ length: dotsQuantityToDisplay }).map(
                      (_, index) => (
                        <Circle
                          key={index}
                          size={6}
                          backgroundColor={shouldHighlightDot(index)}
                          hoverStyle={{
                            cursor: 'pointer',
                          }}
                          onPress={() => {
                            const direction =
                              index > dotIndexHighlight ? 'right' : 'left'
                            moveSlider(instanceRef, direction)
                          }}
                        />
                      ),
                    )}
                  </XStack>
                )}
              </>
            )}
          </XStack>
        )}
      </XStack>

      <XStack
        ref={sliderRef}
        className="keen-slider"
        onLayout={({ nativeEvent }) => {
          setHorizontalControlsPosition({
            width: nativeEvent.layout.width,
            height: nativeEvent.layout.height,
          })
        }}
      >
        {displaySliderControlsHorizontal && (
          <Animated.View
            style={{
              opacity: media.gtSm ? interpolatedOpacity : 1,
              zIndex: 1000,
              height: horizontalControlsPosition.height,
              bottom: -horizontalControlsPosition.height / 2.6,
            }}
          >
            {!sliderControl.first && (
              <View>
                <SliderControl
                  position="absolute"
                  zIndex={1000}
                  alignSelf="center"
                  left={0}
                  onHoverIn={() => null}
                  onHoverOut={() => null}
                  chevronColor="$neutral-700"
                  action={() => moveSlider(instanceRef, 'left')}
                  chevronDirection="left"
                  disabled={sliderControl.first}
                  size={media.gtSm ? 60 : 40}
                  style={{
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                />
                <LinearGradient
                  colors={['transparent', 'rgba(0,0,0,0.86)']}
                  style={{
                    zIndex: 999,
                    alignSelf: 'center',
                    position: 'absolute',
                    width: horizontalControlsPosition.width,
                    height: 150,
                    transform: 'rotate(90deg)',
                  }}
                />
              </View>
            )}
            {!sliderControl.last && (
              <View position="absolute" left={width - 125}>
                <SliderControl
                  zIndex={1000}
                  alignSelf="center"
                  chevronColor="$neutral-700"
                  onHoverIn={() => null}
                  onHoverOut={() => null}
                  action={() => moveSlider(instanceRef, 'right')}
                  chevronDirection="right"
                  disabled={sliderControl.last}
                  size={media.gtSm ? 60 : 40}
                  style={{
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                />
                <LinearGradient
                  colors={['rgba(0,0,0,0.86)', 'transparent']}
                  style={{
                    zIndex: 999,
                    alignSelf: 'center',
                    position: 'absolute',
                    width: horizontalControlsPosition.width,
                    height: 100,
                    transform: 'rotate(90deg)',
                  }}
                />
              </View>
            )}
          </Animated.View>
        )}
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement, {
              className:
                `${(child.props as { className?: string }).className || ''} keen-slider__slide`.trim(),
              isslidermoving: isSliderMoving.toString(),
            })
          }
          return child
        })}
      </XStack>
    </YStack>
  )
}
