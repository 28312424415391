import { gql } from '@apollo/client'

export const GET_COURSE_CONTENTS = gql`
  query getModulesCourseContents(
    $id: ID!
    $moduleId: ID
    $offset: Int!
    $limit: Int!
  ) {
    course(id: $id) {
      id
      courseModules(id: $moduleId) {
        nodes {
          id
          name
          available
          courseContents(pagination: { offset: $offset, limit: $limit }) {
            nodes {
              id
              available
              lesson {
                id
                title
                releaseAt
                releaseAfter
                lockAt
                lockAfter
                createdAt
                activity {
                  examQuestionsCount
                }
                media {
                  type
                  duration
                  hostKey
                  host
                }
                coverImage {
                  cdnFileUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
