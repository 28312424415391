import { gql } from '@apollo/client'

export const GET_LESSON_EXTRA_CONTENTS = gql`
  query getLessonExtraContents($id: ID!, $offset: Int!, $limit: Int!) {
    course(id: $id) {
      id
      lessons(
        withExtraMedia: true
        pagination: { offset: $offset, limit: $limit }
      ) {
        nodes {
          available
          id
          title
          releaseAt
          releaseAfter
          lockAt
          lockAfter
          courseContentId
          extraMedias {
            title
            id
            type
            fileUrl
            fileExt
            text
            host
            hostKey
            organizationUuid
          }
        }
      }
    }
  }
`
