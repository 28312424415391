import { useMedia, YStack } from 'tamagui'
import { Slider } from '@components/Slider'
import { ProductCard } from './ProductCard'
import { MyProductsSectionTitle } from './MyProductsSectionTitle'
import { useState } from 'react'
import { IEnrollment } from '@dtos/Enrollment'
import { useGetMyProductsConfig } from '@hooks/useMembersConfig'
import { useWindowDimensions } from 'react-native'
import { CARD_DIMENSIONS } from '@constants/productCardDimension'
import { useMediaSize } from '@hooks/useMediaSize'

interface IMyProductSectionProps {
  data: IEnrollment[] | null
  totalProductsNumber: number
  handleLoadMoreProducts: (offset: number) => Promise<void>
  showProductName: boolean
}

export function MyProductsSection({
  data,
  totalProductsNumber,
  handleLoadMoreProducts,
  showProductName,
}: IMyProductSectionProps) {
  const [isHovered, setIsHovered] = useState(false)
  const { data: myProductsConfig } = useGetMyProductsConfig()
  const isHorizontal = myProductsConfig?.cardFormat
    ? myProductsConfig.cardFormat === 'horizontal'
    : true
  const media = useMedia()
  const { width: windowWidth } = useWindowDimensions()
  const sizes = useMediaSize()

  const containerPadding = media.gtSm ? 48 : 16
  const spaceBetweenCards = media.gtSm ? 20 : 16
  const slidesPerView = () => {
    const orientation = isHorizontal ? 'horizontal' : 'vertical'
    const cardWidth =
      CARD_DIMENSIONS[orientation][sizes] || CARD_DIMENSIONS[orientation].xs
    return (
      (windowWidth - containerPadding) / (cardWidth.width + spaceBetweenCards)
    )
  }

  const canDisplaySliderControls =
    media.gtSm && !!data && data.length > slidesPerView()
  const shouldDisplaySliderControls = media.gtMd && isHovered
  return (
    <YStack
      testID="my-products-section"
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <Slider
        slidesTotalNumber={totalProductsNumber}
        slidesPerView={slidesPerView()}
        displaySliderControls={canDisplaySliderControls}
        slidesSpacing={spaceBetweenCards}
        sliderControlVariation="dots"
        loadMoreContent={() => handleLoadMoreProducts(data?.length || 0)}
        sliderTitle={<MyProductsSectionTitle isHoveringSection={isHovered} />}
        displaySliderControlsHorizontal={shouldDisplaySliderControls}
      >
        {data?.map((item: IEnrollment) => {
          return (
            <ProductCard
              key={item.id}
              hasAuxiliarTitle={showProductName}
              isHorizontal={isHorizontal}
              data={{
                id: item.id.toString(),
                enrollmentId: item.id.toString(),
                courseId: item.schoolProduct.courses?.[0]?.id.toString(),
                activatedAt: item.activatedAt,
                availableUntil: item.availableUntil,
                progress: item.progressCard.progress,
                totalLessons: item.progressCard.total,
                completedLessons: item.progressCard.completed,
                title: item.schoolProduct.title,
                logo: item.schoolProduct.logo,
                logoVertical: item.schoolProduct.logoVertical,
                kind: item.schoolProduct.courses?.[0]?.kind,
                pathId: item.schoolProduct.path?.id,
                hasLiveSoon: item.livemeetRoomsAvailable.length > 0,
                courseContentId:
                  item.schoolProduct.courses?.[0]?.lessons?.nodes?.[0]
                    ?.courseContentId ?? null,
              }}
            />
          )
        })}
      </Slider>
    </YStack>
  )
}
