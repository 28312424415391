import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { YStack, Text, useWindowDimensions } from 'tamagui'
import { CourseDescriptionModal } from './CourseDescriptionModal'
import { useState, useMemo } from 'react'
import RenderHtml from 'react-native-render-html'
import { useHasFeature } from '@hooks/useThemeParams'
import { THEME_LIGHT_MODE } from '@constants/features'

export function CourseDescription({ description }: { description: string }) {
  const isLightMode = useHasFeature(THEME_LIGHT_MODE)
  const [openDesscriptionModal, setOpenDesscriptionModal] = useState(false)
  const { width } = useWindowDimensions()

  const reducedDescription = useMemo(() => {
    if (description.slice(0, 600).includes('</a>')) {
      return description.slice(0, description.indexOf('</a>') + 150) + '...'
    }
    if (description.length > 300) {
      return description.slice(description.indexOf('>') + 1, 330) + '...'
    }
    return description
  }, [description])

  return (
    <YStack marginTop={24} maxHeight={130}>
      <Text
        numberOfLines={3}
        color="$neutral-700"
        ff="$mulishSemiBold"
        fontSize="$font-size-xs"
      >
        <RenderHtml
          contentWidth={width * 0.9}
          enableCSSInlineProcessing={false}
          source={{ html: reducedDescription }}
          baseStyle={{
            color: isLightMode ? '#262626' : '#F2F2F2',
            maxWidth: width * 0.9,
          }}
        />
      </Text>
      <Button
        marginTop={10}
        width="fit-content"
        alignSelf="flex-end"
        variant="primary"
        aria-label="Read more"
        link
        onPress={() => setOpenDesscriptionModal(true)}
      >
        <HeroIcon icon={['fas', 'plus']} size={16} color="$primary-300" />
        <Text
          fontSize="$font-size-xxs"
          ff="$mulishRegular"
          color="$primary-300"
          position="relative"
          textDecorationLine="underline"
          top={-4}
          ml={8}
        >
          Ler mais
        </Text>
      </Button>
      <CourseDescriptionModal
        open={openDesscriptionModal}
        onClose={() => setOpenDesscriptionModal(false)}
        description={description}
      />
    </YStack>
  )
}
