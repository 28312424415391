export const THEME_LIGHT_MODE = 'theme_light_mode'
export const THEME_DARK_MODE = 'theme_dark_mode'
export const DISABLE_MEMBERS_AREA = 'disable_members_area'
export const MEMBERS_PAGE_HOME = 'members_page_home'
export const DISABLE_HEROSPARK_LOGO = 'deactivate_copyright'
export const DISABLE_MEMBER_AREA_REFUND = 'disable_member_area_refund'

export const FEATURES = [
  THEME_LIGHT_MODE,
  THEME_DARK_MODE,
  DISABLE_MEMBERS_AREA,
  MEMBERS_PAGE_HOME,
  DISABLE_HEROSPARK_LOGO,
  DISABLE_MEMBER_AREA_REFUND,
] as const
