import { useState, useEffect } from 'react'
import { Animated } from 'react-native'

const ANIMATION_DURATION = 700

type Scale = {
  [key: string]: Animated.Value
}

export const useScaleAnimation = (id: string) => {
  const [scaleValues, setScaleValues] = useState<Scale>({})

  useEffect(() => {
    setScaleValues({ [id]: new Animated.Value(1) })
  }, [id])

  const startAnimation = (id: string) => {
    if (!scaleValues[id]) return
    Animated.timing(scaleValues[id], {
      toValue: 1.1,
      duration: ANIMATION_DURATION,
      useNativeDriver: false,
    }).start()
  }

  const endAnimation = (id: string) => {
    if (!scaleValues[id]) return
    Animated.timing(scaleValues[id], {
      toValue: 1,
      duration: ANIMATION_DURATION,
      useNativeDriver: false,
    }).start()
  }

  useEffect(() => {
    const newScaleValues: Scale = {}
    newScaleValues[id] = new Animated.Value(1)
    setScaleValues(newScaleValues)
  }, [id])

  return { scaleValues, startAnimation, endAnimation }
}
