import { GetProps } from 'tamagui'
import { Container, Label } from './style'

export type BadgeProps = GetProps<typeof Container> & {
  text: string
}

export function Badge({ text, variant, outline, ...resp }: BadgeProps) {
  return (
    <Container variant={variant} outline={outline} {...resp}>
      <Label variant={variant} outline={outline}>
        {text}
      </Label>
    </Container>
  )
}
