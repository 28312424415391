import { Text, useMedia, View } from 'tamagui'

interface IMobileExtendedCardContentProps {
  title: string
  productName: string
}

export function MobileExtendedCardContent({
  title,
  productName,
}: IMobileExtendedCardContentProps) {
  const media = useMedia()
  const titleNumberOfLines = media.gtSm ? 3 : 2
  const titleFontSize = media.gtSm ? '$font-size-xxs' : '$font-size-xxs'

  return (
    <View
      backgroundColor="$neutral-0"
      width="50%"
      $gtSm={{
        width: '60%',
      }}
      justifyContent="space-between"
    >
      <View gap="$size.spacing-xxxs">
        <Text
          ff="$mulishSemiBold"
          color="$neutral-800"
          fontSize={titleFontSize}
          fontWeight="600"
          numberOfLines={titleNumberOfLines}
        >
          {title}
        </Text>
        <Text
          ff="$mulishSemiBold"
          color="$neutral-500"
          fontSize="$font-size-xxxs"
          display="flex"
          gap={8}
          numberOfLines={2}
        >
          {productName}
        </Text>
      </View>
    </View>
  )
}
