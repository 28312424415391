import { ReactNode, createContext, useEffect, useState } from 'react'
import { ICourseModules } from '@dtos/Course'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useGetModules } from '@services/api/graphql/modules'
import { useLocalSearchParams } from 'expo-router'

interface IModulesProviderProps {
  modules?: ICourseModules
  isLoading: boolean
  error?: Error
  onLoadMore?: () => void
}

interface IUseGetModulesInterface {
  data: {
    course: {
      courseModules: ICourseModules | null
    }
  } | null
  loading: boolean
  error: Error | undefined
  fetchMore: (args: { variables: { offset: number | undefined } }) => void
}

export const ModulesContext = createContext<IModulesProviderProps>(
  {} as IModulesProviderProps,
)

export function ModulesProvider({ children }: { children: ReactNode }) {
  const [modulesState, setModulesState] = useState<ICourseModules | undefined>()
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { courseId } = useLocalSearchParams()
  const { data, loading, error, fetchMore } = useGetModules({
    id: `${courseId}`,
    offset: 0,
    limit: 5,
  }) as IUseGetModulesInterface

  useEffect(() => {
    if (!data?.course.courseModules) return
    setModulesState(data?.course.courseModules)
  }, [data?.course.courseModules])

  if (error && error.message !== 'Empty page!') {
    toast.show(t('apolloErrors.lessonExtraContents.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (loading) {
    return (
      <ModulesContext.Provider value={{ isLoading: loading, error }}>
        {children}
      </ModulesContext.Provider>
    )
  }

  return (
    <ModulesContext.Provider
      value={{
        modules: modulesState,
        isLoading: false,
        error,
        onLoadMore: () => {
          fetchMore({
            variables: {
              offset: data?.course.courseModules?.nodes.length,
            },
          })
        },
      }}
    >
      {children}
    </ModulesContext.Provider>
  )
}
