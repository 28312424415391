import { useLazyQuery } from '@apollo/client'
import { GET_LESSON_EXTRA_CONTENTS } from './queries/getLessonExtraContents'
import { IGraphqlQueryProps } from '@dtos/Pagination'

export const useGetLessonExtraContents = ({
  id,
  offset,
  limit,
}: IGraphqlQueryProps) => {
  const [getMoreLessonExtraContents, { data, loading, error, variables }] =
    useLazyQuery(GET_LESSON_EXTRA_CONTENTS, {
      variables: { id, offset, limit },
    })

  return {
    getMoreLessonExtraContents,
    data,
    loading,
    error,
    variables,
  }
}
