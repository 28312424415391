import { Badge } from '@components/Badge'
import { PaymentStatusType, SubscriptionStatus } from '@dtos/Order'

import { useTranslation } from 'react-i18next'
import { getStyleProps } from './getStyleProps'
import { useMemo } from 'react'
import { camelCase } from 'lodash'

interface IStatusBadgeProps {
  status: PaymentStatusType | SubscriptionStatus
  isSubscription?: boolean
}

const DEFAULT_STYLE_PROPS = ['primary', true] as const

export function StatusBadge({
  status = 'initiated',
  isSubscription = false,
}: IStatusBadgeProps) {
  const { t } = useTranslation('OrderDetails', { keyPrefix: 'status' })

  const [variant, outline] =
    getStyleProps({
      status,
      isSubscription,
    }) || DEFAULT_STYLE_PROPS

  const textKey = useMemo(() => {
    const type = isSubscription ? 'subscription' : 'payment'
    return `${type}.${camelCase(status)}`
  }, [isSubscription, status])

  return <Badge variant={variant} outline={outline} text={t(textKey)} />
}
