import HeroIconImage from '@assets/hero-icon.png'
import { ISocialMediaIcons } from '@dtos/MembersConfig'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { useGetFooterConfig } from '@hooks/useMembersConfig'
import { useThemeParams, useHasFeature } from '@hooks/useThemeParams'
import { ImageBackground } from 'react-native'
import {
  Image,
  Stack,
  styled,
  Text,
  useMedia,
  View,
  XStack,
  YStack,
} from 'tamagui'
import { FooterSocialMediaIcon } from './FooterSocialMediaIcon'
import { DISABLE_HEROSPARK_LOGO } from '../constants/features'

type ISocialMediaIconsMethod = {
  icon: [IconPrefix, IconName] | string
  url: string
}

const FooterIcons: Record<ISocialMediaIcons, [IconPrefix, IconName] | string> =
  {
    facebook: ['fab', 'facebook-f'],
    linkedin: ['fab', 'linkedin-in'],
    youtube: ['fab', 'youtube'],
    twitter: ['fab', 'x-twitter'],
    instagram: 'instagram-solid',
    tiktok: ['fab', 'tiktok'],
    whatsapp: 'whatsapp-solid',
    email: ['fas', 'envelope'],
    site: ['fas', 'globe'],
    support: ['fas', 'headset'],
  }

export function Footer() {
  const { data: params } = useThemeParams()
  const { data: membersConfig } = useGetFooterConfig()
  const media = useMedia()
  const hasFeature = useHasFeature(DISABLE_HEROSPARK_LOGO)
  const schoolLogo = params?.school?.logo
  const actualYear = new Date().getFullYear()
  const displayCustomText =
    membersConfig?.showCustomText && !!membersConfig?.customText
  const displayExternalLinks =
    membersConfig?.showExternalLinks && membersConfig?.externalLinks.length > 0
  const displayHeroSparkLogo = !hasFeature
  const disabledFooter = !displayExternalLinks && !displayCustomText
  const hasNoContentOnFooter =
    !!membersConfig &&
    !membersConfig.externalLinks?.length &&
    !displayCustomText
  const displayFooterContent =
    membersConfig !== null && !hasNoContentOnFooter && !disabledFooter
  const linksMarginTop = !!schoolLogo && !displayCustomText ? 24 : 0
  const imageWidth = media.gtMd ? 'auto' : 80

  const socialMediaIcons = (): ISocialMediaIconsMethod[] => {
    if (!membersConfig?.externalLinks) return []
    return membersConfig?.externalLinks.reduce(
      (
        acc: Array<ISocialMediaIconsMethod>,
        link: { icon: ISocialMediaIcons; url: string },
      ) => {
        if (link.icon in FooterIcons) {
          acc.push({
            icon: FooterIcons[link.icon as keyof typeof FooterIcons],
            url: link.url,
          })
        }
        return acc
      },
      [],
    )
  }

  return (
    <YStack
      backgroundColor="$background-100"
      paddingHorizontal="$size.spacing-xs"
      paddingVertical="$size.spacing-xxl"
      $gtSm={{
        paddingHorizontal: '$size.spacing-xsl',
      }}
    >
      <>
        {displayFooterContent ? (
          <Stack
            borderTopWidth={1}
            borderBottomWidth={1}
            flexDirection="column"
            borderColor="$neutral-200"
            paddingVertical={46}
            $gtSm={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingHorizontal: 48,
              gap: '$size.spacing-xxs',
            }}
          >
            <Stack
              $gtMd={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              {schoolLogo && (
                <ImageBackground
                  testID="footer-logo"
                  source={{ uri: schoolLogo }}
                  alt={`Logo da ${params?.school?.name}`}
                  resizeMode="contain"
                  style={{
                    minWidth: 80,
                    width: imageWidth,
                    maxWidth: 124,
                    height: 40,
                  }}
                />
              )}
              {displayCustomText && (
                <FooterText
                  testID="footer-custom-text"
                  marginVertical={15}
                  maxWidth={336}
                  $gtMd={{
                    alignSelf: 'center',
                    maxWidth: 510,
                  }}
                >
                  {membersConfig?.customText}
                </FooterText>
              )}
            </Stack>
            {displayExternalLinks && (
              <View
                flexDirection="row"
                gap="$size.spacing-xxs"
                marginTop={linksMarginTop}
                width={200}
                flexWrap="wrap"
                $gtMd={{
                  width: 'fit-content',
                  marginTop: 0,
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                {socialMediaIcons().map(({ icon, url }, index: number) => (
                  <FooterSocialMediaIcon key={index} icon={icon} url={url} />
                ))}
              </View>
            )}
          </Stack>
        ) : null}
        {displayHeroSparkLogo && (
          <Stack
            testID="footer-herospark-logo"
            paddingTop="$size.spacing-md"
            gap="$size.spacing-xxxs"
            flexDirection="column"
            $gtSm={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <XStack gap="$size.spacing-xxxs" alignItems="center">
              <Image
                source={HeroIconImage}
                alt="Ícone da HeroSpark"
                style={{
                  width: 24,
                  height: 24,
                }}
              />
              <FooterText>Feito por HeroSpark</FooterText>
            </XStack>
            <FooterText>2012-{actualYear} &#169;</FooterText>
          </Stack>
        )}
      </>
    </YStack>
  )
}

const FooterText = styled(Text, {
  ff: '$mulishRegular',
  fontSize: '$font-size-xxxs',
  fontWeight: '700',
  color: '$neutral-500',
})
