import { useMedia } from '@tamagui/core'

export function useMediaSize() {
  const media = useMedia()
  if (media.gtXxl) return 'xxl'
  if (media.gtLg) return 'lg'
  if (media.gtMd) return 'md'
  if (media.gtSm) return 'sm'
  return 'xs'
}
