import { shorthands } from '@tamagui/shorthands'
import { createTamagui } from 'tamagui'
import { tokens } from './tokens'
import { light } from './light'
import { dark } from './dark'
import { media } from './tokens/media'
import { mulishRegular, mulishSemiBold, mulishBold } from './tokens/font'
import { animations } from '@tamagui/config'

const config = createTamagui({
  themes: { light, dark },
  tokens,
  shorthands,
  media,
  animations,
  defaulTheme: 'dark',
  fonts: {
    heading: mulishBold,
    body: mulishRegular,
    mulishRegular,
    mulishSemiBold,
    mulishBold,
  },
})

export default config
