import { Shimmer } from '@components/Shimmer'
import { Stack, XStack, YStack } from 'tamagui'

export function ExtraContentGhostLoading() {
  return (
    <Stack flexDirection="column" marginBottom="$size.spacing-xl">
      <YStack marginTop={50} gap={30}>
        <Shimmer width={250} height={50} $gtSm={{ width: '40%', height: 50 }} />
        <Shimmer width={250} height={30} $gtSm={{ width: '40%', height: 50 }} />
        <XStack gap={16}>
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
        </XStack>
        <YStack>
          <Shimmer
            width={250}
            height={30}
            $gtSm={{ width: '40%', height: 50 }}
          />
          <XStack gap={16} marginTop={20}>
            <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
            <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
            <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
            <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
            <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          </XStack>
        </YStack>
      </YStack>
    </Stack>
  )
}
