import { HeroIcon } from '@components/HeroIcon'
import { Progress } from '@components/Progress'
import { useTranslation } from 'react-i18next'
import { YStack, XStack, Text, useMedia, CardProps, View } from 'tamagui'

interface IProductCardContentProps extends CardProps {
  progress?: number
  completedLessons?: number
  totalLessons?: number
  isContentAvailable: boolean
  isHorizontal?: boolean
  title?: string
}

export function ProductCardContent({
  completedLessons,
  progress,
  totalLessons,
  isContentAvailable,
  isHorizontal = true,
  title,
  ...extraProps
}: IProductCardContentProps) {
  const media = useMedia()
  const { t } = useTranslation('Home')

  const footerIcon = () => {
    if (!isContentAvailable) return 'lock-keyhole'
    if (progress === 100) return 'check-circle'
    return 'arrow-right'
  }

  const isCompleted = progress === 100
  const isStarted = (progress ?? 0) > 0
  const getNumberOfLines = media.gtSm ? 2 : 3

  const getProgress = isContentAvailable ? progress : 0
  const getProgressIndicatorColor = isCompleted ? '$success-300' : '$info-300'
  const progressHeight = media.sm ? 6 : 8
  const displayCardProgress =
    isContentAvailable || (!isContentAvailable && media.gtMd)
  const displayCardFooter = !isContentAvailable || media.gtMd
  const displayStatusProgressIcon = progress === 100 && media.md
  const iconsSize = media.gtSm ? 16 : 14
  const displayCardProgressInfo = progress !== undefined

  const getFooterLabel = () => {
    if (!isContentAvailable) return t('myProducts.productCard.status.blocked')
    if (isCompleted) return t('myProducts.productCard.status.finished')
    if (isStarted) return t('myProducts.productCard.status.continue')
    return t('myProducts.productCard.status.access')
  }

  const getFooterContentLabel = () => {
    let label = ''
    if (isStarted) label += `${t('myProducts.productCard.of')} `
    label += `${totalLessons} `
    if (media.gtMd && isHorizontal)
      label += t('myProducts.productCard.contents')

    return label
  }

  const insetSpace = () => {
    if (media.gtMd) return '$spacing-inset-sm'
    if (media.md && media.gtSm) return '$spacing-inset-xs'
    return '$spacing-inset-xxs'
  }

  return (
    <View
      backgroundColor="$neutral-0"
      flex={1}
      borderTopStartRadius={0}
      borderTopEndRadius={0}
      borderBottomStartRadius={8}
      borderBottomEndRadius={8}
      {...extraProps}
    >
      <YStack p={insetSpace()} gap={insetSpace()} flex={1}>
        <Text
          color="$neutral-800"
          ff="$mulishBold"
          numberOfLines={getNumberOfLines}
          fontSize="$font-size-xxxs"
          lineHeight={24}
          $gtXs={{ fontSize: '$font-size-xs' }}
        >
          {title}
        </Text>

        {displayCardProgressInfo && (
          <YStack
            flex={1}
            gap={16}
            justifyContent="flex-end"
            $gtLg={{ flexDirection: 'column' }}
            $md={{ flexDirection: 'row', gap: 4, alignItems: 'flex-end' }}
          >
            {displayCardProgress && (
              <Progress
                value={getProgress || 0}
                foregroundColor={getProgressIndicatorColor}
                height={progressHeight}
                marginBottom="$spacing-inset-quarck"
                $gtLg={{ marginBottom: 0 }}
              />
            )}
            <XStack justifyContent="space-between" alignItems="center">
              {displayCardFooter && (
                <XStack gap={8} alignItems="center">
                  <HeroIcon
                    icon={['fal', footerIcon()]}
                    size={iconsSize}
                    color="$neutral-800"
                  />
                  <Text
                    color="$neutral-800"
                    ff="$mulishRegular"
                    fontSize="$font-size-xxxs"
                    $gtSm={{
                      fontSize: '$font-size-xxs',
                    }}
                  >
                    {getFooterLabel()}
                  </Text>
                </XStack>
              )}

              {isContentAvailable &&
                (displayStatusProgressIcon ? (
                  <HeroIcon
                    icon={['fal', 'check-circle']}
                    color="$success-300"
                    size={iconsSize}
                  />
                ) : (
                  <XStack gap={5} alignItems="flex-end">
                    {isStarted && (
                      <Text
                        color="$success-300"
                        ff="$mulishRegular"
                        fontWeight={400}
                        fontSize="$font-size-xxs"
                        $xs={{ fontSize: '$font-size-xxxs' }}
                      >
                        {completedLessons}
                      </Text>
                    )}
                    <Text
                      color="$neutral-800"
                      ff="$mulishRegular"
                      fontWeight={400}
                      fontSize="$font-size-xxs"
                      $xs={{ fontSize: '$font-size-xxxs' }}
                    >
                      {getFooterContentLabel()}
                    </Text>
                  </XStack>
                ))}
            </XStack>
          </YStack>
        )}
      </YStack>
    </View>
  )
}
