type ColorGradesType = {
  [grade: number]: string
}

export type DarkPalletType = { [pallet: string]: ColorGradesType }

const darkPalletes: DarkPalletType = {
  primary: {
    300: '#00E4F5',
    100: '#BCFBFF',
    200: '#00B7C5',
    400: '#0B717A',
  },

  secundary: {
    300: '#FF6485',
    100: '#FFCFCF',
    200: '#FF305C',
    400: '#B71858',
  },

  background: {
    100: '#171717',
  },

  neutral: {
    0: '#262626',
    100: '#3F3F3F',
    200: '#525252',
    300: '#6E6E6E',
    400: '#8F8F8F',
    500: '#A8A8A8',
    600: '#C2C2C2',
    700: '#F2F2F2',
    800: '#FFFFFF',
  },

  success: {
    300: '#8ED147',
    100: '#BAFF70',
    200: '#6EA633',
    400: '#417509',
  },
  info: {
    300: '#24ABFF',
    100: '#B3DAFE',
    200: '#1494E3',
    400: '#104AA1',
  },

  warning: {
    300: '#FFB521',
    100: '#FFEAA6',
    200: '#C88C15',
    400: '#8A610F',
  },

  danger: {
    300: '#FF5C5C',
    100: '#FEB4B4',
    200: '#F62D2D',
    400: '#851515',
  },
}

type PalletType = keyof typeof darkPalletes

export const createTheme = (pallets: DarkPalletType) => {
  const tokens: { [key: string]: string } = {}

  for (const pallete in pallets) {
    for (const key in pallets[pallete as PalletType]) {
      tokens[`${pallete}-${key}`] = pallets[pallete][key]
    }
  }

  return tokens
}

export const dark = createTheme(darkPalletes)
