import { useLazyQuery } from '@apollo/client'
import { IGraphqlQueryProps } from '@dtos/Pagination'
import { GET_LAST_LESSONS_VIEWED_CONTENT } from './queries/getLastLessonsViewedContent'

export const useGetLastLessonsViewedContent = ({
  id,
  offset,
  limit,
  lessonsIds,
}: IGraphqlQueryProps) => {
  const [getLastLessonsViewed, { data, loading, error, variables, called }] =
    useLazyQuery(GET_LAST_LESSONS_VIEWED_CONTENT, {
      variables: { id, lessonsIds, offset, limit },
    })

  return {
    getLastLessonsViewed,
    data,
    loading,
    error,
    variables,
    called,
  }
}
