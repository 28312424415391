import { DarkPalletType, createTheme } from './dark'

const lightPalletes: DarkPalletType = {
  primary: {
    300: '#7427F1',
    100: '#35126E',
    200: '#4D1AA1',
    400: '#EAD3FE',
  },

  secundary: {
    300: '#FF305C',
    100: '#7A094C',
    200: '#B71858',
    400: '#FFDCD5',
  },

  background: {
    100: '#E3E3E3',
  },

  neutral: {
    0: '#FFFFFF',
    100: '#E3E3E3',
    200: '#CFCFCF',
    300: '#BABABA',
    400: '#929292',
    500: '#6F6F6F',
    600: '#525252',
    700: '#3F3F3F',
    800: '#262626',
  },

  success: {
    300: '#348535',
    100: '#112B11',
    200: '#205221',
    400: '#E3FAB8',
  },
  info: {
    300: '#3575D4',
    100: '#1E4278',
    200: '#28579E',
    400: '#B3DAFE',
  },

  warning: {
    300: '#EDA81F',
    100: '#977D48',
    200: '#BF8208',
    400: '#FFEAA6',
  },

  danger: {
    300: '#DB3939',
    100: '#802121',
    200: '#A62B2B',
    400: '#FEB4B4',
  },
}

export const light = createTheme(lightPalletes)
