import { OrdersLayout } from '@layouts/OrdersLayout'
import { H2 } from 'tamagui'

export default function Orders() {
  return (
    <OrdersLayout>
      <H2 color="$neutral-200" fontSize={'$font-size-md'} ff="$mulishRegular">
        Meus pedidos
      </H2>
    </OrdersLayout>
  )
}
