import { Header } from '@components/Header/Header'
import { HeaderMobile } from '@components/Header/HeaderMobile'
import { ReactNode, useState, useRef } from 'react'
import { ScrollView, View, YStack, useMedia } from 'tamagui'
import { Animated, NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { MobileMenu } from '@components/Header/MobileMenu'
import { LoadingScreen } from '@components/LoadingScreen'
import { useThemeParams } from '@hooks/useThemeParams'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Footer } from '@components/Footer'
import { IOScrollView } from 'react-native-intersection-observer'

export interface IBackRoute {
  route?: string
  label: string
  back?: () => void
  shouldDisplayLogo?: boolean
}
interface IAppLayoutProps {
  children: ReactNode
  backRoute?: IBackRoute
  backgroundColor?: string
}

export function AppLayout({
  children,
  backRoute,
  backgroundColor = '$background-100',
}: IAppLayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [hideMenu, setHideMenu] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const media = useMedia()
  const { loading: themeParamsIsLoading, data } = useThemeParams()
  const safeArea = useSafeAreaInsets()

  const headerSectionHeight = 100
  const minHeight = media.gtSm ? 50 : 55

  const scrollOffsetY = useRef(new Animated.Value(0)).current

  const animatedHeaderHeight = scrollOffsetY.interpolate({
    inputRange: [-100, 0],
    outputRange: [headerSectionHeight, headerSectionHeight - minHeight],
    extrapolate: 'clamp',
  })

  const animatedOpacity = scrollOffsetY.interpolate({
    outputRange: [0, 1],
    inputRange: [headerSectionHeight, headerSectionHeight + 40],
    extrapolate: 'clamp',
  })

  const animatedBottom = scrollOffsetY.interpolate({
    inputRange: [-20, 150],
    outputRange: [-100, 70],
    extrapolate: 'clamp',
  })
  if (media.gtSm) {
    animatedOpacity.addListener(({ value }) => {
      if (Math.floor(value) && !isVisible) {
        setIsVisible(true)
      } else if (!Math.floor(value) && isVisible) {
        setIsVisible(false)
      }
    })
  }
  const renderHeader = (isFixedHeader: boolean) => (
    <Header
      backRoute={backRoute}
      logoUrl={data?.school?.logo}
      isFixedHeader={isFixedHeader}
      isVisible={isVisible}
    />
  )

  if (themeParamsIsLoading) {
    return <LoadingScreen />
  }

  return (
    <View flex={1} paddingTop={safeArea.top} backgroundColor="$background-100">
      {
        <ScrollView
          backgroundColor="$background-100"
          scrollEventThrottle={99}
          width="100%"
          contentContainerStyle={{ flexGrow: 1 }}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollOffsetY } } }],
            {
              useNativeDriver: false,
              listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
                if (event.nativeEvent.contentOffset.y > 120 && hideMenu) {
                  setHideMenu(false)
                } else if (
                  event.nativeEvent.contentOffset.y < 120 &&
                  !hideMenu
                ) {
                  setHideMenu(true)
                }
              },
            },
          )}
        >
          {renderHeader(true)}
          <YStack
            backgroundColor={backgroundColor}
            paddingBottom="$size.spacing-xxl"
            flexGrow={1}
            $gtMd={{
              paddingBottom: 0,
            }}
          >
            <IOScrollView contentContainerStyle={{ flex: 1 }}>
              {children}
              <Footer />
            </IOScrollView>
          </YStack>
        </ScrollView>
      }
      <Animated.View
        style={{
          zIndex: 100,
          height: animatedHeaderHeight,
          position: 'absolute',
          backgroundColor: '$neutral-0',
          marginHorizontal: 'auto',
          width: '100%',
          opacity: animatedOpacity,
          bottom: media.gtSm ? 'auto' : animatedBottom,
          display: hideMenu ? 'none' : 'flex',
        }}
      >
        {media.gtSm ? (
          renderHeader(false)
        ) : (
          <>
            <HeaderMobile openModal={() => setIsMenuOpen(true)} />
            <MobileMenu
              open={isMenuOpen}
              onClose={() => {
                setIsMenuOpen(false)
              }}
            />
          </>
        )}
      </Animated.View>
    </View>
  )
}
