import { Badge } from '@components/Badge'
import { HeroIcon } from '@components/HeroIcon'
import {
  Stack,
  XStack,
  Text,
  useMedia,
  useWindowDimensions,
  getTokens,
} from 'tamagui'

interface IModuleSliderTitleProps {
  isSubmodule: boolean
  moduleName: string
  totalLessons: number
  numberOfLessons: number
  lessonsCompletedPerModule: number
}

export function ModuleSliderTitle({
  isSubmodule,
  moduleName,
  totalLessons,
  numberOfLessons,
  lessonsCompletedPerModule,
}: IModuleSliderTitleProps) {
  const media = useMedia()
  const { width } = useWindowDimensions()
  const sizes = getTokens().size
  const moduleTitleDisplay = moduleName.length > 360 ? 'block' : 'flex'
  const moduleTitleFontSize = isSubmodule ? '$font-size-sm' : '$font-size-md'

  const formattedModuleName = (name: string) => {
    const spacingMd = Number(sizes['$spacing-md'].val)
    const nameMaxLength = (width - spacingMd) / (media.gtSm ? 4.7 : 3.5)
    return name.length > nameMaxLength
      ? `${name.slice(0, nameMaxLength)}...`
      : name
  }

  const calculateWidthDivice = () => {
    if (media.gtLg) return width * 0.8
    if (media.gtSm) return width * 0.4
    return width * 0.5
  }

  const moduleTitleWidth =
    moduleName.length > 200 ? calculateWidthDivice() : 'auto'

  return (
    <Stack
      flex={1}
      marginBottom="$size.spacing-xs"
      $gtSm={{ flexDirection: 'row', alignItems: 'center' }}
      gap={16}
      $gtMd={{ width: '90%' }}
    >
      {isSubmodule && (
        <XStack
          borderEndStartRadius={12}
          borderTopRightRadius={12}
          bg="$neutral-0"
          alignItems="center"
          paddingHorizontal={16}
          paddingVertical={8}
          gap={10}
        >
          <HeroIcon
            icon={['fal', 'turn-down-right']}
            color="$neutral-600"
            size={20}
          />
          <Text
            color="$neutral-600"
            fontSize="$font-size-xs"
            ff="$mulishSemiBold"
          >
            Submódulo
          </Text>
        </XStack>
      )}

      <XStack
        flex={1}
        flexDirection="column"
        alignItems="flex-start"
        gap={8}
        $gtSm={{
          display: moduleTitleDisplay,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Text
          color="$neutral-800"
          ff="$mulishBold"
          fontSize={moduleTitleFontSize}
          mr={16}
          width={moduleTitleWidth}
        >
          {formattedModuleName(moduleName)}
        </Text>
        {numberOfLessons !== 0 && (
          <Text
            color="$success-300"
            ff="$mulishBold"
            fontSize="$font-size-xxs"
            whiteSpace="nowrap"
          >
            {lessonsCompletedPerModule}
            <Text
              display="inline-flex"
              color="$neutral-600"
              ff="$mulishBold"
              fontSize="$font-size-xxs"
              alignItems="center"
            >
              {`  de ${totalLessons} conteúdos`}
              {lessonsCompletedPerModule === numberOfLessons && (
                <Badge
                  ml={16}
                  variant="success"
                  text="Concluído"
                  style={{
                    alignSelf: 'center',
                  }}
                />
              )}
            </Text>
          </Text>
        )}
      </XStack>
    </Stack>
  )
}
