import { HeroIcon } from '@components/HeroIcon'
import { Animated } from 'react-native'
import { useOpacityAnimation } from '@hooks/useOpacityAnimation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { XStack, Text, useMedia, isWeb } from 'tamagui'

export function MyProductsSectionTitle({
  isHoveringSection,
}: {
  isHoveringSection: boolean
}) {
  const [buttonIsHovered, setButtonIsHovered] = useState(false)
  const { t } = useTranslation('Home')
  const media = useMedia()
  const { startAnimation, interpolatedOpacity } = useOpacityAnimation()
  const iconSize = media.gtSm ? 16 : 14
  const setOpacity = media.gtMd ? interpolatedOpacity : 1
  const setColor = buttonIsHovered ? '$primary-100' : '$primary-300'

  const setDisplay = () => {
    if (media.gtMd) return isHoveringSection ? 'flex' : 'none'
    return 'flex'
  }

  const handlePress = () => {
    if (isWeb) {
      window.location.href = '/enrollments?advanced=true'
    }
  }

  useEffect(() => {
    startAnimation(isHoveringSection)
  }, [isHoveringSection, startAnimation])

  return (
    <XStack marginBottom="$size.spacing-xs">
      <Text
        color="$neutral-800"
        ff="$mulishBold"
        fontSize="$font-size-xs"
        $gtMd={{ fontSize: '$font-size-md' }}
        alignSelf="center"
        fontWeight={700}
      >
        {t('myProducts.myProductsSectionTitle.title')}
      </Text>
      {isWeb && (
        <XStack
          gap={4}
          alignItems="center"
          $gtSm={{ gap: 8 }}
          $gtMd={{ paddingTop: 4 }}
          paddingRight={16}
          cursor="pointer"
          onPress={handlePress}
          onHoverIn={() => setButtonIsHovered(true)}
          onHoverOut={() => setButtonIsHovered(false)}
        >
          <Animated.View
            style={{
              opacity: setOpacity,
            }}
          >
            <Text
              color={setColor}
              ff="$mulishBold"
              paddingLeft={16}
              fontSize="$font-size-xxxs"
              display={setDisplay()}
              fontWeight={700}
              $gtSm={{ fontSize: '$font-size-xs' }}
              textDecorationLine="unset"
              textDecorationColor="$primary-300"
            >
              {t('myProducts.myProductsSectionTitle.seeAll')}
            </Text>
          </Animated.View>
          <HeroIcon
            icon={['fas', 'chevron-right']}
            size={iconSize}
            color={setColor}
            style={{
              paddingVertical: 2,
            }}
          />
        </XStack>
      )}
    </XStack>
  )
}
