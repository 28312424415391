import { View, Stack, useMedia, SizableText } from 'tamagui'
import { useMemo } from 'react'
import { Banner } from './Banner'
import { ICustomBannersKind } from '@dtos/MembersConfig'
import { useGetCustomBanners } from '@hooks/useMembersConfig'
import { useMediaSize } from '@hooks/useMediaSize'
import { CUSTOM_BANNERS_HEIGHTS } from '@constants/customBanners'

export function CustomBanners() {
  const { data: customBanners } = useGetCustomBanners()
  const media = useMedia()
  const sizes = useMediaSize()
  const shouldSkipRenderBanners = !customBanners || customBanners.length === 0

  const sectionsGap = () => {
    if (media.gtLg) return '$size.spacing-xsl'
    if (media.gtSm) return '$size.spacing-xl'
    return '$size.spacing-lg'
  }

  const renderBanners = useMemo(() => {
    if (shouldSkipRenderBanners) return null
    const bannersFlexDirecion = media.gtSm ? 'row' : 'column'
    const bannersGap = media.gtSm ? '$size.spacing-sm' : '$size.spacing-lg'

    const getBannerHeight = (kind: ICustomBannersKind) => {
      return CUSTOM_BANNERS_HEIGHTS[kind][sizes || 'xs']
    }

    return customBanners.map((banner) => {
      return (
        <View
          key={`banner-${banner.title}-${banner.order}`}
          gap="$spacing-inset-xs"
        >
          <SizableText
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-xs"
            fontWeight="700"
            $gtMd={{
              fontSize: '$font-size-md',
            }}
          >
            {banner.title}
          </SizableText>
          <Stack
            testID="banner-container"
            flexDirection={bannersFlexDirecion}
            gap={bannersGap}
          >
            {banner.items.map((item, index) => {
              return (
                <Banner
                  key={`${banner.order}-${index}`}
                  item={item}
                  height={getBannerHeight(banner.kind)}
                  kind={banner.kind}
                />
              )
            })}
          </Stack>
        </View>
      )
    })
  }, [customBanners, media.gtSm, shouldSkipRenderBanners, sizes])

  if (shouldSkipRenderBanners) return null
  return (
    <View
      paddingHorizontal="$size.spacing-xs"
      gap={sectionsGap()}
      $gtSm={{
        paddingHorizontal: '$size.spacing-xsl',
      }}
    >
      {renderBanners}
    </View>
  )
}
