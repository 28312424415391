import { Button } from '@components/Button'
import { Fieldset, H3, Label, Paragraph, Stack, YStack } from 'tamagui'

import { useToastController } from '@tamagui/toast'
import { useComponentTranslation } from '@hooks/useComponentTranslation'
import { Modal } from '@components/Modal'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import * as Yup from 'yup'
import { magicLinkEmail } from '@services/api/auth'
import { useRudderstack } from '@hooks/useAnalytics'
import {
  ISubscription,
  ISmartInstallment,
  IBolixInstallment,
} from '@dtos/Order'
import { useThemeParams } from '@hooks/useThemeParams'

interface IAuthEmailModalProps {
  open: boolean
  onClose: () => void
  orderInfo: {
    id: number
    productTitle: string
    createdAt: string
    subscription: ISubscription | ISmartInstallment | IBolixInstallment | null
  }
  action: 'cancelSubscription' | 'changeCreditCard' | 'refundPayment'
}

interface IMagicLogin {
  email: string
}
const validationSchema = Yup.object({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
})

export function AuthEmailModal({
  open,
  onClose,
  orderInfo,
  action,
}: IAuthEmailModalProps) {
  const { data: params } = useThemeParams()
  const { t } = useComponentTranslation('AuthEmailModal')
  const toast = useToastController()
  const formInitialValues: IMagicLogin = { email: '' }
  const { track } = useRudderstack()

  async function handleSendEmail({ email }: IMagicLogin) {
    await magicLinkEmail(
      email,
      `/orders/${orderInfo.id}/payments?action=${action}`,
    )

    onClose()

    toast.show(t('toast.title'), {
      message: t('toast.message'),
    })

    track('Authentication Email Sent to Student', {
      product_name: orderInfo.productTitle,
      subscription_id: orderInfo.subscription?.id,
      subscription_status: orderInfo.subscription?.status,
      subscription_type: orderInfo.subscription?.subscription_type,
      cart_id: orderInfo.id,
      cart_created_at: orderInfo.createdAt,
      email,
      school_id: params?.school?.id,
    })
  }

  return (
    <Modal onClose={onClose} snapPoints={[100, 80]} open={open}>
      <Stack
        gap="$size.spacing-xxxs"
        paddingHorizontal="$size.spacing-xs"
        paddingVertical="$size.spacing-xsl"
        $gtSm={{
          p: '$size.spacing-lg',
        }}
        bg="$neutral-0"
      >
        <YStack gap="$size.spacing-xs">
          <H3
            textAlign="center"
            ff="$mulishBold"
            fontSize="$font-size-sm"
            color="$neutral-800"
          >
            {t('title')}
          </H3>
          <Paragraph
            mt="$size.spacing-md"
            ff="$mulishRegular"
            fontSize="$font-size-xs"
            textAlign="center"
            color="$neutral-800"
          >
            {t('description')}
          </Paragraph>
        </YStack>
        <Formik
          initialValues={formInitialValues}
          onSubmit={handleSendEmail}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Fieldset
                gap="$size.spacing-xxxs"
                $gtSm={{
                  flex: 1,
                }}
                borderWidth={0}
                m={0}
                p={0}
              >
                <Label
                  htmlFor="name"
                  ff="$mulishBold"
                  fontSize="$font-size-xs"
                  color="$neutral-800"
                  unstyled
                  mt="$size.spacing-lg"
                >
                  {t('label')}
                </Label>
              </Fieldset>

              <Stack
                gap="$size.spacing-xxs"
                $gtSm={{
                  flexDirection: 'row',
                }}
              >
                <YStack flex={1}>
                  <Input
                    unstyled
                    id="name"
                    placeholder={t('placeholder')}
                    width="100%"
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    errorMessage={touched.email && errors.email}
                  />
                </YStack>
                <YStack>
                  <Button
                    variant="primary"
                    aria-label="Close"
                    onPress={() => handleSubmit()}
                    loading={isSubmitting}
                  >
                    {t('action')}
                  </Button>
                </YStack>
              </Stack>
            </>
          )}
        </Formik>
      </Stack>
    </Modal>
  )
}
