import {
  ApolloError,
  OperationVariables,
  QueryResult,
  useLazyQuery,
} from '@apollo/client'
import { GET_CONTINUE_LESSONS } from './queries/getContinueLessons'
import { IContinueLessons } from '@dtos/User'

interface IUseGetContinueLessonsData {
  user: {
    lastViewedContents: IContinueLessons[]
  }
}

export const useGetContinueLessons = () => {
  const [getContinueLessons, { data, loading, error, called }] =
    useLazyQuery(GET_CONTINUE_LESSONS)

  return {
    getContinueLessons,
    data,
    loading,
    error,
    called,
  } as {
    getContinueLessons: () => Promise<
      QueryResult<IUseGetContinueLessonsData, OperationVariables>
    >
    data: IUseGetContinueLessonsData | null
    loading: boolean
    error: ApolloError
    called: boolean
  }
}
