import { View, useMedia, useWindowDimensions } from 'tamagui'
import { Slider } from '@components/Slider'
import { DefaultSectionTitle } from '@components/Home/DefaultSectionTitle'
import { ICustomProductList, ICustomProductListItem } from '@dtos/MembersConfig'
import { useGetGeneralConfig } from '@hooks/useMembersConfig'
import { useState, useCallback } from 'react'
import { ContinueCard } from '@components/Home/Continue/ContinueCard'
import { ProductCard } from '@components/Home/MyProducts/ProductCard'
import { ContinueLessonsModal } from '@components/Home/Continue/ContinueLessonsModal'
import { useProductsCustomList } from '@services/api/graphql/productsCustomList'
import { useTranslation } from 'react-i18next'
import { useToastController } from '@tamagui/toast'
import { InView } from 'react-native-intersection-observer'
import { useGetProductUrl } from '@services/api/graphql/productUrl'
import { openWebBrowser } from '@utils/openWebBrowser'
import { useEnrollmentIdsInfo } from '@services/api/graphql/enrollment'
import { productRedirectToEnrollment } from '@utils/productRedirectToEnrollment'
import { useMediaSize } from '@hooks/useMediaSize'
import { CARD_DIMENSIONS } from '@constants/productCardDimension'

export function CustomProductListSlider({
  list,
  cardFormat,
}: {
  list: ICustomProductList
  cardFormat: 'horizontal' | 'vertical'
}) {
  const [customListItems, setCustomListItems] = useState<
    ICustomProductListItem[]
  >(list.items)
  const [isHovered, setIsHovered] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [reachedEnd, setReachedEnd] = useState(false)
  const [isLoadingUrl, setIsLoadingUrl] = useState(false)
  const { data: generalConfig } = useGetGeneralConfig()
  const { getProductsCustomList } = useProductsCustomList()
  const { getProductUrl } = useGetProductUrl({
    itemId: '',
    productListId: '',
  })
  const { getEnrollmentIdsInfo } = useEnrollmentIdsInfo()
  const media = useMedia()
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { width: windowWidth } = useWindowDimensions()
  const sizes = useMediaSize()
  const isHorizontal = cardFormat === 'horizontal'
  const shouldDisplaySliderControls = media.gtMd && isHovered
  const showProductName = generalConfig?.showProductName ?? false

  const handleLoadMoreContent = useCallback(async () => {
    if (reachedEnd) return
    try {
      const { data, error } = await getProductsCustomList({
        variables: {
          id: list.id,
          offset: customListItems.length,
          limit: 10,
        },
      })
      if (error?.message === 'Empty page!') {
        setReachedEnd(true)
      }
      if (data) {
        const newListItems = data.membersConfig.customProductLists[0].items
        setCustomListItems([...customListItems, ...newListItems])
      }
    } catch (error) {
      toast.show(t('apolloErrors.productsCustomList.title'), {
        message: t('genericRequestError.message'),
        type: 'error',
      })
    }
  }, [customListItems, getProductsCustomList, list.id, toast, t, reachedEnd])

  const containerPadding = media.gtSm ? 48 : 16
  const spaceBetweenCards = media.gtSm ? 20 : 16
  const slidesPerView = () => {
    const orientation = isHorizontal ? 'horizontal' : 'vertical'
    const cardWidth =
      CARD_DIMENSIONS[orientation][sizes] || CARD_DIMENSIONS[orientation].xs
    return (
      (windowWidth - containerPadding) / (cardWidth.width + spaceBetweenCards)
    )
  }

  const handleButtonPress = () => {
    setOpenModal(true)
  }

  const shouldDisplaySeeAllButton = (itemsLength: number) => {
    return itemsLength > slidesPerView()
  }

  const getEnrollmentInfo = useCallback(
    async (enrollmentId: string) => {
      try {
        const { data } = await getEnrollmentIdsInfo({
          variables: { id: enrollmentId },
        })
        if (data) {
          productRedirectToEnrollment({
            enrollmentId: data.enrollment.id.toString(),
            courseId: data.enrollment.schoolProduct.courses?.[0]?.id.toString(),
            courseContentId:
              data.enrollment.schoolProduct.courses?.[0]?.courseModules
                ?.nodes[0]?.courseContents?.nodes[0]?.id,
            pathId: data.enrollment.schoolProduct.path?.id,
            kind: data.enrollment.schoolProduct.courses?.[0]?.kind,
          })
        }
      } catch (error) {
        toast.show(t('apolloErrors.enrollmentIdsInfo.title'), {
          message: t('genericRequestError.message'),
          type: 'error',
        })
      } finally {
        setIsLoadingUrl(false)
      }
    },
    [getEnrollmentIdsInfo, toast, t],
  )

  const getProductSellUrl = useCallback(
    async (listId: string, itemId: string) => {
      try {
        setIsLoadingUrl(true)
        const { data, error } = await getProductUrl({
          variables: {
            itemId,
            productListId: listId,
          },
        })
        if (data) {
          const url = data.productUrl
          openWebBrowser(url)
        }
        if (error) {
          throw error
        }
      } catch (error) {
        toast.show(t('apolloErrors.productUrl.title'), {
          message: t('genericRequestError.message'),
          type: 'error',
        })
      } finally {
        setIsLoadingUrl(false)
      }
    },
    [getProductUrl, setIsLoadingUrl, toast, t],
  )

  const customProductCards = useCallback(
    (isModalVersion: boolean, items: ICustomProductListItem[]) => {
      return items.map((item: ICustomProductListItem, index: number) => {
        const handleCustomOnPress = async () => {
          if (isLoadingUrl) return
          if (item.activeForUser) {
            await getEnrollmentInfo(item.enrollmentId)
          } else {
            await getProductSellUrl(list.id, item.id)
          }
        }

        if (isModalVersion) {
          return (
            <>
              {index === items.length - 1 ? (
                <InView
                  key={item.schoolProductId}
                  onChange={(inView: boolean) =>
                    inView && handleLoadMoreContent()
                  }
                >
                  <ContinueCard
                    cardId={item.schoolProductId}
                    showProductName={showProductName}
                    shouldExtendCard={false}
                    productTitle={item.title}
                    isModalVersion={true}
                    customThumbnail={item.logo}
                    displayPlayIcon={false}
                    displayBuyTag={!item.activeForUser}
                    customOnPress={handleCustomOnPress}
                  />
                </InView>
              ) : (
                <ContinueCard
                  cardId={item.schoolProductId}
                  showProductName={showProductName}
                  key={item.schoolProductId}
                  shouldExtendCard={false}
                  productTitle={item.title}
                  isModalVersion={true}
                  customThumbnail={item.logo}
                  displayPlayIcon={false}
                  displayBuyTag={!item.activeForUser}
                  customOnPress={handleCustomOnPress}
                />
              )}
            </>
          )
        }
        return (
          <ProductCard
            key={item.schoolProductId}
            hasAuxiliarTitle={showProductName}
            isHorizontal={isHorizontal}
            displayBuyTag={!item.activeForUser}
            hideProgressInformation={true}
            customOnPress={handleCustomOnPress}
            data={{
              id: item.schoolProductId,
              title: item.title,
              logo: item.logo,
              logoVertical: item.logoVertical,
              kind: item.kind,
            }}
          />
        )
      })
    },
    [
      showProductName,
      isHorizontal,
      handleLoadMoreContent,
      list.id,
      getEnrollmentInfo,
      getProductSellUrl,
      isLoadingUrl,
    ],
  )

  if (customListItems.length === 0) return null
  return (
    <View
      paddingLeft="$size.spacing-xs"
      $gtSm={{
        paddingLeft: '$size.spacing-xsl',
      }}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <Slider
        slidesTotalNumber={customListItems.length}
        slidesPerView={slidesPerView()}
        displaySliderControls={false}
        slidesSpacing={spaceBetweenCards}
        sliderControlVariation="dots"
        loadMoreContent={handleLoadMoreContent}
        sliderTitle={
          <DefaultSectionTitle
            title={list.title}
            isHoveringSection={isHovered}
            onButtonPress={handleButtonPress}
            showButton={shouldDisplaySeeAllButton(list.items.length) ?? false}
          />
        }
        displaySliderControlsHorizontal={shouldDisplaySliderControls}
      >
        {customProductCards(false, customListItems)}
      </Slider>
      <ContinueLessonsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        lessonCards={customProductCards(true, customListItems)}
        title={list.title}
      />
    </View>
  )
}
