import { Stack, StackProps, useMedia } from 'tamagui'
import { useEffect, useState } from 'react'
import { HeroIcon } from '@components/HeroIcon'
import { debounce } from 'lodash'

interface ISliderControlProps extends StackProps {
  action: () => void
  chevronDirection: 'left' | 'right'
  size: number
  isCarousel?: boolean
  disabled?: boolean
  chevronColor?: string
}

export function SliderControl({
  action,
  chevronDirection,
  size,
  isCarousel = false,
  disabled = false,
  chevronColor = '$neutral-600',
  ...extraProps
}: ISliderControlProps) {
  const [isActive, setIsActive] = useState(false)
  const media = useMedia()
  const handleClick = debounce(action, 300)
  const buttonSize = media.gtSm ? 52 : 32
  const iconColor = isActive && !disabled ? '$primary-300' : chevronColor
  const cursorPointer = disabled ? 'default' : 'pointer'
  const backgroundColor =
    disabled || isCarousel || !isActive ? 'transparent' : '$neutral-200'
  const marginRight = isCarousel ? 0 : 8

  const setPadding = () => {
    if (isCarousel) return 4
    return media.gtSm ? 12 : 8
  }

  useEffect(() => {
    setIsActive(disabled === true && isActive === true ? false : isActive)
  }, [disabled, isActive])

  return (
    <Stack
      onPress={() => handleClick()}
      height={buttonSize}
      width={buttonSize}
      borderRadius={8}
      disabled={disabled}
      marginRight={marginRight}
      padding={setPadding()}
      justifyContent="center"
      alignItems="center"
      cursor={cursorPointer}
      backgroundColor={backgroundColor}
      pressStyle={{ outlineWidth: 0 }}
      focusStyle={{ outlineWidth: 0 }}
      onFocus={() => (isCarousel ? setIsActive(true) : null)}
      onBlur={() => (isCarousel ? setIsActive(false) : null)}
      onHoverIn={() => (disabled ? null : setIsActive(true))}
      onHoverOut={() => setIsActive(false)}
      hoverStyle={{
        backgroundColor,
      }}
      {...extraProps}
    >
      <HeroIcon
        icon={['fas', `chevron-${chevronDirection}`]}
        size={size}
        style={{
          opacity: disabled ? 0.5 : 1,
        }}
        color={iconColor}
      />
    </Stack>
  )
}
