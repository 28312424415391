import { Slider } from '@components/Slider'
import { ICourseModulesNodes } from '@dtos/Course'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stack, StackProps, useMedia, useWindowDimensions } from 'tamagui'
import { ModuleSliderTitle } from './ModuleSlidertitle'
import { useEnrollment } from '@hooks/useEnrollment'
import { LessonCard } from './LessonCard'
import { useCourseContents } from '@hooks/useCourseContents'
import { InView } from 'react-native-intersection-observer'
interface IModuleContainerProps extends StackProps {
  module: ICourseModulesNodes
  isLastModuleOfData?: boolean
  displayBorder?: boolean
  onLoadMoreCallBack?: () => void
}

export function ModuleContainer({
  module,
  isLastModuleOfData,
  displayBorder,
  onLoadMoreCallBack,
  ...rest
}: IModuleContainerProps) {
  const [moduleState, setModulesState] = useState<ICourseModulesNodes>(module)
  const { getCourseContents } = useCourseContents()
  const { enrollment } = useEnrollment()
  const [isVisible, setIsVisible] = useState(false)
  const [loadedMoreModules, setLoadedMoreModules] = useState(false)
  const moduleContainerRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowDimensions()
  const media = useMedia()

  const containerPadding = useMemo(() => {
    if (media.gtMd) return 48
    return media.gtSm ? 24 : 16
  }, [media.gtMd, media.gtSm])

  const cardWidth = useMemo(() => {
    if (media.gtMd) return 407
    return media.gtSm ? 292 : 300
  }, [media.gtMd, media.gtSm])

  const spaceBetweenCards = useMemo(() => {
    return media.gtSm ? 20 : 5
  }, [media.gtSm])

  const perView =
    (width - containerPadding * 2) / (cardWidth + spaceBetweenCards)

  const findLessonProgress = (moduleId: number) => {
    return enrollment?.lessonsCompletedPerModule[moduleId] || 0
  }

  const availableContents = moduleState.courseContents.nodes.reduce(
    (acc, content) => (content.available ? acc + 1 : acc),
    0,
  )

  const displaySliderControls = availableContents > perView

  useEffect(() => {
    if (isVisible && isLastModuleOfData && !loadedMoreModules) {
      setLoadedMoreModules(true)
    }
  }, [isVisible, isLastModuleOfData, loadedMoreModules])

  useEffect(() => {
    if (isVisible && isLastModuleOfData && !loadedMoreModules) {
      setLoadedMoreModules(true)
      onLoadMoreCallBack?.()
    }
  }, [isVisible, isLastModuleOfData, loadedMoreModules, onLoadMoreCallBack])

  const loadMoreLessons = async () => {
    if (moduleState) {
      const dataCourseContents = await getCourseContents?.(
        `${moduleState.id}`,
        moduleState.courseContents.nodes.length,
      )
      const newModuleLessons =
        dataCourseContents?.course.courseModules?.nodes[0]?.courseContents
          ?.nodes

      if (newModuleLessons) {
        setModulesState((prevState) => ({
          ...prevState,
          courseContents: {
            nodes: [...prevState.courseContents.nodes, ...newModuleLessons],
          },
        }))
      }
    }
  }
  const checkInView = useCallback(
    (inView: boolean) => {
      if (!isVisible && inView) {
        setIsVisible(true)
      }
    },
    [isVisible],
  )

  if (!enrollment) return null

  return (
    <InView onChange={(inView: boolean) => checkInView(inView)}>
      <Stack
        {...rest}
        ref={moduleContainerRef}
        gap={40}
        borderTopColor={displayBorder ? 'transparent' : '$neutral-100'}
        borderTopWidth={1}
        paddingVertical={40}
      >
        <Slider
          slidesTotalNumber={moduleState.courseContents.nodes.length}
          slidesPerView={perView}
          sliderTitle={
            <ModuleSliderTitle
              isSubmodule={!module.courseModules}
              moduleName={module.name}
              totalLessons={module.courseContentsCount}
              numberOfLessons={module.courseContents?.nodes.length}
              lessonsCompletedPerModule={findLessonProgress(module.id)}
            />
          }
          displaySliderControls={displaySliderControls}
          loadMoreContent={loadMoreLessons}
        >
          {moduleState.courseContents.nodes.map((item) => {
            return (
              <LessonCard
                key={item.id}
                width={cardWidth}
                enrollment={enrollment}
                lessonData={item}
                hasContentLesson={!!item.lesson.media || !!item.lesson.activity}
              />
            )
          })}
        </Slider>
      </Stack>
    </InView>
  )
}
