import { XStack, styled } from 'tamagui'

export const Container = styled(XStack, {
  name: 'StyledToast',

  opacity: 1,
  scale: 1,
  borderWidth: 1,
  padding: '$size.spacing-xs',
  left: 0,
  $gtSm: {
    width: 350,
    paddingTop: '$size.spacing-xs',
  },
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$size.spacing-xs',
  borderRadius: '$spacing-nano',
  backgroundColor: '$success-400',
  borderColor: '$success-300',
  variants: {
    variant: {
      success: {
        backgroundColor: '$success-400',
        borderColor: '$success-300',
      },
      error: {
        backgroundColor: '$danger-400',
        borderColor: '$danger-300',
      },
      warning: {
        backgroundColor: '$warning-400',
        borderColor: '$warning-100',
      },
      info: {
        backgroundColor: '$info-400',
        borderColor: '$info-300',
      },
    },
  } as const,
  defaultVariants: {
    variant: 'success',
  },
})
