import { AppLayout } from '@layouts/AppLayout'
import { CourseProvider } from '@contexts/CourseContext'
import { EnrollmentProvider } from '@contexts/EnrollmentContext'
import { CourseContent } from '@components/enrollments/courses/CourseContent'
import { ModulesProvider } from '@contexts/ModulesContext'
import { LessonExtraContentsProvider } from '@contexts/LessonExtraContentsContext'
import { ExtraContentsProvider } from '@contexts/ExtraContentsContext'
import { CourseContentsProvider } from '@contexts/CourseContentsContext'
import { router } from 'expo-router'
import { isWeb } from 'tamagui'
import { useHasFeature } from '@hooks/useThemeParams'
import { MEMBERS_PAGE_HOME } from '@constants/features'

export default function Course() {
  const hasFeature = useHasFeature(MEMBERS_PAGE_HOME)

  const backRoute = () => {
    const route = hasFeature ? '/' : '/enrollments'
    if (isWeb) window.location.href = route
    else router.push('/')
  }

  return (
    <CourseProvider>
      <CourseContentsProvider>
        <ModulesProvider>
          <LessonExtraContentsProvider>
            <ExtraContentsProvider>
              <EnrollmentProvider>
                <AppLayout
                  backRoute={{
                    label: 'Meus Produtos',
                    back: backRoute,
                  }}
                  backgroundColor="$neutral-0"
                >
                  <CourseContent />
                </AppLayout>
              </EnrollmentProvider>
            </ExtraContentsProvider>
          </LessonExtraContentsProvider>
        </ModulesProvider>
      </CourseContentsProvider>
    </CourseProvider>
  )
}
