import { Button } from '@components/Button'
import { useTranslation } from 'react-i18next'
import { H3, Stack, Text, XStack, YStack } from 'tamagui'
import { StatusBadge } from './StatusBadge'
import { useBankSlipPrint } from '@hooks/handleBankSlip'
import { useOrderDetails } from '@contexts/OrderDetailsContext'
import { useClipboard } from '@hooks/useClipboard'

export function PaymentList() {
  const { t } = useTranslation('OrderDetails')
  const { printBankSlip } = useBankSlipPrint()
  const { copyToClipboard } = useClipboard()
  const { isSubscription, isStrategySales, payments } = useOrderDetails()

  if (!isSubscription && !isStrategySales) return null

  const isWaitingPayment = (status: string) => {
    return status === 'waiting_payment'
  }

  return (
    <YStack gap="$size.spacing-xs">
      <H3 color="$neutral-800" ff="$mulishBold" fontSize="$font-size-md">
        {t('payments.title')}
      </H3>
      <YStack gap="$size.spacing-xxxs">
        <XStack
          gap="$size.spacing-xxxs"
          $gtSm={{
            gap: '$size.spacing-xsl',
          }}
        >
          <Text
            fontSize="$font-size-xxs"
            color="$primary-300"
            textTransform="uppercase"
            ff="$mulishBold"
            width={105}
          >
            {t('payments.paymentDate')}
          </Text>

          <Text
            fontSize="$font-size-xxs"
            color="$primary-300"
            textTransform="uppercase"
            ff="$mulishBold"
            width={100}
          >
            {t('payments.amount')}
          </Text>
        </XStack>
        <YStack rowGap="$size.spacing-xs">
          {payments.map(
            ({ amount, paymentDate, status, bankSlipURL, pixQrCode }) => (
              <Stack
                key={paymentDate}
                p="$size.spacing-xs"
                br="$spacing-nano"
                borderWidth="$borderWidth.border-width-hairline"
                borderColor="$neutral-300"
                rowGap="$size.spacing-xxs"
                $gtSm={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <XStack
                  gap="$size.spacing-xxxs"
                  flexWrap="wrap"
                  $gtSm={{
                    gap: '$size.spacing-xsl',
                  }}
                >
                  <Text
                    color="$neutral-800"
                    ff="$mulishBold"
                    fontSize="$font-size-xxs"
                    width={83}
                  >
                    {paymentDate}
                  </Text>

                  <Text
                    color="$neutral-800"
                    ff="$mulishRegular"
                    fontSize="$font-size-xxs"
                    width={100}
                  >
                    {amount}
                  </Text>
                  <StatusBadge status={status} />
                </XStack>
                {isWaitingPayment(status) && bankSlipURL && (
                  <Button
                    $gtSm={{
                      marginLeft: 'auto',
                    }}
                    $gtMd={{
                      marginLeft: 124,
                    }}
                    variant="primary"
                    onPress={() => printBankSlip(bankSlipURL)}
                  >
                    {t('payments.bankSlip')}
                  </Button>
                )}
                {isWaitingPayment(status) && pixQrCode && (
                  <Button
                    $gtSm={{
                      marginLeft: 'auto',
                    }}
                    $gtMd={{
                      marginLeft: 124,
                    }}
                    variant="primary"
                    onPress={() => copyToClipboard(pixQrCode)}
                  >
                    {t('payments.pixCode')}
                  </Button>
                )}
              </Stack>
            ),
          )}
        </YStack>
      </YStack>
    </YStack>
  )
}
