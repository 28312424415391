import '@adyen/adyen-web/dist/adyen.css'
import { Modal } from '@components/Modal'
import { useTranslation } from 'react-i18next'
import { Dialog, XStack, YStack, useWindowDimensions } from 'tamagui'
import { useOrderDetails } from '@contexts/OrderDetailsContext'
import { useCallback, useEffect } from 'react'
import { useToastController } from '@tamagui/toast'
import { useSession } from '@hooks/useSession'
import { useRudderstack } from '@hooks/useAnalytics'
import { useThemeParams } from '@hooks/useThemeParams'

type ChangeCreditCardModalProps = {
  open: boolean
  onClose: () => void
}

export function ChangeCreditCardModal({
  open,
  onClose,
}: ChangeCreditCardModalProps) {
  const { data: params } = useThemeParams()
  const { t } = useTranslation('OrderDetails', {
    keyPrefix: 'subscription.changeCreditCard',
  })
  const {
    id: orderId,
    productTitle,
    subscription,
    createdAt,
  } = useOrderDetails()
  const { refetch } = useOrderDetails()
  const toast = useToastController()
  const { track } = useRudderstack()

  const handleSuccess = useCallback(async () => {
    await refetch()
    toast.show(t('toast.success.title'), {
      message: t('toast.success.description'),
    })
    track('Credit Card Updated by Student', {
      product_name: productTitle,
      subscription_id: subscription?.id,
      subscription_status: subscription?.status,
      subscription_type: subscription?.subscription_type,
      cart_id: orderId,
      cart_created_at: createdAt,
      school_id: params?.school?.id,
    })
    onClose()
  }, [
    refetch,
    onClose,
    t,
    toast,
    track,
    productTitle,
    subscription,
    orderId,
    createdAt,
    params,
  ])

  const handleError = useCallback(
    (error?: Error) => {
      toast.show(t('toast.error.title'), {
        message: t('toast.error.description'),
        type: 'error',
      })
      console.error(error)
      onClose()
    },
    [onClose, t, toast],
  )

  useEffect(() => {
    function handleReceiveMessage(event: MessageEvent) {
      if (event.data.status === 'success') {
        handleSuccess()
      } else if (event.data.status === 'error') {
        handleError()
      }
    }

    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [handleError, handleSuccess])

  const { token } = useSession()
  const { width, height } = useWindowDimensions()
  const updateCardUrl = `${process.env.EXPO_PUBLIC_SPARKCHECKOUT_API_URL}/drop-ins/update-credit-card/?token=${token}&orderId=${orderId}`

  return (
    <Modal
      backdropOpacity={0.8}
      onClose={onClose}
      open={open}
      snapPoints={[100, 100]}
      shouldAdapt={false}
    >
      <YStack
        gap="$size.spacing-xs"
        paddingHorizontal="$size.spacing-lg"
        paddingVertical="$size.spacing-xs"
        $gtSm={{
          p: '$size.spacing-lg',
          width: 600,
        }}
        height="100%"
      >
        <YStack gap="$size.spacing-lg" height="100%">
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-sm"
            textAlign="center"
          >
            {t('title')}
          </Dialog.Title>

          <XStack height={height - 100} $gtSm={{ height: 385 }}>
            <iframe
              src={updateCardUrl}
              width={width}
              height="100%"
              frameBorder="0"
            />
          </XStack>
        </YStack>
      </YStack>
    </Modal>
  )
}
