import { useAsyncStorage } from '@react-native-async-storage/async-storage'
import { authUpgrade, logout } from '@services/api/auth'
import { client } from '@services/api/client'
import { AUTH_TOKEN } from '@constants/storages'
import {
  useState,
  ReactNode,
  createContext,
  useEffect,
  useCallback,
} from 'react'
import { isWeb } from 'tamagui'
import { router, usePathname, useRootNavigationState } from 'expo-router'
import { isPublic } from '@utils/routes'
import { useRudderstack } from '@hooks/useAnalytics'
import { jwtDecode } from 'jwt-decode'
import { apolloClient } from '@services/api/apollo'
import { LoadingScreen } from '@components/LoadingScreen'
import { useUser } from '@hooks/useUser'
import { IUser } from '@dtos/User'

interface ISessionProviderProps {
  logged: boolean
  loggedLoading: boolean
  token: string | null
  user: IUser | null
  signInByToken: (token: string | null) => Promise<boolean>
}

interface IJWTHeader {
  sub: number
}

export const SessionContext = createContext<ISessionProviderProps>(
  {} as ISessionProviderProps,
)

const SKIP_ROUTES = ['/sign_in/qr']
const WEB_LOGIN_URL = '/users/sign_in'

export function SessionProvider({ children }: { children: ReactNode }) {
  const rootNavigationState = useRootNavigationState()
  const [logged, setLogged] = useState(false)
  const [loggedLoading, setLoggedLoading] = useState(true)
  const [user, setUser] = useState<IUser | null>(null)
  const [token, setSessionToken] = useState<string | null>(null)
  const { getUser } = useUser()
  const pathname = usePathname()
  const {
    getItem: getToken,
    setItem: setToken,
    removeItem: removeToken,
  } = useAsyncStorage(AUTH_TOKEN)
  const { identify } = useRudderstack()

  const shouldSkipRoute = SKIP_ROUTES.includes(pathname) || isPublic

  const handleLogout = useCallback(async () => {
    await removeToken()
    setSessionToken(null)
    await logout().finally(() => {
      if (isWeb) window.location.href = WEB_LOGIN_URL
      else router.replace('/sign_in/qr')
    })
  }, [removeToken, setSessionToken])

  const signInByToken = useCallback(
    async (token: string | null) => {
      if (!token) return false
      client.defaults.headers.Authorization = `Bearer ${token}`
      apolloClient.defaultContext.headers = {
        authorization: `Bearer ${token}`,
      }
      const { data, error } = await getUser()
      if (data) {
        setUser(data.user)
      }
      if (error) {
        return true
      }
      setLoggedLoading(false)
      setLogged(true)
      setSessionToken(token)
      return false
    },
    [getUser],
  )

  const loginOrRedirect = useCallback(async () => {
    const token = await getToken()
    if (await signInByToken(token)) {
      handleLogout()
      return
    }

    try {
      const { data } = await authUpgrade()
      await setToken(data.token)
      client.defaults.headers.Authorization = `Bearer ${data.token}`
      setLogged(true)
      setSessionToken(data.token)
      apolloClient.defaultContext.headers = {
        authorization: `Bearer ${data.token}`,
      }
      const { data: userData } = await getUser()
      if (userData) {
        setUser(userData.user)
      }
      setLoggedLoading(false)
    } catch (error) {
      setLogged(false)
    }
    // É necessário não ter dependências para que o useEffect seja executado apenas uma vez e não entre em loop infinito
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldSkipRoute) return
    if (logged || !loggedLoading) return
    loginOrRedirect()
  }, [logged, loggedLoading, loginOrRedirect, shouldSkipRoute])

  useEffect(() => {
    if (shouldSkipRoute) return
    if (isPublic) return
    if (!rootNavigationState.key) return
    if (!logged && !loggedLoading) {
      handleLogout()
    }
  }, [
    handleLogout,
    logged,
    loggedLoading,
    rootNavigationState.key,
    shouldSkipRoute,
  ])

  useEffect(() => {
    if (!shouldSkipRoute) return
    setLoggedLoading(false)
  }, [shouldSkipRoute])

  useEffect(() => {
    try {
      if (!token) return
      const { sub } = jwtDecode<IJWTHeader>(token, { header: true })
      if (!sub) return
      identify(sub)
    } catch (error) {
      handleLogout()
    }
  }, [handleLogout, identify, token])

  if (loggedLoading) return <LoadingScreen />

  return (
    <SessionContext.Provider
      value={{ logged, loggedLoading, token, signInByToken, user }}
    >
      {children}
    </SessionContext.Provider>
  )
}
