import { createMedia } from '@tamagui/react-native-media-driver'

export const medias = {
  xs: 360,
  sm: 700,
  md: 1200,
  lg: 1704,
  xl: 1920,
  xxl: 2200,
}

export const media = createMedia({
  xs: { maxWidth: medias.xs },
  gtXs: { minWidth: medias.xs + 1 },
  sm: { maxWidth: medias.sm },
  gtSm: { minWidth: medias.sm + 1 },
  md: { maxWidth: medias.md },
  gtMd: { minWidth: medias.md + 1 },
  lg: { maxWidth: medias.lg },
  gtLg: { minWidth: medias.lg + 1 },
  xl: { maxWidth: medias.xl },
  gtXl: { minWidth: medias.xl + 1 },
  xxl: { maxWidth: medias.xxl },
  gtXxl: { minWidth: medias.xxl + 1 },
})
