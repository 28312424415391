import { gql } from '@apollo/client'

export const GET_EXTRA_CONTENTS = gql`
  query getExtraContents($id: ID!, $offset: Int!, $limit: Int!) {
    course(id: $id) {
      id
      extraContents(
        withMedia: true
        pagination: { offset: $offset, limit: $limit }
      ) {
        nodes {
          title
          id
          releaseAt
          releaseAfter
          createdAt
          downloadable
          media {
            title
            type
            id
            fileUrl
            fileExt
            text
            host
            hostKey
            organizationUuid
          }
        }
      }
    }
  }
`
