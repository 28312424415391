import { ICourseContentsNodes } from '@dtos/Course'
import { getVideoThumb } from '@utils/videoThumbs'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  YStack,
  Text,
  useMedia,
  XStack,
  Stack,
  Image,
  isWeb,
} from 'tamagui'
import { LessonTypeTranslation } from '@utils/lessonMediaType'
import {
  isContentAvailable,
  releaseContentDateText,
} from '@utils/contentAvailable'
import { Animated } from 'react-native'
import { IEnrollment } from '@dtos/Enrollment'
import { HeroIcon } from '@components/HeroIcon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
interface ILessonCardProps extends React.ComponentProps<typeof Card> {
  hasContentLesson: boolean
  width: number
  lessonData: ICourseContentsNodes
  enrollment: IEnrollment
  isSliderMoving?: boolean
}

type Scale = {
  [key: string]: Animated.Value
}

export function LessonCard({
  hasContentLesson,
  width,
  lessonData,
  enrollment,
  isSliderMoving = false,
  ...rest
}: ILessonCardProps) {
  const [scaleValues, setScaleValues] = useState<Scale>({})
  const media = useMedia()

  const cardMinHeight = media.gtSm ? 300 : 206
  const setCardMinHeight = isWeb
    ? `${cardMinHeight}px !important`
    : cardMinHeight

  const dataAvailable = {
    releaseAt: lessonData.lesson.releaseAt,
    releaseAfter: lessonData.lesson.releaseAfter,
    lockAt: lessonData.lesson.lockAt,
    lockAfter: lessonData.lesson.lockAfter,
    createdAt: enrollment.activatedAt,
  }

  const setCardWidth = isWeb ? `${width}px !important` : width
  const isLessonAvailable = isContentAvailable(dataAvailable)
  const releaseDateText = releaseContentDateText(dataAvailable)
  const canAnimateCard = isLessonAvailable && !isSliderMoving
  const isYoutubeOrVimeo = ['Youtube', 'Vimeo'].includes(
    lessonData.lesson.media?.host || '',
  )
  const displayThumbnail = lessonData.lesson.coverImage || isYoutubeOrVimeo
  const lessonIconColor = isLessonAvailable ? 'primary-300' : 'neutral-200'

  const videoDuration = (duration: number) => {
    const time = duration
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time - (hours * 3600 + minutes * 60)
    const stringHours = hours.toString().padStart(2, '0')
    const stringMinutes = minutes.toString().padStart(2, '0')
    const stringSeconds = seconds.toString().padStart(2, '0')
    return `${stringHours}:${stringMinutes}:${stringSeconds}`
  }

  const thumbnailHeight = () => {
    if (media.gtMd) return 202
    if (media.gtSm) return 164
    return 153
  }

  const lessonTypeContainerHeight = () => {
    if (media.gtMd) return 202
    if (media.gtSm) return 164
    return 144
  }

  const isLessonCompleted = (lessonId: number) => {
    return enrollment?.progressCard.lessonsProgresses?.find((lesson) => {
      return lesson.lessonId === lessonId && lesson.completed
    })
  }

  const imageFilter = isLessonAvailable ? 'none' : 'grayscale(1)'
  const LESSON_STATUS_STYLES = {
    completed: {
      icon: ['fas', 'check-circle'],
      color: 'success-300',
      label: 'Item concluído',
    },
    available: {
      icon: ['fal', 'calendar-alt'],
      color: 'neutral-800',
      label: releaseDateText,
    },
  }
  const lessonStatusStyles = isLessonCompleted(lessonData.lesson.id)
    ? LESSON_STATUS_STYLES.completed
    : LESSON_STATUS_STYLES.available

  useEffect(() => {
    const newScaleValues: Scale = {}
    newScaleValues[lessonData.id.toString()] = new Animated.Value(1)
    setScaleValues(newScaleValues)
  }, [lessonData])

  const handlePressLesson = useCallback(
    (lessonId: number, isLessonAvailable: boolean) => {
      if (isLessonAvailable === false) return null
      const formattedPath = window.location.pathname.split('/online_course')[0]
      window.location.href = `${window.location.origin}${formattedPath}/course_contents/${lessonId}`
    },
    [],
  )

  const cardPadding = () => {
    if (media.gtLg) return 24
    if (media.gtSm) return 16
    return 12
  }

  const startAnimation = (id: string) => {
    if (!scaleValues[id]) return
    Animated.timing(scaleValues[id], {
      toValue: 1.1,
      duration: 700,
      useNativeDriver: false,
    }).start()
  }

  const endAnimation = (id: string) => {
    if (!scaleValues[id]) return
    Animated.timing(scaleValues[id], {
      toValue: 1,
      duration: 700,
      useNativeDriver: false,
    }).start()
  }

  if (!hasContentLesson) {
    return (
      <Card
        {...rest}
        bg="$neutral-0"
        padding={media.gtSm ? (media.gtLg ? 24 : 16) : 12}
        borderRadius={8}
        maxWidth={setCardWidth}
        minWidth={setCardWidth}
        minHeight={setCardMinHeight}
      >
        <YStack flex={1} alignItems="center" gap={24} justifyContent="center">
          <HeroIcon
            icon={['fal', 'hourglass']}
            color="$neutral-800"
            size={46}
          />
          <Text
            color="$neutral-800"
            ff="$mulishSemiBold"
            fontSize="$font-size-sm"
            whiteSpace="pre-line"
            textAlign="center"
          >
            Em breve esse conteúdo {'\n'}
            será adicionado
          </Text>
        </YStack>
      </Card>
    )
  }
  return (
    <Card
      {...rest}
      height="100%"
      flex={1}
      minWidth={setCardWidth}
      maxWidth={setCardWidth}
      minHeight={setCardMinHeight}
      style={{
        cursor: isLessonAvailable ? 'pointer' : 'not-allowed',
      }}
    >
      <Card
        flex={1}
        bg="$neutral-0"
        onPress={() => handlePressLesson(lessonData.id, isLessonAvailable)}
        borderRadius={8}
        padding={cardPadding()}
        borderColor="transparent"
        borderWidth={2}
        perspective={1000}
        onMouseEnter={() =>
          canAnimateCard ? startAnimation(lessonData.id.toString()) : null
        }
        onMouseLeave={() =>
          canAnimateCard ? endAnimation(lessonData.id.toString()) : null
        }
        scale={1}
        hoverStyle={
          canAnimateCard
            ? {
                borderColor: '$neutral-800',
                borderWidth: 2,
                transition: 'all 0.7s ease',
              }
            : {}
        }
      >
        <Stack overflow="hidden" borderRadius={8}>
          <Animated.View
            style={{
              transform: [{ scale: scaleValues[lessonData.id] || 0 }],
            }}
          >
            {isLessonAvailable === false && (
              <Stack
                bg="$warning-300"
                borderRadius="$border-radius-pill"
                position="absolute"
                zIndex={100}
                padding={8}
                right={10}
                top={10}
              >
                <HeroIcon
                  icon={['fal', 'lock-keyhole']}
                  color="$neutral-0"
                  size={25}
                />
              </Stack>
            )}
            {displayThumbnail ? (
              <Image
                style={{
                  borderRadius: 8,
                  filter: imageFilter,
                }}
                source={{
                  uri:
                    getVideoThumb({
                      ...(lessonData.lesson.media || {
                        type: 'Video',
                        id: 1,
                        title: '',
                        duration: 0,
                        fileUrl: null,
                        fileExt: null,
                        text: null,
                        hostKey: null,
                        host: null,
                        organizationUuid: null,
                      }),
                      coverImage: lessonData.lesson.coverImage,
                    }) || undefined,
                  height: thumbnailHeight(),
                }}
                alt=""
              />
            ) : (
              <XStack
                borderRadius={8}
                bg="$background-100"
                alignItems="center"
                justifyContent="center"
                height={lessonTypeContainerHeight()}
              >
                <HeroIcon
                  icon={LessonTypeTranslation(
                    lessonData.lesson.activity !== null
                      ? 'Quiz'
                      : lessonData.lesson.media?.type || 'Text',
                  )}
                  color={lessonIconColor}
                  size={50}
                />
              </XStack>
            )}
          </Animated.View>
        </Stack>
        <YStack marginTop={12}>
          <Text
            color="$neutral-800"
            numberOfLines={1}
            fontSize="$font-size-xs"
            ff="$mulishSemiBold"
            paddingBottom={8}
          >
            {lessonData.lesson.title}
          </Text>
          <XStack alignItems="center">
            {isLessonAvailable === false ||
            isLessonCompleted(lessonData.lesson.id) ? (
              <>
                <HeroIcon
                  icon={lessonStatusStyles.icon as IconProp}
                  color={lessonStatusStyles.color}
                  size={16}
                />
                <Text
                  marginLeft={4}
                  color="$neutral-800"
                  fontSize="$font-size-xxxs"
                  ff="$mulishRegular"
                >
                  {lessonStatusStyles.label}
                </Text>
              </>
            ) : lessonData.lesson.media?.duration ? (
              <XStack
                paddingHorizontal={8}
                paddingVertical={4}
                backgroundColor="$background-100"
                borderRadius={4}
              >
                <Text
                  ff="$mulishRegular"
                  color="$neutral-800"
                  fontSize="$font-size-xxs"
                >
                  {videoDuration(lessonData.lesson.media?.duration)}
                </Text>
              </XStack>
            ) : lessonData.lesson.activity !== null ? (
              <XStack
                paddingHorizontal={8}
                paddingVertical={4}
                backgroundColor="$background-100"
                borderRadius={4}
              >
                <Text
                  ff="$mulishRegular"
                  color="$neutral-800"
                  fontSize="$font-size-xxs"
                >
                  {lessonData.lesson.activity.examQuestionsCount}
                  {' questões'}
                </Text>
              </XStack>
            ) : null}
          </XStack>
        </YStack>
      </Card>
    </Card>
  )
}
