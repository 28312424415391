import { useLazyQuery } from '@apollo/client'
import { IGraphqlQueryProps } from '@dtos/Pagination'
import { GET_COURSE_CONTENTS } from './queries/getCourseContents'

interface IUseGetCourseContentsProps extends IGraphqlQueryProps {
  id: string
  moduleId: string
}

export const useGetModuleCourseContents = ({
  id,
  moduleId,
  offset,
  limit,
}: IUseGetCourseContentsProps) => {
  const [getModuleCourseContents, { data, loading, error, variables }] =
    useLazyQuery(GET_COURSE_CONTENTS, {
      variables: { id, moduleId, offset, limit },
    })

  return { getModuleCourseContents, data, loading, error, variables }
}
