import {
  library,
  IconProp,
  IconDefinition,
} from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faXmark,
  faPlus,
  faPlay,
  faCheckCircle as faCheckCircleSolid,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import {
  faArrowLeftLong,
  faExclamationCircle,
  faArrowRight,
  faCalendarAlt,
  faHourglass,
  faLockKeyhole,
  faCheckCircle,
  faTurnDownRight,
  faDownload,
  faBooks,
  faBookAlt,
  faXmark as faXmarkLight,
  faSignalStream,
  faTrashCan,
  faCartShopping,
  faWallet,
  faCreditCard,
  faBadgeDollar,
  faTriangleExclamation,
  faCircleInfo,
  faFile,
  faFileSignature,
  faMicrophone,
  faFileWord,
  faPlay as faPlayLight,
  faImage,
  faVideo,
  faFilePdf,
  faFileSpreadsheet,
  faUserGraduate,
  faDoorOpen,
  faUser,
  faUsdCircle,
  faEnvelope as faEnvelopeLight,
  faHouse,
  faBagShopping,
  faUserHeadset,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import { useTheme } from 'tamagui'
import {
  faWhatsappSolid,
  faInstagramSolid,
  faTwitterSolid,
} from '@awesome.me/kit-9f9823c2a0/icons/modules/kit/custom'

const CUSTOM_ICONS = {
  'whatsapp-solid': faWhatsappSolid,
  'twitter-solid': faTwitterSolid,
  'instagram-solid': faInstagramSolid,
}

library.add(
  faArrowLeftLong,
  faExclamationCircle,
  faVideo,
  faFilePdf,
  faFileSpreadsheet,
  faChevronDown,
  faBagShopping,
  faChevronLeft,
  faDoorOpen,
  faEnvelopeLight,
  faChevronUp,
  faXmarkLight,
  faUserGraduate,
  faChevronRight,
  faCalendarAlt,
  faHouse,
  faUser,
  faUsdCircle,
  faHourglass,
  faArrowRight,
  faSignalStream,
  faCircleInfo,
  faPlayLight,
  faGlobe,
  faFile,
  faFileSignature,
  faMicrophone,
  faFileWord,
  faPlay,
  faImage,
  faTrashCan,
  faCartShopping,
  faWallet,
  faCreditCard,
  faBadgeDollar,
  faTriangleExclamation,
  faLockKeyhole,
  faCheckCircle,
  faCheckCircleSolid,
  faTurnDownRight,
  faDownload,
  faBooks,
  faBookAlt,
  faFacebookF,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
  faEnvelope,
  faXmark,
  faPlus,
  faPlay,
  faUserHeadset,
  faUserCircle,
)

export function HeroIcon({
  icon,
  size = 24,
  color = '$neutral-200',
  style,
}: {
  icon: IconProp | string
  size?: number
  color?: string
  style?: FontAwesomeIconStyle
}) {
  const theme = useTheme()
  const setIcons = () => {
    if (typeof icon === 'string' && icon in CUSTOM_ICONS) {
      return CUSTOM_ICONS[icon as keyof typeof CUSTOM_ICONS] as IconDefinition
    }

    return icon as IconProp
  }
  const styleObject = Object.assign({}, style, { pointerEvents: 'none' })

  return (
    <FontAwesomeIcon
      icon={setIcons()}
      size={size}
      color={theme[color]?.val || color}
      style={styleObject}
    />
  )
}
