import { Button } from '@components/Button'
import { Dialog, Stack, YStack, isWeb } from 'tamagui'

import { Modal } from '@components/Modal'
import { useTranslation } from 'react-i18next'
import { HeroIcon } from '@components/HeroIcon'

type CancelSubscriptionModalProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  courseName?: string
}

export function CancelSubscriptionModal({
  open,
  courseName,
  onClose,
  onConfirm,
}: CancelSubscriptionModalProps) {
  const { t } = useTranslation('OrderDetails')

  if (!open && isWeb) return false

  return (
    <Modal onClose={onClose} open={open} snapPointsMode="fit" maxWidth={392}>
      <YStack
        gap="$size.spacing-xs"
        alignItems="center"
        paddingHorizontal="$size.spacing-lg"
        paddingVertical="$size.spacing-xsl"
        $gtSm={{
          p: '$size.spacing-lg',
        }}
      >
        <HeroIcon icon={['fal', 'trash-can']} color="$danger-300" size={48} />
        <YStack gap="$size.spacing-lg">
          <Dialog.Title
            color="$neutral-800"
            fontFamily="$mulishBold"
            fontSize="$font-size-sm"
            textAlign="center"
          >
            {t('subscription.cancelModal.title')}
          </Dialog.Title>
          <Dialog.Description
            color="$neutral-800"
            fontFamily="$mulishRegular"
            fontSize="$font-size-xxs"
            textAlign="center"
          >
            {t('subscription.cancelModal.description', { courseName })}
          </Dialog.Description>
        </YStack>
        <Stack
          mt="$size.spacing-xs"
          alignItems="flex-start"
          flexDirection="column"
          width="100%"
          gap="$size.spacing-xxs"
          $gtSm={{
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            variant="danger"
            aria-label="Cancel"
            onPress={onConfirm}
            width="100%"
            $gtSm={{
              width: 'auto',
            }}
          >
            {t('subscription.cancelModal.cta')}
          </Button>
          <Button
            variant="danger"
            outline
            aria-label="Close"
            link
            onPress={onClose}
            width="100%"
            $gtSm={{
              width: 'auto',
            }}
          >
            {t('subscription.cancelModal.stay')}
          </Button>
        </Stack>
      </YStack>
    </Modal>
  )
}
