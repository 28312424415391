import { HeroIcon } from '@components/HeroIcon'
import { Modal } from '@components/Modal'
import { useCallback } from 'react'
import { Avatar, XStack, YStack, Text } from 'tamagui'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useSignOut } from '@hooks/useUser'
import { useSession } from '@hooks/useSession'
interface IMobileMenuProps {
  open: boolean
  onClose: () => void
}

interface IMenuOptionsProps {
  label: string
  icon: IconName
  url?: string
}

export function MobileMenu({ open, onClose }: IMobileMenuProps) {
  const { user } = useSession()
  const { signOutMutate } = useSignOut()

  const menuOptions: IMenuOptionsProps[] = [
    { label: 'Meu Perfil', icon: 'user', url: `/users/${user?.id}/edit` },
    { label: 'Meus pedidos', icon: 'usd-circle', url: '/orders' },
    { label: 'Certificados', icon: 'user-graduate', url: '/certificates' },
    { label: 'Sair', icon: 'door-open' },
  ]

  const handleLogout = useCallback(() => {
    signOutMutate()
  }, [signOutMutate])

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPointsMode="percent"
      shouldAdapt={true}
    >
      <YStack paddingTop={64} paddingHorizontal={24} paddingBottom={40}>
        <XStack gap={24} alignItems="center" marginBottom={44}>
          <Avatar circular size={40} borderColor="$neutral-600" borderWidth={1}>
            <Avatar.Image src={user?.coverImageUrl} />
            <Avatar.Fallback bc="$primary-300" />
          </Avatar>
          <YStack>
            <Text
              color="$neutral-800"
              fontSize="$font-size-xs"
              ff="$mulishBold"
            >
              {user?.firstName} {user?.lastName}
            </Text>
            <Text
              color="$neutral-800"
              fontSize="$font-size-xs"
              ff="$mulishSemiBold"
            >
              {user?.email}
            </Text>
          </YStack>
        </XStack>
        {menuOptions.map((option, index) => (
          <MenuOptions key={index} {...option} handleLogout={handleLogout} />
        ))}
      </YStack>
    </Modal>
  )
}

interface IMenuOptionsProps {
  label: string
  icon: IconName
  url?: string
  handleLogout?: () => void
}

export function MenuOptions({
  label,
  icon,
  url,
  handleLogout,
}: IMenuOptionsProps) {
  return (
    <XStack
      gap={8}
      marginBottom={24}
      onPress={() => (url ? (window.location.href = url) : handleLogout)}
    >
      <HeroIcon icon={['fal', icon]} color="$neutral-600" size={20} />
      <Text color="$neutral-800" ff="$mulishRegular" fontSize="$font-size-xs">
        {label}
      </Text>
    </XStack>
  )
}
