import { View, ViewProps, Text, useMedia, getTokens } from 'tamagui'
import { Button } from '@components/Button'
import { useWindowDimensions, GestureResponderEvent, Image } from 'react-native'
import { useMediaSize } from '@hooks/useMediaSize'
import {
  BANNER_CONTENT_MAX_WIDTH,
  BANNER_MAX_HEIGHT,
} from '@constants/homeBanners'
import { LinearGradient } from 'expo-linear-gradient'
import { IBanner } from '@dtos/MembersConfig'
import { openWebBrowser } from '@utils/openWebBrowser'

interface IBannerProps extends ViewProps {
  bannerData: IBanner
  height: number
}

export function Banner({ bannerData, height, ...extraProps }: IBannerProps) {
  const media = useMedia()
  const { width } = useWindowDimensions()
  const size = useMediaSize()
  const imageUrl =
    width >= 700 ? bannerData.imageDesktopUrl : bannerData.imageMobileUrl
  const setGap = media.gtMd ? 24 : 16
  const setMarginLeft = media.gtSm ? 48 : 0
  const fontSizeTokens = getTokens().size

  const displayOverlay = bannerData.showTexts
  const overlayHeight = displayOverlay ? height : height * 0.3

  const calculateBannerMaxWidth = BANNER_CONTENT_MAX_WIDTH[size] || width

  const calculateBannerMaxHeight = BANNER_MAX_HEIGHT[size] || height

  const buttonFontSize = fontSizeTokens['$spacing-xs'].val
  const titleFontSize = media.gtMd ? '$font-size-xxl' : '$font-size-md'
  const descriptionFontSize = () => {
    if (media.gtLg) return '$font-size-sm'
    if (media.gtSm) return '$font-size-xs'
    return '$font-size-xxs'
  }

  const bannerCursorPointer = bannerData.showLinkText ? 'pointer' : 'default'
  const handleBannerPress = (e: GestureResponderEvent) => {
    e.stopPropagation()
    if (!bannerData.linkUrl || !bannerData.showLink) return
    openWebBrowser(bannerData.linkUrl)
  }

  return (
    <View
      backgroundColor="$neutral-0"
      width="100%"
      {...extraProps}
      onPress={bannerData.showLinkText ? handleBannerPress : null}
      cursor={bannerCursorPointer}
      justifyContent="center"
      flex={1}
    >
      <LinearGradient
        colors={['transparent', 'rgba(23,23,23, 1)']}
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          right: 0,
          width,
          height: overlayHeight,
          zIndex: 1,
        }}
      />
      <Image
        source={{
          uri: imageUrl,
        }}
        alt={bannerData.altText}
        style={{
          height,
          maxHeight: calculateBannerMaxHeight,
        }}
      />
      <View
        position="absolute"
        left={0}
        right={0}
        padding={16}
        maxWidth={calculateBannerMaxWidth}
        gap={setGap}
        marginLeft={setMarginLeft}
        zIndex={2}
        userSelect="none"
      >
        {bannerData.showTexts && (
          <View display="flex" flexDirection="column" gap="$spacing-inset-xs">
            {bannerData.title && (
              <Text
                ff="$mulishBold"
                fontWeight={700}
                fontSize={titleFontSize}
                color="$neutral-800"
              >
                {bannerData.title}
              </Text>
            )}
            {bannerData.description && (
              <Text
                ff="$mulishSemiBold"
                fontWeight={600}
                fontSize={descriptionFontSize()}
                color="$neutral-700"
              >
                {bannerData.description}
              </Text>
            )}
          </View>
        )}
        {bannerData.showLinkButton && (
          <Button
            variant="primary"
            backgroundColor="$neutral-800"
            size="large"
            alignSelf="flex-start"
            style={{
              fontFamily: '$mulishBold',
              fontSize: buttonFontSize,
              textAlign: 'center',
              fontWeight: 700,
            }}
            onPress={handleBannerPress}
          >
            {bannerData.linkButtonText}
          </Button>
        )}
      </View>
    </View>
  )
}
