import { CourseContentsContext } from '@contexts/CourseContentsContext'
import { useContext } from 'react'

export const useCourseContents = () => {
  return useContext(CourseContentsContext)
}

export const useGetCoursecontents = (moduleId: number, offset: number) => {
  const { getCourseContents } = useCourseContents()
  getCourseContents?.(`${moduleId}`, offset)
}
