export const borderRadius = {
  'spacing-nano': 4,
  'spacing-xxxs': 8,
  'border-radius-pill': 200,
}

export const borderWidth = {
  'border-width-hairline': 1,
  'border-width-thin': 2,
}
