import { ContinueLessonsContext } from '@contexts/ContinueLessonsContext'
import { useContext } from 'react'

export const useContinueLessons = () => {
  const context = useContext(ContinueLessonsContext)

  return {
    continueLessons: context.continueLessons,
    isLoading: context.isLoading,
  }
}
