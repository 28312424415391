import { HeroIcon } from '@components/HeroIcon'
import { Button } from '@components/Button'
import { useTranslation } from 'react-i18next'
import { H3, Stack, Text, XStack, YStack } from 'tamagui'
import { GestureReponderEvent } from '@tamagui/core'
import { camelCase } from 'lodash'
import { useBankSlipPrint } from '@hooks/handleBankSlip'
import { useOrderDetails } from '@contexts/OrderDetailsContext'
import { useClipboard } from '@hooks/useClipboard'
import { useState, useMemo } from 'react'
import { ChangeCreditCardModal } from './ChangeCreditCardModal'
import { AuthEmailModal } from '@components/AuthEmailModal'
import { useSession } from '@hooks/useSession'
import { useHasFeature, useThemeParams } from '@hooks/useThemeParams'
import { useToastController } from '@tamagui/toast'
import { useLocalSearchParams } from 'expo-router'
import {
  SUBSCRIPTION_ACTIVE_STATUS,
  SUBSCRIPTION_PAST_DUE_STATUS,
} from '@constants/subscription'
import { addDays } from '@utils/dateFormatter'
import { parse, parseISO } from 'date-fns'
import { HeroTooltip } from '@components/HeroTooltip'
import { useRefundPayment } from '@hooks/usePayment'
import { ApolloError } from '@apollo/client'
import { DISABLE_MEMBER_AREA_REFUND } from '@constants/features'
import { PaymentRefundBankSlipModal } from './PaymentRefundBankSlipModal'
import { PaymentRefundContactProducerModal } from './PaymentRefundContactProducerModal'
import { PaymentRefundGenericErrorModal } from './PaymentRefundGenericErrorModal'
import { PaymentRefundErrorModal } from './PaymentRefundErrorModal'

type ModalAction = 'changeCreditCard' | 'refundPayment' | 'cancelSubscription'

export function PaymentMethod() {
  const { action } = useLocalSearchParams<{ action?: string }>()
  const [modalAction, setModalAction] = useState(action as ModalAction)
  const isChangeCreditCardModalOpen = action === 'changeCreditCard'
  const { t } = useTranslation('OrderDetails')
  const { printBankSlip } = useBankSlipPrint()
  const {
    relevantPayment,
    subscription,
    payments = [],
    id,
    productTitle,
    createdAt,
    refetch,
  } = useOrderDetails()
  const [openModal, setOpenModal] = useState(isChangeCreditCardModalOpen)
  const [openRefundBankSlipModal, setOpenRefundBankSlipModal] = useState(false)
  const [openRefundContactProducerModal, setOpenRefundContactProducerModal] =
    useState(false)
  const [openRefundErrorModal, setOpenRefundErrorModal] = useState(false)
  const [openRefundGenericErrorModal, setOpenRefundGenericErrorModal] =
    useState(false)
  const [isProcessingRefund, setIsProcessingRefund] = useState(false)
  const { logged } = useSession()
  const { copyToClipboard } = useClipboard()
  const toast = useToastController()
  const { handleRefund } = useRefundPayment()
  const { data: themeParams } = useThemeParams()

  const disableAutomaticMemberAreaRefund = useHasFeature(
    DISABLE_MEMBER_AREA_REFUND,
  )

  const allowChangeCreditCard =
    !!subscription &&
    relevantPayment.paymentMethod === 'credit_card' &&
    ['recurrency', 'smart_installment'].includes(
      subscription.subscription_type as string,
    ) &&
    [SUBSCRIPTION_ACTIVE_STATUS, SUBSCRIPTION_PAST_DUE_STATUS].includes(
      subscription.status,
    )

  const paymentMethodTranslationKey = `paymentMethod.${camelCase(
    relevantPayment.paymentMethod,
  )}`

  const hasAlreadyRefunded = ['refund_analyzing', 'refunded'].includes(
    relevantPayment.status,
  )
  const bankSlipURL = relevantPayment.bankSlipURL
  const showBankSlip = bankSlipURL && payments.length === 1
  const isBankSlip = relevantPayment.paymentMethod === 'bank_slip'
  const isAnalyzingRefund = relevantPayment.status === 'refund_analyzing'

  const pixCode = relevantPayment.pixQrCode
  const showPixCode = pixCode && payments.length === 1

  const isValidPaymentDate = (paymentDate?: string) => {
    if (!paymentDate) return false

    const [day, month, year] = paymentDate.split('/').map(Number)

    if (!day || !month || !year) return false

    const parsedPaymentDate = parse(
      `${year}-${month}-${day}`,
      'yyyy-MM-dd',
      new Date(),
    )

    if (isNaN(parsedPaymentDate.getTime())) return false

    const paymentDateRefundLimit = addDays(parsedPaymentDate.toISOString(), 7)
    return new Date() < parseISO(paymentDateRefundLimit)
  }

  const relevantPaymentDate = payments[0]?.paymentDate

  const isPaymentRefundable = useMemo(() => {
    if (!relevantPayment.status || !payments?.length) return false

    const isPaymentConfirmed = ['confirmed', 'approved', 'paid'].includes(
      relevantPayment.status,
    )
    if (!isPaymentConfirmed) return false

    return isValidPaymentDate(relevantPaymentDate)
  }, [relevantPayment.status, payments.length, relevantPaymentDate])

  function handleChangeCreditCard() {
    setModalAction('changeCreditCard')
    setOpenModal(true)
  }

  function isSparkecommerceError(error: ApolloError) {
    return error.message === 'error_refund_request_failed'
  }

  async function onPressRefundButton(e?: GestureReponderEvent) {
    if (e?.stopPropagation) {
      e.stopPropagation()
    }

    if (!logged) {
      setModalAction('refundPayment')
      setOpenModal(true)
      return
    }

    if (disableAutomaticMemberAreaRefund) {
      setOpenRefundContactProducerModal(true)
      return
    }

    if (isBankSlip) {
      setOpenRefundBankSlipModal(true)
      return
    }

    try {
      setIsProcessingRefund(true)
      await handleRefund(relevantPayment.id)

      toast.show(t('refundSuccess.toast.title'), {
        message: t('refundSuccess.toast.message'),
        type: 'success',
      })

      await refetch()
    } catch (error: ApolloError) {
      if (isSparkecommerceError(error)) {
        setOpenRefundErrorModal(true)
        return
      }

      setOpenRefundGenericErrorModal(true)
    } finally {
      setIsProcessingRefund(false)
    }
  }

  return (
    <YStack gap="$size.spacing-xs">
      <H3 color="$neutral-800" ff="$mulishBold" fontSize="$font-size-md">
        {t('paymentMethod.title')}
      </H3>
      <Stack
        p="$size.spacing-xs"
        br="$spacing-nano"
        borderWidth="$borderWidth.border-width-hairline"
        borderColor="$neutral-300"
        gap="$size.spacing-xxs"
        $gtSm={{
          flexDirection: 'row',
          gap: '$size.spacing-xsl',
          padding: '$size.spacing-md',
        }}
      >
        <XStack
          gap="$size.spacing-xxs"
          justifyContent="space-between"
          flexDirection="column"
          width="100%"
          $gtMd={{
            flexDirection: 'row',
          }}
        >
          <XStack
            gap={12}
            flexDirection="column"
            $gtSm={{
              flexDirection: 'row',
              gap: 48,
            }}
          >
            <XStack gap="$size.spacing-xxxs" ai="center">
              <HeroIcon
                icon={['fal', 'wallet']}
                color="$neutral-800"
                size={24}
              />
              <Text
                color="$neutral-800"
                ff="$mulishRegular"
                fontSize="$font-size-xxs"
              >
                {t(paymentMethodTranslationKey)}
              </Text>
            </XStack>
            {subscription?.credit_card_masked_number && (
              <XStack gap="$size.spacing-xxs">
                <XStack gap="$size.spacing-xxxs" ai="center">
                  <HeroIcon
                    icon={['fal', 'credit-card']}
                    color="$neutral-800"
                    size={24}
                  />
                  <Text
                    color="$neutral-800"
                    ff="$mulishRegular"
                    fontSize="$font-size-xxs"
                  >
                    {subscription?.credit_card_masked_number}
                  </Text>
                </XStack>
              </XStack>
            )}
          </XStack>
          <XStack
            gap="$size.spacing-xs"
            flexDirection="column"
            $gtSm={{
              flexDirection: 'row',
              gap: 48,
            }}
          >
            {isPaymentRefundable && (
              <HeroTooltip
                placement="top"
                width={150}
                text={t('paymentMethod.disabledPaymentRefundTooltip')}
                display={hasAlreadyRefunded}
              >
                <Button
                  variant="primary"
                  link
                  disabled={hasAlreadyRefunded || isProcessingRefund}
                  testID="refund-button"
                  aria-label={t('paymentMethod.paymentRefundBtnLabel')}
                  onPress={onPressRefundButton}
                >
                  <Text
                    fontSize="$font-size-xxs"
                    ff="$mulishRegular"
                    color={
                      hasAlreadyRefunded || isProcessingRefund
                        ? '$neutral-500'
                        : '$primary-300'
                    }
                    textDecorationLine={
                      hasAlreadyRefunded || isProcessingRefund
                        ? 'none'
                        : 'underline'
                    }
                  >
                    {isProcessingRefund
                      ? t('paymentMethod.processingRefund')
                      : t('paymentMethod.paymentRefundBtnLabel')}
                  </Text>
                </Button>
              </HeroTooltip>
            )}

            {isAnalyzingRefund && (
              <HeroTooltip
                placement="top"
                width={150}
                text={t('paymentMethod.disabledPaymentRefundTooltip')}
                display={isAnalyzingRefund}
              >
                <Button variant="primary" disabled>
                  {t('paymentMethod.paymentRefundBtnLabel')}
                </Button>
              </HeroTooltip>
            )}

            {allowChangeCreditCard && (
              <Button
                variant="primary"
                $gtSm={{
                  width: 'auto',
                  marginLeft: 'auto',
                }}
                onPress={handleChangeCreditCard}
              >
                {t('paymentMethod.cta')}
              </Button>
            )}

            {showBankSlip && (
              <Button
                variant="primary"
                $gtSm={{
                  width: 'auto',
                  marginLeft: 'auto',
                }}
                onPress={() => printBankSlip(bankSlipURL)}
              >
                {t('paymentMethod.ctaBankSlip')}
              </Button>
            )}

            {showPixCode && (
              <Button
                variant="primary"
                $gtSm={{
                  width: 'auto',
                  marginLeft: 'auto',
                }}
                onPress={() => copyToClipboard(pixCode)}
              >
                {t('paymentMethod.ctaPixCode')}
              </Button>
            )}
          </XStack>
        </XStack>
      </Stack>
      <ChangeCreditCardModal
        open={logged && openModal}
        onClose={() => setOpenModal(false)}
      />

      <PaymentRefundBankSlipModal
        open={openRefundBankSlipModal}
        onClose={() => setOpenRefundBankSlipModal(false)}
      />

      <PaymentRefundContactProducerModal
        open={openRefundContactProducerModal}
        onClose={() => setOpenRefundContactProducerModal(false)}
        producerEmail={themeParams?.school?.email}
        producerSchoolName={themeParams?.school?.name || ''}
      />

      <PaymentRefundErrorModal
        open={openRefundErrorModal}
        onClose={() => setOpenRefundErrorModal(false)}
      />

      <PaymentRefundGenericErrorModal
        open={openRefundGenericErrorModal}
        onClose={() => setOpenRefundGenericErrorModal(false)}
      />

      <AuthEmailModal
        onClose={() => setOpenModal(false)}
        open={!logged && openModal}
        orderInfo={{
          id,
          subscription,
          productTitle,
          createdAt,
        }}
        action={modalAction}
      />
    </YStack>
  )
}
