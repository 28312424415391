import { useQuery } from '@apollo/client'
import { GET_MODULE } from './queries/getModules'
import { IGraphqlQueryProps } from '@dtos/Pagination'

interface IUseGetCourseProps extends IGraphqlQueryProps {
  moduleId?: string
}

export const useGetModules = ({
  id,
  moduleId,
  offset,
  limit,
}: IUseGetCourseProps) => {
  const { data, loading, error, fetchMore, variables } = useQuery(GET_MODULE, {
    variables: { id, moduleId, offset, limit },
  })

  return { data, loading, error, fetchMore, variables }
}
