import { Order } from '@dtos/Order'
import { client } from '@services/api/client'
import { client as spkecClient } from '@services/api/sparkecommerce/client'

export async function getOrder(orderId: number | string) {
  return client.get<Order>(`/members/orders/${orderId}`)
}

export async function getPublicOrder(orderId: number | string) {
  return spkecClient.get<Order>(`/public/carts/${orderId}`)
}

export async function cancelSubscription(orderId: number | string) {
  return client.put(`/orders/${orderId}/cancel`)
}
