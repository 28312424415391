import { AUTH_TOKEN } from '@constants/storages'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { isPublic } from '@utils/routes'
import axios from 'axios'

export const client = axios.create({
  baseURL: process.env.EXPO_PUBLIC_API_URL,
})

// Redirect to login page if 401 response
client.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401 && !isPublic) {
      AsyncStorage.removeItem(AUTH_TOKEN).finally(
        () => (window.location.href = '/users/sign_in'),
      )
    }
    return Promise.reject(error)
  },
)
