import { gql } from '@apollo/client'

export const GET_LAST_LESSONS_VIEWED_CONTENT = gql`
  query getLastLessonsViewedContent($id: ID!, $lessonsIds: [Int!]!) {
    course(id: $id) {
      id
      lessons(
        ids: $lessonsIds
        pagination: { offset: 0, limit: 5 }
        orderDirection: "desc"
      ) {
        nodes {
          id
          title
          createdAt
          releaseAt
          releaseAfter
          lockAt
          lockAfter
          courseContentId
          coverImage {
            cdnFileUrl
          }
          media {
            id
            title
            id
            type
            fileUrl
            fileExt
            host
            hostKey
            text
          }
        }
      }
    }
  }
`
