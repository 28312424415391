import { HeroIcon } from '@components/HeroIcon'
import { Button } from '@components/Button'
import { useTranslation } from 'react-i18next'
import { H3, Stack, Text, XStack, YStack } from 'tamagui'
import { AuthEmailModal } from '@components/AuthEmailModal'
import { useState } from 'react'
import { CancelSubscriptionModal } from './CancelSubscriptionModal'
import { UnsubscribeSuccessModal } from './UnsubscribeSuccessModal'
import { cancelSubscription } from '@services/api/order'
import { useToastController } from '@tamagui/toast'
import { useOrderDetails } from '@contexts/OrderDetailsContext'
import { useSession } from '@hooks/useSession'
import { useLocalSearchParams } from 'expo-router'
import { useRudderstack } from '@hooks/useAnalytics'
import {
  SUBSCRIPTION_ACTIVE_STATUS,
  SUBSCRIPTION_PAST_DUE_STATUS,
} from '@constants/subscription'
import { useThemeParams } from '@hooks/useThemeParams'

export function Subscription() {
  const { data: params } = useThemeParams()
  const { action } = useLocalSearchParams<{ action?: string }>()
  const isChangeCreditCardModalOpen = action === 'cancelSubscription'
  const { t } = useTranslation('OrderDetails')
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(isChangeCreditCardModalOpen)
  const [successCancelModal, setSuccessCancelModal] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const toast = useToastController()
  const { logged } = useSession()

  const {
    isSubscription,
    relevantPayment,
    subscription,
    productTitle,
    createdAt,
    id,
    refetch,
  } = useOrderDetails()

  const { track } = useRudderstack()

  async function handleSubscriptionCancel() {
    setCancelling(true)

    try {
      await cancelSubscription(id)
      await refetch()

      setCancelModal(false)
      setSuccessCancelModal(true)
      track('Subscription Cancelled by Student', {
        product_name: productTitle,
        subscription_id: subscription?.id,
        subscription_status: subscription?.status,
        subscription_type: subscription?.subscription_type,
        cart_id: id,
        cart_created_at: createdAt,
        school_id: params?.school?.id,
      })
    } catch (error) {
      setCancelModal(false)
      toast.show(t('subscription.toast.title'), {
        message: t('subscription.toast.description'),
        type: 'error',
      })
      console.error(error)
    } finally {
      setCancelling(false)
    }
  }

  function handleCancel() {
    if (!logged) return setOpenLoginModal(true)
    setCancelModal(true)
  }

  if (!isSubscription || !subscription) return null

  const allowCancelSubscription = [
    SUBSCRIPTION_ACTIVE_STATUS,
    SUBSCRIPTION_PAST_DUE_STATUS,
  ].includes(subscription.status)

  return (
    <YStack gap="$size.spacing-xs">
      <H3 color="$neutral-800" ff="$mulishBold" fontSize="$font-size-md">
        {t('subscription.title')}
      </H3>
      <Stack
        p="$size.spacing-xs"
        br="$spacing-nano"
        borderWidth="$borderWidth.border-width-hairline"
        borderColor="$neutral-300"
        rowGap="$size.spacing-xxs"
        $gtSm={{
          flexDirection: 'row',
          padding: '$size.spacing-md',
          columnGap: '$size.spacing-lg',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          rowGap="$size.spacing-xxs"
          flexWrap="wrap"
          $gtSm={{
            flexDirection: 'row',
            columnGap: '$size.spacing-lg',
          }}
        >
          <XStack gap="$size.spacing-xxs">
            <XStack gap="$size.spacing-xxxs" ai="center">
              <HeroIcon
                icon={['fal', 'badge-dollar']}
                color="$neutral-800"
                size={24}
              />
              <Text
                color="$neutral-800"
                ff="$mulishRegular"
                fontSize="$font-size-xxs"
              >
                {t('subscription.amount')}:{' '}
                <Text ff="$mulishBold" color="$neutral-800">
                  {relevantPayment.amount}
                </Text>
              </Text>
            </XStack>
          </XStack>
          {allowCancelSubscription && (
            <XStack gap="$size.spacing-xxs">
              <XStack gap="$size.spacing-xxxs" ai="center">
                <HeroIcon
                  icon={['fal', 'calendar-alt']}
                  color="$neutral-800"
                  size={24}
                />
                <Text
                  color="$neutral-800"
                  ff="$mulishRegular"
                  fontSize="$font-size-xxs"
                >
                  {t('subscription.bill')}:{' '}
                  <Text ff="$mulishBold" color="$neutral-800">
                    {subscription.nextInvoiceAt}
                  </Text>
                </Text>
              </XStack>
            </XStack>
          )}
        </Stack>
        {allowCancelSubscription && (
          <Button loading={cancelling} variant="danger" onPress={handleCancel}>
            {t('subscription.cancel')}
          </Button>
        )}
        <AuthEmailModal
          onClose={() => setOpenLoginModal(false)}
          open={openLoginModal}
          orderInfo={{
            id,
            subscription,
            productTitle,
            createdAt,
          }}
          action="cancelSubscription"
        />
        <CancelSubscriptionModal
          open={cancelModal}
          onClose={() => setCancelModal(false)}
          onConfirm={handleSubscriptionCancel}
          courseName={productTitle}
        />
        <UnsubscribeSuccessModal
          open={successCancelModal}
          onClose={() => setSuccessCancelModal(false)}
          courseName={productTitle}
          expirationDate={subscription?.nextInvoiceAt}
        />
      </Stack>
    </YStack>
  )
}
