import { View, useMedia } from 'tamagui'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { openWebBrowser } from '@utils/openWebBrowser'
import { useState } from 'react'
import { HeroIcon } from './HeroIcon'

type ISocialMediaIconsProps = {
  icon: [IconPrefix, IconName] | string
  url: string
}

const handleSocialMediaPress = (url: string) => {
  openWebBrowser(url)
}

export function FooterSocialMediaIcon({ icon, url }: ISocialMediaIconsProps) {
  const [isHovering, setIsHovering] = useState(false)
  const media = useMedia()
  const setColor = isHovering ? '$primary-300' : '$neutral-700'
  const setMarginBottom = media.gtLg ? 0 : '$size.spacing-xxxs'

  return (
    <View
      testID="footer-social-media-icon"
      borderWidth={1}
      padding={10}
      borderRadius="$border-radius-pill"
      marginBottom={setMarginBottom}
      width={40}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      height={40}
      borderColor={setColor}
      onPress={() => handleSocialMediaPress(url)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <HeroIcon icon={icon} size={20} color={setColor} />
    </View>
  )
}
