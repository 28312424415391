import { YStack, View } from 'tamagui'
import React, { useMemo } from 'react'
import { useModules } from '@hooks/useModules'
import { ModuleContainer } from './ModuleContainer'

export function CourseModules() {
  const { modules, onLoadMore } = useModules()

  const filteredAvailableModules = useMemo(() => {
    if (!modules) return []
    return modules.nodes.filter((module) => module.available !== false)
  }, [modules])

  if (!modules) return null
  return (
    <YStack
      bg="$background-100"
      p={'$size.spacing-xs'}
      flex={1}
      $gtSm={{
        padding: '$size.spacing-md',
        paddingTop: 0,
      }}
      $gtMd={{
        padding: '$size.spacing-xsl',
        paddingTop: 0,
      }}
    >
      {filteredAvailableModules.map((module, index) => {
        if (module.available === false) return null
        return (
          <View key={module.id}>
            <ModuleContainer
              module={module}
              isLastModuleOfData={index === filteredAvailableModules.length - 1}
              displayBorder={index === 0}
              onLoadMoreCallBack={onLoadMore}
            />
            {module.courseModules.map((submodule) => {
              return <ModuleContainer key={submodule.id} module={submodule} />
            })}
          </View>
        )
      })}
    </YStack>
  )
}
