import { GetProps, Stack, Text, styled } from 'tamagui'

type LabelProps = GetProps<typeof Text> & {
  outline: boolean
}

type VariantProps = {
  props: LabelProps
}

export const Label = styled(Text, {
  ff: '$mulishSemiBold',
  fontSize: '$font-size-xxxs',
  variants: {
    variant: {
      primary: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$primary-100' : '$neutral-0',
      }),
      success: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$success-100' : '$neutral-0',
      }),
      info: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$info-100' : '$neutral-0',
      }),
      warning: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$warning-100' : '$neutral-0',
      }),
      danger: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$danger-100' : '$neutral-0',
      }),
      disable: (_, { props: { outline } }: VariantProps) => ({
        color: outline ? '$neutral-600' : '$neutral-800',
      }),
    },
    outline: {
      true: {},
    },
  } as const,
  defaultVariants: {
    variant: 'primary',
    outline: false,
  },
})

export const Container = styled(Stack, {
  name: 'Container',
  borderRadius: '$border-radius-pill',
  paddingHorizontal: '$size.spacing-xxs',
  paddingVertical: '$size.spacing-nano',
  borderWidth: '$borderWidth.border-width-hairline',
  alignSelf: 'flex-start',
  variants: {
    variant: {
      primary: (_, { props: { outline } }: VariantProps) => ({
        bg: '$primary-300',
        borderColor: outline ? '$primary-300' : '$primary-100',
      }),
      success: (_, { props: { outline } }: VariantProps) => ({
        bg: '$success-300',
        borderColor: outline ? '$success-100' : '$success-300',
      }),
      info: (_, { props: { outline } }: VariantProps) => ({
        bg: '$info-300',
        borderColor: outline ? '$info-100' : '$info-300',
      }),
      warning: (_, { props: { outline } }: VariantProps) => ({
        bg: '$warning-300',
        borderColor: outline ? '$warning-100' : '$warning-300',
      }),
      danger: (_, { props: { outline } }: VariantProps) => ({
        bg: '$danger-300',
        borderColor: outline ? '$danger-100' : '$danger-300',
      }),
      disable: (_, { props: { outline } }: VariantProps) => ({
        bg: '$neutral-300',
        borderColor: outline ? '$neutral-600' : '$neutral-300',
      }),
    },
    outline: {
      true: {
        bg: 'transparent',
      },
    },
  } as const,
  defaultVariants: {
    variant: 'primary',
    outline: false,
  },
})
