import { Shimmer } from '@components/Shimmer'
import { YStack, Text, XStack, Stack } from 'tamagui'
import { useTranslation } from 'react-i18next'

export function ContinueLessonsGhostLoading() {
  const { t } = useTranslation('Home')
  return (
    <>
      <Stack flexDirection="column" marginTop={60}>
        <YStack
          padding={16}
          $gtSm={{
            flexDirection: 'column',
            gap: 20,
            padding: '$size.spacing-xs',
          }}
          $gtMd={{
            padding: '$size.spacing-xsl',
          }}
          $gtLg={{
            width: '100%',
            marginHorizontal: 'auto',
            padding: '$size.spacing-xl',
          }}
        >
          <Text
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-xs"
            $gtMd={{ fontSize: '$font-size-md' }}
            fontWeight={700}
          >
            {t('continue.continueSectionTitle.title')}
          </Text>
          <XStack marginTop={20} alignSelf="baseline" gap={20}>
            <Shimmer
              width={204}
              height={115}
              $gtSm={{ width: 345, height: 194 }}
            />
            <Shimmer
              width={204}
              height={115}
              $gtSm={{ width: 345, height: 194 }}
            />
            <Shimmer
              width={204}
              height={115}
              $gtSm={{ width: 345, height: 194 }}
            />
            <Shimmer
              width={204}
              height={115}
              $gtSm={{ width: 345, height: 194 }}
            />
            <Shimmer
              width={204}
              height={115}
              $gtSm={{ width: 345, height: 194 }}
            />
          </XStack>
        </YStack>
      </Stack>
    </>
  )
}
