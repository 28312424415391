import * as Sentry from '@sentry/react-native'
import { isRunningInExpoGo } from 'expo'

export const navigationIntegration = Sentry.reactNavigationIntegration({
  enableTimeToInitialDisplay: !isRunningInExpoGo(),
})

Sentry.init({
  enabled: !__DEV__,
  dsn: process.env.EXPO_PUBLIC_SENTRY_DSN,
  debug: false,
  environment: process.env.EXPO_PUBLIC_SENTRY_ENVIRONMENT,
  ignoreErrors: ['Empty page!'],
  tracesSampleRate: 1.0,
  enableAutoPerformanceTracing: true,
  enableNativeFramesTracking: !isRunningInExpoGo(),
  integrations: [navigationIntegration],
})
