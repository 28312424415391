import { gql } from '@apollo/client'

export const GET_USER_ENROLLMENTS = gql`
  query getUserEnrollments($offset: Int!, $limit: Int!) {
    user {
      id
      enrollmentsCount
      enrollments(
        pagination: { limit: $limit, offset: $offset }
        orderBy: "createdAt"
        orderDirection: "desc"
      ) {
        id
        activatedAt
        availableUntil
        livemeetRoomsAvailable {
          id
        }
        progressCard {
          id
          progress
          total
          completed
        }
        schoolProduct {
          id
          title
          logo
          logoVertical
          path {
            id
          }
          courses {
            id
            kind
            lessons(pagination: { offset: 0, limit: 1 }) {
              nodes {
                id
                courseContentId
              }
            }
          }
        }
      }
    }
  }
`
