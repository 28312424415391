import { useGetCustomProductLists } from '@hooks/useMembersConfig'
import { CustomProductListSlider } from './CustomProducListSlider'

export function CustomProductList() {
  const { data: customProductLists } = useGetCustomProductLists()

  return (
    <>
      {customProductLists?.map((list) => {
        if (!list.active) return null
        return (
          <CustomProductListSlider
            key={list.id}
            list={list}
            cardFormat={list.kind}
          />
        )
      })}
    </>
  )
}
