import { IMedia } from '@dtos/Course'
export interface IVideoThumbMedia extends IMedia {
  coverImage: {
    cdnFileUrl: string
  } | null
}

export function getVideoThumb(media: IVideoThumbMedia | null): string | null {
  if (!media) return null
  if (media.coverImage?.cdnFileUrl) return media.coverImage.cdnFileUrl
  switch (media.host) {
    case 'Youtube':
      return `https://img.youtube.com/vi/${media.hostKey}/hqdefault.jpg`
    case 'Vimeo':
      return `https://vumbnail.com/${media.hostKey}.jpg`
    default:
      return null
  }
}
