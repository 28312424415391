import React, { useState } from 'react'
import { View, XStack, useMedia, XStackProps } from 'tamagui'
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { SliderControl } from '@components/SliderControl'
import { Button } from '@tamagui/button'

export interface IHeroCarouselProps {
  numberOfSlides: number
  children: React.ReactNode
  height?: number
  displayControls?: boolean
  indicatorsStyles?: XStackProps
  sliderTitle?: React.ReactNode
  autoPlay?: boolean
  autoPlayInterval?: number
  stopOnHover?: boolean
  isCarousel?: boolean
  maxHeight?: number
}

export function HeroCarousel({
  numberOfSlides,
  children,
  height,
  displayControls = true,
  indicatorsStyles,
  autoPlay = false,
  autoPlayInterval = 3000,
  stopOnHover = true,
  maxHeight,
}: IHeroCarouselProps) {
  const [opacities, setOpacities] = useState([0])
  const [currentSlide, setCurrentSlide] = useState(0)
  const media = useMedia()

  const defineColor = (index: number) => {
    return currentSlide === index ? '$primary-300' : '$neutral-300'
  }

  const centrilizeSliderControls = media.gtMd ? '50%' : '40%'

  const autoPlayPlugin = (slider: KeenSliderInstance) => {
    if (!autoPlay || numberOfSlides === 1) return
    let timeout: ReturnType<typeof setTimeout>
    let mouseOver = false
    function clearNextTimeout() {
      clearTimeout(timeout)
    }
    function nextTimeout() {
      clearTimeout(timeout)
      if (mouseOver) return
      timeout = setTimeout(() => {
        slider.next()
      }, autoPlayInterval)
    }
    slider.on('created', () => {
      if (stopOnHover) {
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true
          clearNextTimeout()
        })
      }
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false
        nextTimeout()
      })
      nextTimeout()
    })
    slider.on('dragStarted', clearNextTimeout)
    slider.on('animationEnded', nextTimeout)
    slider.on('updated', nextTimeout)
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: numberOfSlides,
      renderMode: 'performance',
      loop: true,
      detailsChanged(s) {
        const newOpacities = s.track.details.slides.map(
          (slide) => slide.portion,
        )
        setOpacities(newOpacities)
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
    },
    [autoPlayPlugin],
  )

  return (
    <View>
      <View
        ref={sliderRef}
        className="fader"
        position="relative"
        width="100%"
        height={height}
        maxHeight={maxHeight}
        justifyContent="center"
        alignSelf="center"
      >
        {numberOfSlides > 1 && displayControls && (
          <>
            <XStack
              position="absolute"
              zIndex={999}
              left={-10}
              bottom={centrilizeSliderControls}
            >
              <SliderControl
                action={() => instanceRef.current?.prev()}
                chevronDirection="left"
                size={35}
                isCarousel={true}
              />
            </XStack>
            <XStack
              position="absolute"
              zIndex={999}
              right={-10}
              bottom={centrilizeSliderControls}
            >
              <SliderControl
                action={() => instanceRef.current?.next()}
                chevronDirection="right"
                size={35}
                isCarousel={true}
              />
            </XStack>
          </>
        )}

        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement, {
              className:
                `${(child.props as { className?: string }).className || ''} fader__slide`.trim(),
              style: {
                position: 'absolute',
                opacity: opacities[index],
                zIndex: opacities[index] === 1 ? 100 : 1,
              },
            })
          }
          return child
        })}
      </View>
      {numberOfSlides > 1 && (
        <XStack
          position="relative"
          justifyContent="center"
          gap={16}
          paddingTop={60}
          {...indicatorsStyles}
        >
          {Array.from({ length: numberOfSlides }).map((_, index) => (
            <Button
              key={index}
              onPress={() => instanceRef.current?.moveToIdx(index)}
              animation="slow"
              bottom={0}
              size={8}
              borderRadius={100}
              width={currentSlide === index ? 32 : 8}
              bg={defineColor(index)}
              borderColor={defineColor(index)}
              hoverStyle={{ borderColor: '$primary-300', bg: '$primary-300' }}
              pressStyle={{ borderColor: '$primary-300', bg: '$primary-300' }}
              style={{
                transition: 'width 0.3s ease-in-out',
              }}
            />
          ))}
        </XStack>
      )}
    </View>
  )
}
