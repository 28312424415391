import { createFont } from 'tamagui'

const baseFont = {
  size: {
    'font-size-xxxs': 12,
    'font-size-xxs': 14,
    'font-size-xs': 16,
    'font-size-sm': 20,
    'font-size-md': 24,
    'font-size-lg': 32,
    'font-size-xl': 40,
    'font-size-xxl': 48,
    'font-size-display': 64,
    heading: 24,
  },
  lineHeight: {
    'line-height-default': 20,
    'line-height-compact': 25,
    'line-height-comfortable': 50,
  },
}

export const mulishRegular = createFont({
  family: 'Mulish',
  ...baseFont,
  weight: {
    'font-weight-regular': 400,
  },
  face: {
    400: { normal: 'Mulish_400Regular' },
    true: { normal: 'Mulish_400Regular' },
  },
})

export const mulishSemiBold = createFont({
  family: 'Mulish',
  ...baseFont,
  weight: {
    'font-weight-semi-bold': 600,
    true: 600,
  },
  face: {
    600: { normal: 'Mulish_600SemiBold' },
    true: { normal: 'Mulish_600SemiBold' },
  },
})

export const mulishBold = createFont({
  family: 'Mulish',
  ...baseFont,
  weight: {
    'font-weight-bold': 700,
    true: 700,
  },
  face: {
    700: { normal: 'Mulish_700Bold' },
    true: { normal: 'Mulish_700Bold' },
  },
})
