import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ptBR from './pt-BR.json'

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    ptBR,
  },
  lng: 'ptBR',
  fallbackLng: 'ptBR',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
})
