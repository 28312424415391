import { ScreenLockerLoad } from '@components/ScreenLockerLoad'
import React, { useState, ReactNode } from 'react'

interface ILockParams {
  title: string
  description: string
}
interface IProviderProps {
  lock: ({ title, description }: ILockParams) => void
  unlock: () => void
}

export const SLLContext = React.createContext<IProviderProps>(
  {} as IProviderProps,
)

export function SSLProvider({ children }: { children: ReactNode }) {
  const [sslIsOpen, setSslIsOpen] = useState(false)
  const [[title, description], setTitleAndDescription] = useState(['', ''])

  function lock({ title, description }: ILockParams) {
    setSslIsOpen(true)
    setTitleAndDescription([title, description])
  }

  function unlock() {
    setSslIsOpen(false)
    setTitleAndDescription(['', ''])
  }

  return (
    <SLLContext.Provider value={{ lock, unlock }}>
      {children}
      <ScreenLockerLoad
        visible={sslIsOpen}
        title={title}
        description={description}
      />
    </SLLContext.Provider>
  )
}
