import { Modal } from '@components/Modal'
import { YStack, Dialog, useMedia, Text, XStack } from 'tamagui'
import { Dimensions } from 'react-native'
import { ScrollBar } from '@components/ScrollBar'
import { Button } from '@components/Button'
import { SliderControl } from '@components/SliderControl'
import { HeroIcon } from '@components/HeroIcon'

interface IExtraContentModalProps {
  open: boolean
  onClose: () => void
  onChangeContent: (i: number) => void
  children: React.ReactNode
  title: string
  mediaType: string
  disabledBtns?: {
    isFirstContent: boolean
    isLastContent: boolean
  }
}

export function ExtraContentModal({
  open,
  children,
  onClose,
  title,
  onChangeContent,
  disabledBtns,
  mediaType,
}: IExtraContentModalProps) {
  const media = useMedia()
  const windowHeight = Dimensions.get('window').height
  const needScrollbar = mediaType === 'Text'
  const calculateMaxHeight = () => {
    if (media.gtLg) return windowHeight * 0.7
    if (media.gtMd) return 410
    if (media.gtSm) return windowHeight * 0.8
    return windowHeight
  }

  const modalMaxWidth = () => {
    if (media.gtMd) return 1064
    if (media.gtSm) return 672
    return 631
  }

  const calculateContainerPadding = () => {
    if (media.gtLg) return '$size.spacing-lg'
    if (media.gtSm) return '$size.spacing-md'
    return '$size.spacing-xxs'
  }

  const titleMarginBottom = media.gtSm ? 24 : 100
  const sliderControlSize = media.gtSm ? 48 : 40
  const calculatePaddingBottom = media.gtSm ? 0 : 100

  return (
    <Modal
      onClose={onClose}
      open={open}
      snapPointsMode="fit"
      maxWidth={modalMaxWidth()}
    >
      {media.sm && (
        <Button
          icon
          position="absolute"
          top={5}
          right={5}
          onPress={onClose}
          outlineWidth={0}
          pressStyle={{ borderWidth: 0 }}
          focusStyle={{ borderWidth: 0 }}
        >
          <HeroIcon icon={['fal', 'xmark']} color="$neutral-800" size={16} />
        </Button>
      )}
      <YStack
        padding={calculateContainerPadding()}
        marginTop="$size.spacing-md"
      >
        <XStack
          flexDirection="row"
          justifyContent="space-between"
          mb={20}
          alignItems="center"
        >
          <Dialog.Title marginBottom={titleMarginBottom} mb={0}>
            <Text
              ff="$mulishSemiBold"
              color="$neutral-800"
              fontSize="$font-size-xs"
            >
              {title}
            </Text>
          </Dialog.Title>
          <XStack flexDirection="row">
            <SliderControl
              action={() => onChangeContent(-1)}
              chevronDirection="left"
              disabled={disabledBtns?.isFirstContent}
              size={sliderControlSize}
            />
            <SliderControl
              action={() => onChangeContent(1)}
              chevronDirection="right"
              disabled={disabledBtns?.isLastContent}
              size={sliderControlSize}
            />
          </XStack>
        </XStack>
        {needScrollbar ? (
          <ScrollBar maxHeight={calculateMaxHeight()}>
            <YStack paddingBottom={calculatePaddingBottom}>{children}</YStack>
          </ScrollBar>
        ) : (
          <YStack paddingBottom={calculatePaddingBottom}>{children}</YStack>
        )}
      </YStack>
    </Modal>
  )
}
