import { ReactNode, createContext, useEffect, useState } from 'react'
import { IExtraContents } from '@dtos/Course'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useGetExtraContents } from '@services/api/graphql/extraContents'
import { useLocalSearchParams } from 'expo-router'

interface IExtraContentsProviderProps {
  extraContents?: IExtraContents
  isLoading: boolean
  error?: Error
  onLoadMore?: (offset: number) => void
}

export const ExtraContentsContext = createContext<IExtraContentsProviderProps>(
  {} as IExtraContentsProviderProps,
)

export function ExtraContentsProvider({ children }: { children: ReactNode }) {
  const [extraContentsState, setExtraContentsState] = useState<
    IExtraContents | undefined
  >()
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { courseId } = useLocalSearchParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const { getExtraContents, error } = useGetExtraContents({
    id: `${courseId}`,
    offset: 0,
    limit: 10,
  })

  useEffect(() => {
    if (extraContentsState) return

    const fetchData = async () => {
      try {
        const { data } = await getExtraContents({
          variables: {
            id: courseId,
            offset: 0,
            limit: 10,
          },
        })
        setExtraContentsState(data?.course.extraContents)
        setFirstLoad(false)
      } catch (error) {
        toast.show(t('Conteúdo adicional'), {
          message: t('Erro ao carregar conteúdos adicionais'),
          type: 'error',
        })
      }
    }

    fetchData()
  }, [extraContentsState, getExtraContents, courseId, t, toast])

  if (error && error.message !== 'Empty page!') {
    toast.show(t('apolloErrors.lessonExtraContents.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (firstLoad || !extraContentsState) {
    return (
      <ExtraContentsContext.Provider value={{ isLoading: true, error }}>
        {children}
      </ExtraContentsContext.Provider>
    )
  }

  return (
    <ExtraContentsContext.Provider
      value={{
        onLoadMore: async (offset) => {
          try {
            const { data } = await getExtraContents({
              variables: {
                id: courseId,
                offset,
                limit: 10,
              },
            })
            if (data.course.extraContents?.nodes.length) {
              setExtraContentsState((prev) => {
                if (!prev) return
                return {
                  ...prev,
                  nodes: [...prev.nodes, ...data.course.extraContents.nodes],
                }
              })
            }
          } catch (error) {
            toast.show(t('Conteúdo adicional'), {
              message: t('Erro ao carregar conteúdos adicionais'),
              type: 'error',
            })
          }
        },
        extraContents: { ...extraContentsState },
        isLoading: false,
        error,
      }}
    >
      {children}
    </ExtraContentsContext.Provider>
  )
}
