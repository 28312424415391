import { useCallback } from 'react'
import { setStringAsync } from 'expo-clipboard'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'

export function useClipboard() {
  const toast = useToastController()
  const { t } = useTranslation('clipboard')

  const copyToClipboard = useCallback(
    async (text: string) => {
      try {
        await setStringAsync(text)
        toast.show(t('success'))
      } catch (error) {
        toast.show(t('failure'), { type: 'error' })
      }
    },
    [t, toast],
  )

  return { copyToClipboard }
}
