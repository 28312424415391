import { Progress } from '@components/Progress'
import { MediaType } from '@dtos/Course'
import { Text, View } from 'tamagui'

interface IExtendedCardContentProps {
  title: string
  progress: number
  productName: string
  productType: string
  contentType: MediaType
}

export function ExtendedCardContent({
  title,
  progress,
  productName,
  productType,
  contentType,
}: IExtendedCardContentProps) {
  const displayProgress = contentType === 'Video' || contentType === 'Audio'
  return (
    <View
      backgroundColor="$neutral-0"
      flex={1}
      padding="$size.spacing-md"
      justifyContent="space-between"
    >
      <View gap="$size.spacing-xxxs">
        <Text
          ff="$mulishSemiBold"
          color="$neutral-800"
          fontSize="$font-size-xs"
          fontWeight="600"
          numberOfLines={2}
        >
          {title}
        </Text>
        <Text
          ff="$mulishSemiBold"
          color="$neutral-500"
          fontSize="$font-size-xs"
          display="flex"
          gap={8}
        >
          {productName}
          <Text
            ff="$mulishSemiBold"
            fontSize="$font-size-xxxs"
            color="$neutral-500"
          >
            •
          </Text>
          {productType}
        </Text>
      </View>
      <View alignItems="flex-end" gap="$size.spacing-xxxs">
        {displayProgress && (
          <Progress value={progress} height={8} foregroundColor="$info-300" />
        )}
      </View>
    </View>
  )
}
