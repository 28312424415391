import { HeroIcon } from '@components/HeroIcon'
import { ICartItem } from '@dtos/Order'
import { useTranslation } from 'react-i18next'
import { Circle, H3, Text, XStack, YStack } from 'tamagui'

interface IOrderBumpProps {
  products?: ICartItem[]
}
export function OrderBump({ products }: IOrderBumpProps) {
  const { t } = useTranslation('OrderDetails')

  if (!products || !products.length) return null

  return (
    <YStack gap="$size.spacing-xxxs">
      <XStack
        bg="$neutral-100"
        paddingVertical="$size.spacing-xxxs"
        paddingHorizontal="$size.spacing-xs"
        alignItems="center"
        gap="$size.spacing-xxxs"
        alignSelf="flex-start"
      >
        <HeroIcon
          icon={['fal', 'cart-shopping']}
          color="$neutral-800"
          size={24}
        />
        <H3 ff="$mulishBold" color="$neutral-800" fontSize="$font-size-xxs">
          {t('orderBumpTitle')}
        </H3>
      </XStack>
      {products.map(({ product: { name } }) => (
        <XStack
          key={name}
          alignItems="center"
          gap="$size.spacing-xxxs"
          pl="$size.spacing-xxs"
        >
          <Circle size={5} bg="$neutral-800" />
          <Text
            ff="$mulishRegular"
            color="$neutral-800"
            fontSize="$font-size-xxs"
          >
            {name}
          </Text>
        </XStack>
      ))}
    </YStack>
  )
}
