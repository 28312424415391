import { useMedia } from 'tamagui'
import { Platform, Dimensions } from 'react-native'
import { useEffect, useState } from 'react'

/**
 * Hook para detectar se o dispositivo é móvel, baseado na plataforma e no tamanho da tela.
 * @param options Opções de configuração
 * @param options.forceValue Força um valor específico (útil para testes)
 * @param options.breakpoint Tamanho da tela em pixels para considerar como móvel (padrão: 768)
 * @returns {boolean} true se o dispositivo for móvel
 */
export function useIsMobile(options?: {
  forceValue?: boolean
  breakpoint?: number
}): boolean {
  const { forceValue, breakpoint = 768 } = options || {}
  const media = useMedia()
  const isMobileDevice = Platform.OS === 'ios' || Platform.OS === 'android'

  // Estado para armazenar a largura da tela
  const [width, setWidth] = useState(() => Dimensions.get('window').width)

  // Atualiza a largura da tela quando ela muda
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWidth(window.width)
    })

    return () => subscription.remove()
  }, [])

  // Verifica se a tela é pequena o suficiente para ser considerada móvel
  const isMobileScreen = width < breakpoint

  // Se forceValue for fornecido, retorna esse valor
  if (forceValue !== undefined) {
    return forceValue
  }

  // Caso contrário, retorna true se for um dispositivo móvel ou uma tela pequena
  return isMobileDevice || isMobileScreen || !media.gtSm
}
