import { gql } from '@apollo/client'

export const GET_CONTINUE_LESSONS = gql`
  query getContinueLessons {
    user {
      id
      lastViewedContents {
        completed
        id
        enrollment {
          id
          activatedAt
          status
        }
        progressCard {
          id
          courseId
        }
        lesson {
          id
          courseContentId
          title
          releaseAt
          releaseAfter
          lockAt
          lockAfter
          coverImage {
            cdnFileUrl
          }
          media {
            id
            host
            type
            hostKey
          }
        }
        schoolProduct {
          id
          title
          courses {
            id
            kind
          }
        }
        progress
      }
    }
  }
`
