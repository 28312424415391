import { utcToZonedTime, format } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'

function determineTimeZone(dateString: string): string {
  if (dateString.length === 10) return 'UTC'
  return dateString.endsWith('Z') ? 'UTC' : dateString.slice(-6)
}

function formatDate(dateString: string, formatString: string): string {
  if (!dateString) return '-'
  const timeZone = determineTimeZone(dateString)
  const zonedDate = utcToZonedTime(new Date(dateString), timeZone)
  return format(zonedDate, formatString, { locale: ptBR })
}

export function formatDateLong(dateString: string): string {
  return formatDate(dateString, "EEEE, dd 'de' MMMM 'de' yyyy")
}

export function formatDateMedium(dateString: string): string {
  return formatDate(dateString, "dd 'de' MMMM 'de' yyyy")
}

export function formatDateShort(dateString: string): string {
  return formatDate(dateString, 'dd/MM/yyyy')
}

export function formatTime(dateString: string): string {
  return formatDate(dateString, 'HH:mm:ss')
}

export function formatDateComplete(dateString: string): string {
  return formatDate(dateString, "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm:ss")
}

export function isPastDate(dateString: string): boolean {
  const timeZone = determineTimeZone(dateString)
  const zonedDate = utcToZonedTime(new Date(dateString), timeZone)
  return zonedDate < new Date()
}

export function differenceInDays(date1: string, date2: string): number {
  const timeZone = determineTimeZone(date2)
  const zonedDate1 = utcToZonedTime(new Date(date1), timeZone)
  const zonedDate2 = utcToZonedTime(new Date(date2), timeZone)

  return Math.floor(
    (zonedDate2.getTime() - zonedDate1.getTime()) / (1000 * 60 * 60 * 24),
  )
}

export function addDays(dateString: string, days: number): string {
  const timeZone = determineTimeZone(dateString)
  const zonedDate = utcToZonedTime(new Date(dateString), timeZone)
  zonedDate.setDate(zonedDate.getDate() + days)
  return zonedDate.toISOString()
}
