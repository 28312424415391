import { ReactNode, useMemo } from 'react'
import { TamaguiProvider, Token, getToken } from 'tamagui'
import {
  ThemeProvider as NavigationThemeProvider,
  DefaultTheme,
} from '@react-navigation/native'

import config from '../theme'
import { useHasFeature } from '@hooks/useThemeParams'
import { THEME_DARK_MODE, THEME_LIGHT_MODE } from '@constants/features'

export function ThemeProvider({ children }: { children: ReactNode }) {
  const navigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: getToken('$background-100' as Token),
    },
  }

  const isLightMode = useHasFeature(THEME_LIGHT_MODE)
  const isDarkMode = useHasFeature(THEME_DARK_MODE)

  const theme = useMemo(() => {
    if (isLightMode) return 'light'
    if (isDarkMode) return 'dark'
    return 'dark'
  }, [isDarkMode, isLightMode])

  return (
    <TamaguiProvider config={config} defaultTheme={theme}>
      <NavigationThemeProvider value={navigationTheme}>
        {children}
      </NavigationThemeProvider>
    </TamaguiProvider>
  )
}
