import { gql } from '@apollo/client'

export const GET_MODULE = gql`
  query getCourseModules($id: ID!, $moduleId: ID, $offset: Int!, $limit: Int!) {
    course(id: $id) {
      id
      courseModules(
        id: $moduleId
        main: true
        pagination: { offset: $offset, limit: $limit }
      ) {
        nodes {
          id
          name
          available
          courseModules {
            id
            name
            available
            courseContentsCount
            courseContents(pagination: { offset: 0, limit: 10 }) {
              nodes {
                id
                available
                lesson {
                  id
                  title
                  releaseAt
                  releaseAfter
                  lockAt
                  lockAfter
                  createdAt
                  activity {
                    examQuestionsCount
                  }
                  media {
                    id
                    type
                    duration
                    hostKey
                    host
                  }
                  coverImage {
                    cdnFileUrl
                  }
                }
              }
            }
          }
          courseContentsCount
          courseContents(pagination: { offset: 0, limit: 10 }) {
            nodes {
              id
              available
              lesson {
                id
                title
                releaseAt
                releaseAfter
                lockAt
                lockAfter
                createdAt
                activity {
                  examQuestionsCount
                }
                media {
                  id
                  type
                  duration
                  hostKey
                  host
                }
                coverImage {
                  cdnFileUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
