import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import {
  Adapt,
  Dialog,
  Sheet,
  SheetProps,
  Unspaced,
  XStack,
  useMedia,
} from 'tamagui'
import { ReactNode } from 'react'

interface IModalProps extends SheetProps {
  onClose?: () => void
  children: ReactNode
  maxWidth?: number
  shouldAdapt?: boolean
  open: boolean
  backdropOpacity?: number
  snapPoints?: number[]
  snapPointsMode?: 'percent' | 'fit' | 'constant' | 'mixed'
}
export interface IModalHandles {
  showModal: (show: boolean) => void
}

export function Modal({
  open,
  snapPoints,
  snapPointsMode = 'percent',
  shouldAdapt = true,
  maxWidth,
  onClose,
  children,
  backdropOpacity = 0.1,
  ...rest
}: IModalProps) {
  function handleClose() {
    onClose && onClose()
  }
  const media = useMedia()
  const closeButtonTop = media.gtSm ? 25 : 0

  return (
    <Dialog modal open={open}>
      <Adapt when="sm" platform="touch">
        <Sheet
          open={open}
          animation="medium"
          zIndex={200000}
          modal
          onOpenChange={onClose}
          dismissOnOverlayPress
          dismissOnSnapToBottom
          snapPoints={snapPoints}
          snapPointsMode={snapPointsMode}
          moveOnKeyboardChange
          {...rest}
        >
          <Sheet.Overlay
            opacity={backdropOpacity}
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
            backgroundColor="rgba(0, 0, 0, 0.64)"
          />
          <Sheet.Frame bg="$neutral-0">
            {shouldAdapt && (
              <XStack
                width={80}
                height={8}
                bg="$neutral-300"
                br="$border-radius-pill"
                alignSelf="center"
                marginTop="$size.spacing-xs"
              />
            )}
            <Adapt.Contents />
          </Sheet.Frame>
        </Sheet>
      </Adapt>

      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="quick"
          opacity={backdropOpacity}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          backgroundColor="rgba(0, 0, 0, 0.64)"
          onPress={handleClose}
        />

        <Dialog.Content
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          width="100%"
          maxWidth={maxWidth || 600}
          unstyled
          backgroundColor="$neutral-0"
          zIndex={9999999}
          br="$spacing-nano"
        >
          {children}

          <Unspaced>
            <Dialog.Close asChild>
              <Button
                position="absolute"
                top={closeButtonTop}
                right={10}
                onPress={handleClose}
                icon
                outlineWidth={0}
                pressStyle={{ borderWidth: 0 }}
                focusStyle={{ borderWidth: 2, borderColor: 'transparent' }}
              >
                <HeroIcon
                  icon={['fas', 'xmark']}
                  size={20}
                  color="$neutral-800"
                />
              </Button>
            </Dialog.Close>
          </Unspaced>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
