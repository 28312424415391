import { CourseDetails } from '@components/enrollments/courses/CourseDetails'
import { CourseModules } from '@components/enrollments/courses/CourseModules'
import { useContext, useEffect, useMemo } from 'react'
import { CourseGhostLoading } from './CourseGhostLoading'
import { ThemeParamsContext } from '@contexts/ThemeParamsContext'
import { useCourse } from '@hooks/useCourse'
import { useModules } from '@hooks/useModules'
import { useEnrollment } from '@hooks/useEnrollment'
import { EmptyStateComponent } from '@components/EmptyStateComponent'
import { useThemeParams } from '@hooks/useThemeParams'
import { useRudderstack } from '@hooks/useAnalytics'
import { isContentAvailable } from '@utils/contentAvailable'
import { YStack, View } from 'tamagui'
import { useTranslation } from 'react-i18next'

export function CourseContent() {
  const { course, isLoading: isCourseLoading } = useCourse()
  const { modules, isLoading: isModulesLoading } = useModules()
  const { enrollment, enrollmentIsLoading } = useEnrollment()
  const { themeParamsIsLoading } = useContext(ThemeParamsContext)
  const { t } = useTranslation('CourseDetails')
  const { track } = useRudderstack()
  const { data: themeParams } = useThemeParams()
  const isSomethingLoading =
    isCourseLoading || isModulesLoading || enrollmentIsLoading

  useEffect(() => {
    if (!course || !themeParams?.school?.id) return
    track('Members Page Course Opened', {
      school_id: themeParams.school.id,
      product_name: course?.name,
      product_type: course?.kind,
    })
  }, [track, themeParams?.school?.id, course])

  const courseHasContent = useMemo(() => {
    return modules?.nodes?.some((module) => {
      if (module.courseContents?.nodes.length > 0) return true
      if (module.courseModules.length > 0) {
        return module.courseModules.some(
          (submodule) => submodule.courseContents?.nodes.length > 0,
        )
      }
      return false
    })
  }, [modules])

  const filteredLastLessonsViewedContent = useMemo(() => {
    if (
      enrollment?.lastLessonsViewedContent &&
      !Object.keys(enrollment.lastLessonsViewedContent).length
    )
      return
    return enrollment?.lastLessonsViewedContent?.nodes.filter((lesson) => {
      const dataAvailable = {
        releaseAt: lesson.releaseAt,
        releaseAfter: lesson.releaseAfter,
        lockAt: lesson.lockAt,
        lockAfter: lesson.lockAfter,
        createdAt: enrollment.activatedAt,
      }
      return isContentAvailable(dataAvailable)
    })
  }, [enrollment])

  if (
    isSomethingLoading ||
    !course ||
    !enrollment ||
    themeParamsIsLoading ||
    !modules
  ) {
    return <CourseGhostLoading />
  }

  return (
    <>
      {courseHasContent ? (
        <YStack marginTop={100}>
          <CourseDetails
            enrollment={{
              ...enrollment,
              lastLessonsViewedContent: filteredLastLessonsViewedContent,
            }}
          />
          <CourseModules />
        </YStack>
      ) : (
        <View flex={1} marginBottom={50}>
          <EmptyStateComponent
            title={t('emptyState.title')}
            description={t('emptyState.description')}
          />
        </View>
      )}
    </>
  )
}
