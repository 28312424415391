import { useLazyQuery } from '@apollo/client'
import { IGraphqlQueryProps } from '@dtos/Pagination'
import { GET_USER_ENROLLMENTS } from '@services/api/graphql/queries/getUserEnrollments'

interface IUseGetUserEnrollmentsProps extends IGraphqlQueryProps {}

export const useGetUserEnrollments = ({
  offset,
  limit,
}: IUseGetUserEnrollmentsProps) => {
  const [getUserEnrollments, { data, loading, error, variables, called }] =
    useLazyQuery(GET_USER_ENROLLMENTS, {
      variables: { offset, limit },
    })

  return { getUserEnrollments, data, loading, error, variables, called }
}
