import { PaymentStatusType } from '@dtos/Order'

export const PAYMENT_INITIATED_STATUS: PaymentStatusType = 'initiated'
export const PAYMENT_ERROR_STATUS: PaymentStatusType = 'error'
export const PAYMENT_PAID_STATUS: PaymentStatusType = 'paid'
export const PAYMENT_REFUSED_STATUS: PaymentStatusType = 'refused'
export const PAYMENT_CANCELLED_STATUS: PaymentStatusType = 'cancelled'
export const PAYMENT_WAITING_PAYMENT_STATUS: PaymentStatusType =
  'waiting_payment'
export const PAYMENT_SCHEDULED_STATUS: PaymentStatusType = 'scheduled'
export const PAYMENT_OVERDUE_STATUS: PaymentStatusType = 'overdue'
export const PAYMENT_REFUND_ANALYZING_STATUS: PaymentStatusType =
  'refund_analyzing'
export const PAYMENT_REFUNDED_STATUS: PaymentStatusType = 'refunded'
export const PAYMENT_CHARGEBACK_STATUS: PaymentStatusType = 'chargeback'
