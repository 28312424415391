import { HeroIcon } from '@components/HeroIcon'
import {
  GetProps,
  Paragraph,
  Input as TamaguiInput,
  Text,
  XStack,
  YStack,
  styled,
} from 'tamagui'
import { Masks, formatWithMask } from 'react-native-mask-input'
import { StylableComponent } from '@tamagui/core'

type InputStylesType<T extends StylableComponent> = GetProps<T>

const inputStyles: InputStylesType<typeof TamaguiInput> = {
  paddingHorizontal: '$size.spacing-xs',
  paddingVertical: '$size.spacing-xxxs',
  borderRadius: '$spacing-nano',
  borderWidth: 1,
  borderColor: '$neutral-300',
  backgroundColor: '$neutral-0',
  ff: '$mulishRegular',
  fontSize: '$font-size-xxs',
  color: '$neutral-800',
  br: '$spacing-nano',
}

const BaseInput = styled(TamaguiInput, {
  name: 'Input',
  unstyled: true,
  ...inputStyles,
  placeholderTextColor: '$neutral-500',

  variants: {
    error: {
      true: {
        borderColor: '$danger-300',
      },
    },
  },
})

const BaseFakeInput = styled(Paragraph, {
  name: 'FakeInput',
  unstyled: true,
  ...(inputStyles as InputStylesType<typeof Paragraph>),
  height: 35,

  focusStyle: {
    borderColor: '$primary-300',
  },
  variants: {
    error: {
      true: {
        borderColor: '$danger-300',
      },
    },
  },
})

type ContainerProps = {
  children: React.ReactNode
  errorMessage?: string | false
}

function Container({ children, errorMessage }: ContainerProps) {
  return (
    <YStack gap="$size.spacing-nano">
      {children}
      {!!errorMessage && (
        <XStack gap="$size.spacing-nano" alignItems="center">
          <HeroIcon
            icon={['fal', 'exclamation-circle']}
            size={16}
            color="$danger-300"
          />
          <Text
            color="$danger-300"
            ff="$mulishRegular"
            fontSize="$font-size-xxxs"
          >
            {errorMessage}
          </Text>
        </XStack>
      )}
    </YStack>
  )
}

const DATE_MMYY = (text = '') => {
  const cleanText = text.replace(/\D+/g, '')

  const firstDigit = cleanText.charAt(0)
  let secondDigitMask = /\d/

  if (firstDigit === '0') {
    secondDigitMask = /[1-9]/
  } else if (firstDigit === '1') {
    secondDigitMask = /[0-2]/
  }

  return [/[0-1]/, secondDigitMask, '/', /\d/, /\d/]
}
const ALL_MASKS = {
  ...Masks,
  DATE_MMYY,
}

export type InputProps = React.ComponentProps<typeof BaseInput> & {
  errorMessage?: string | false
  mask?: keyof typeof Masks | 'DATE_MMYY'
  name?: string
  value?: string
  onChangeText?: (text: string) => void
}

export function Input({
  errorMessage,
  value,
  onChangeText,
  mask,
  ...rest
}: InputProps) {
  const { masked } = formatWithMask({
    text: value,
    mask: mask ? ALL_MASKS[mask] : undefined,
  })

  return (
    <Container errorMessage={errorMessage}>
      <BaseInput
        onChangeText={onChangeText}
        value={mask ? masked : value}
        error={!!errorMessage}
        {...rest}
      />
    </Container>
  )
}

export function FakeInput({
  errorMessage,
  children,
  cse,
}: InputProps & { cse?: string }) {
  return (
    <Container errorMessage={errorMessage}>
      <BaseFakeInput data-cse={cse} error={!!errorMessage}>
        {children}
      </BaseFakeInput>
    </Container>
  )
}
