import { ReactNode, createContext, useEffect, useState } from 'react'
import { ILessons } from '@dtos/Course'
import { useToastController } from '@tamagui/toast'
import { useTranslation } from 'react-i18next'
import { useGetLessonExtraContents } from '@services/api/graphql/lessonExtraContents'
import { useLocalSearchParams } from 'expo-router'

interface ILessonExtraContentsProviderProps {
  lessonsExtraContents?: ILessons
  isLoading: boolean
  error?: Error
  onLoadMore?: (offset: number) => Promise<{ lessons: ILessons | null }>
}

export const LessonExtraContentsContext =
  createContext<ILessonExtraContentsProviderProps>(
    {} as ILessonExtraContentsProviderProps,
  )

export function LessonExtraContentsProvider({
  children,
}: {
  children: ReactNode
}) {
  const [lessonExtraContentsState, setLessonExtraContentsState] = useState<
    ILessons | undefined | null
  >()
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { courseId } = useLocalSearchParams()
  const { getMoreLessonExtraContents, loading, error } =
    useGetLessonExtraContents({
      id: `${courseId}`,
      offset: 0,
      limit: 5,
    })

  useEffect(() => {
    if (lessonExtraContentsState) return
    const fetchData = async () => {
      const { data } = await getMoreLessonExtraContents({
        variables: {
          id: courseId,
          offset: 0,
          limit: 5,
        },
      })
      if (data?.course.lessons) {
        setLessonExtraContentsState(data?.course.lessons)
      }
    }
    fetchData()
  }, [lessonExtraContentsState, getMoreLessonExtraContents, courseId])

  if (error && error.message !== 'Empty page!') {
    toast.show(t('apolloErrors.lessonExtraContents.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (loading || !lessonExtraContentsState) {
    return (
      <LessonExtraContentsContext.Provider
        value={{ isLoading: loading, error }}
      >
        {children}
      </LessonExtraContentsContext.Provider>
    )
  }

  return (
    <LessonExtraContentsContext.Provider
      value={{
        lessonsExtraContents: { ...lessonExtraContentsState },
        isLoading: loading,
        error,
        onLoadMore: async (offset) => {
          try {
            const { data } = await getMoreLessonExtraContents({
              variables: {
                id: courseId,
                offset,
                limit: 5,
              },
            })
            return data?.course
          } catch (error) {
            toast.show(t('apolloErrors.lessonExtraContents.title'), {
              message: t('genericRequestError.message'),
              type: 'error',
            })
          }
        },
      }}
    >
      {children}
    </LessonExtraContentsContext.Provider>
  )
}
