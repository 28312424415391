/* eslint-disable */
// TODO: Descomentar quando o backend estiver em produção
import { ApolloError } from '@apollo/client'
import {
  IFooterConfig,
  IGeneralConfig,
  IMembersConfig,
  ISectionsConfig,
  ICustomBanners,
  ICustomProductList,
  ICustomBannersKind,
} from '@dtos/MembersConfig'
import { useGetMembersConfig } from '@services/api/graphql/membersConfig'
import { useToastController } from '@tamagui/toast'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useMembersConfig = () => {
  const [membersConfigData, setMembersConfigData] = useState<IMembersConfig | null>(null)
  const toast = useToastController()
  const { t } = useTranslation('toast')
  const { getMembersConfig, data, error, loading, called } =
    useGetMembersConfig({
      version: 'production',
    })

  useEffect(() => {
    try {
      const asyncGetMembersConfig = async () => {
        const { data } = await getMembersConfig()
        setMembersConfigData(data?.membersConfig)
      }
      if (!membersConfigData) {
        asyncGetMembersConfig()
      }
    } catch (error) {
      throw error
    }
  }, [getMembersConfig])

  if (error && error.message !== 'Object of type MembersConfig not found') {
    toast.show(t('apolloErrors.membersConfig.title'), {
      message: t('genericRequestError.message'),
      type: 'error',
    })
  }

  if (!data) return { data: null, error, loading, called }

  return {
    getMembersConfig,
    data: membersConfigData,
    error,
    loading,
    called,
  } as {
    getMembersConfig: () => void
    data: IMembersConfig
    error: ApolloError
    loading: boolean
    called: boolean
  }
}



export const useGetFooterConfig = () => {
  const { data } = useMembersConfig()

  return { data: data?.footer } as { data: IFooterConfig | null }
}

export const useGetGeneralConfig = () => {
  const { data } = useMembersConfig()

  return { data: data?.general } as { data: IGeneralConfig | null }
}

export const useGetMyProductsConfig = () => {
  const { data } = useMembersConfig()

  const findMyProductsSection = data?.sections.find(
    (section) => section.kind === 'school_products',
  )
  return {
    data: findMyProductsSection,
  } as { data: ISectionsConfig | null }
}

export const useGetCustomBanners = () => {
  const { data } = useMembersConfig()

  const sortedCustomBanners = useMemo(() => {
    if (!data?.customBanners) return null

    const orderPriority: { [key in ICustomBannersKind]: number } = {
      duo: 1,
      trio: 2,
      single: 3,
    }

    return [...data.customBanners].sort(
      (a, b) => orderPriority[a.kind] - orderPriority[b.kind]
    )
  }, [data?.customBanners])

  return { data: sortedCustomBanners } as { data: ICustomBanners[] | null }
}

export const useGetCustomProductLists = () => {
  const { data } = useMembersConfig()

  return { data: data?.customProductLists } as { data: ICustomProductList[] | null }
}

