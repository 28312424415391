import { FormattedOrderType, useOrderQuery } from '@queries/order'
import { useToastController } from '@tamagui/toast'
import { GhostLoading } from '@components/order/GhostLoading'
import React, { createContext, useContext, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query'

type OrderDetailsContextType = FormattedOrderType & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<FormattedOrderType, unknown>>
}

const OrderDetailsContext = createContext<OrderDetailsContextType>(
  {} as OrderDetailsContextType,
)

interface IOrderDetailsProviderProps {
  children: ReactNode
  orderId: string
  isPublic?: boolean
}

export const OrderDetailsProvider: React.FC<IOrderDetailsProviderProps> = ({
  children,
  orderId,
  isPublic,
}) => {
  const { data: order, isLoading, refetch } = useOrderQuery(orderId, isPublic)
  const toast = useToastController()
  const { t } = useTranslation('toast')

  if (isLoading) return <GhostLoading />

  if (!order) {
    toast.show(t('apolloErrors.orderDetails.title'), {
      message: t('apolloErrors.orderDetails.message'),
      type: 'error',
    })

    return <GhostLoading />
  }

  return (
    <OrderDetailsContext.Provider value={{ ...order, refetch }}>
      {children}
    </OrderDetailsContext.Provider>
  )
}

export function useOrderDetails() {
  const context = useContext(OrderDetailsContext)
  if (!context) {
    throw new Error(
      'useOrderDetails must be used within an OrderDetailsProvider',
    )
  }
  return context
}
