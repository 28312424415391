import { Stack, Text, XStack, YStack } from 'tamagui'
import { HeroIcon } from '@components/HeroIcon'
import { Button } from '../Button'
import { useCallback, useState } from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import { StyleSheet } from 'react-native'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { router } from 'expo-router'

export function HeaderMobile({ openModal }: { openModal: () => void }) {
  const handleRouter = useCallback((path: string) => {
    router.push(path)
  }, [])

  return (
    <Stack paddingTop={15}>
      <LinearGradient
        colors={['transparent', 'rgba(0,0,0,0.76)']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: 115,
        }}
      />
      <XStack
        marginHorizontal={16}
        bg="$neutral-0"
        borderRadius={200}
        paddingHorizontal={32}
        justifyContent="space-around"
        height={65}
      >
        <MobileButton
          label="Inicio"
          action={() => handleRouter('/')}
          icon="house"
        />
        <MobileButton
          label="Mensagens"
          action={() => (window.location.href = '/channels')}
          icon="envelope"
        />
        <MobileButton label="Perfil" action={() => openModal()} icon="user" />
      </XStack>
    </Stack>
  )
}

interface IMobileButtonProps {
  icon: IconName
  label: string
  action: () => void
}

export function MobileButton({ icon, label, action }: IMobileButtonProps) {
  const [isPressed, setIsPressed] = useState(false)
  return (
    <Button
      outline
      size="small"
      borderColor="transparent"
      outlineWidth={0}
      focusStyle={{ ...styles.unstyled }}
      pressStyle={{ ...styles.unstyled }}
      onPress={() => {
        setIsPressed(true)
        action()
      }}
      onBlur={() => setIsPressed(false)}
    >
      <YStack
        padding={8}
        alignItems="center"
        justifyContent="flex-end"
        borderTopWidth={isPressed ? 2 : 0}
        borderTopColor="$primary-300"
      >
        <HeroIcon
          icon={['fal', icon]}
          color={isPressed ? '$primary-300' : '$neutral-800'}
          size={24}
        />
        <Text
          color={isPressed ? '$primary-300' : '$neutral-600'}
          fontSize="$font-size-xxxs"
          ff="$mulishRegular"
        >
          {label}
        </Text>
      </YStack>
    </Button>
  )
}

const styles = StyleSheet.create({
  unstyled: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
  },
})
