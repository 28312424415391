import { useLazyQuery } from '@apollo/client'
import { IGraphqlQueryProps } from '@dtos/Pagination'
import { GET_MEMBERS_CONFIG } from '@services/api/graphql/queries/getMembersConfig'

interface IUseMembersConfigProps extends IGraphqlQueryProps {
  version: string
}

export const useGetMembersConfig = ({ version }: IUseMembersConfigProps) => {
  const [getMembersConfig, { data, loading, error, variables, called }] =
    useLazyQuery(GET_MEMBERS_CONFIG, {
      variables: { version },
    })

  return { getMembersConfig, data, loading, error, variables, called }
}
