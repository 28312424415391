import { useRef } from 'react'
import { Animated } from 'react-native'

export const useOpacityAnimation = (animationDuration = 700) => {
  const animatedOpacity = useRef(new Animated.Value(1)).current
  const interpolatedOpacity = animatedOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  })

  const startAnimation = (isHoveringSection: boolean) => {
    Animated.timing(animatedOpacity, {
      toValue: isHoveringSection ? 0 : 1,
      duration: animationDuration,
      delay: 100,
      useNativeDriver: false,
    }).start()
  }

  return { animatedOpacity, startAnimation, interpolatedOpacity }
}
