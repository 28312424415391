import { useContext, useMemo } from 'react'
import { EXCLUSIVE_DOMAINS } from '@constants/school'
import extractDomain from 'extract-domain'
import { isWeb } from 'tamagui'
import { FEATURES } from '@constants/features'
import { ThemeParamsContext } from '@contexts/ThemeParamsContext'

const hostname = isWeb ? window.location.hostname : null

export const useThemeParams = () => {
  const { params, themeParamsIsLoading, error } = useContext(ThemeParamsContext)

  return { data: params, loading: themeParamsIsLoading, error }
}

export const useDomain = () => {
  const { data: params } = useThemeParams()

  const isCustomDomain = useMemo(() => {
    if (!isWeb) return false

    const currentURl = hostname ? (extractDomain(hostname) as string) : ''
    return !EXCLUSIVE_DOMAINS.includes(currentURl)
  }, [])

  const subdomain = useMemo(
    () =>
      params
        ? `${params.school?.subdomain}.${process.env.EXPO_PUBLIC_DOMAIN}`
        : null,
    [params],
  )

  const subdomainURL = useMemo(
    () => (params ? `https://${subdomain}` : null),
    [params, subdomain],
  )

  return { isCustomDomain, subdomain, subdomainURL }
}

export const useFeatures = () => {
  const { data: params, loading, error } = useThemeParams()
  if (loading || error || !params) return []
  return params.school?.featuresAvailable
}

export type Feature = (typeof FEATURES)[number]

export const useHasFeature = (feature: Feature) => {
  const features = useFeatures()

  return features?.includes(feature)
}
