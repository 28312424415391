{
  "OrderDetails": {
    "title": "Detalhes do pedido",
    "orderId": "NÚMERO",
    "createdAt": "Data do pedido",
    "amount": "Total",
    "amountPaid": "TOtal pago",
    "orderBumpTitle": "Produtos adquiridos junto:",
    "loadPrintTitle": "Por favor, aguarde",
    "loadPrintDescription": "Isso pode levar alguns segundos.",
    "subscription": {
      "title": "Assinatura",
      "amount": "Valor da assinatura",
      "bill": "Próxima cobrança",
      "cancel": "Cancelar assinatura",
      "cancelModal": {
        "title": "Cancelamento de assinatura",
        "description": "Você tem certeza que deseja cancelar a assinatura do produto {{courseName}}",
        "cta": "Cancelar assinatura",
        "stay": "Manter assinatura"
      },
      "toast": {
        "title": "Lamentamos, mas algo deu errado",
        "description": "Não foi possível cancelar sua assinatura neste momento, por favor, aguarde alguns minutos e tente novamente"
      },
      "unubscribeSuccess": {
        "title": "Cancelamento realizado",
        "description": "Você cancelou a assinatura do produto {{courseName}}. Seu acesso será mantido até {{expirationDate}}"
      },
      "changeCreditCard": {
        "title": "Dados do cartão",
        "ccNumber": "Número do cartão",
        "ccHolder": "Nome impresso no cartão",
        "ccHolderPlaceholder": "EX: João A. Silva",
        "ccExpiration": "Validade",
        "ccExpirationPlaceholder": "MM/AA",
        "ccCvv": "Cód. de segurança",
        "ccCvvPlaceholder_3": "EX: 777",
        "ccCvvPlaceholder_4": "EX: 7777",
        "cta": "Salvar novo cartão",
        "tooltip": "3 dígitos no verso do cartão. Amex: 4 dígitos na frente.",
        "toast": {
          "error": {
            "title": "Algo deu errado!",
            "description": "Verifique os dados informados e tente novamente"
          },
          "success": {
            "title": "Tudo certo!",
            "description": "Cartão alterado com sucesso"
          }
        },
        "validations": {
          "ccNumber": {
            "required": "O número do cartão é obrigatório",
            "invalid": "Número do cartão inválido"
          },
          "ccHolder": {
            "required": "Nome do titular é obrigatório"
          },
          "ccExpiration": {
            "required": "Data de validade é obrigatória",
            "expired": "Data de validade inválida"
          },
          "ccCvv": {
            "required": "CVV é obrigatório",
            "invalidSize": "Seu código de segurança deve ter {{size}} dígitos"
          }
        }
      }
    },
    "paymentMethod": {
      "title": "Forma de pagamento",
      "paymentRefundBtnLabel": "Solicitar reembolso",
      "disabledPaymentRefundTooltip": "Reembolso já solicitado",
      "creditCard": "Cartão de crédito",
      "bankSlip": "Boleto bancário",
      "pix": "PIX",
      "multicard": "2 cartões de crédito",
      "cta": "Alterar cartão",
      "ctaBankSlip": "Imprimir boleto",
      "ctaPixCode": "Copiar código PIX",
      "processingRefund": "Processando reembolso..."
    },
    "refundError": {
      "title": "Não foi possível solicitar o reembolso!",
      "description": "Não conseguimos processar seu pedido de reembolso no momento. Abra um chamado com o suporte da HeroSpark, e nossa equipe vai te ajudar a concluir seu reembolso o mais rápido possível",
      "openTicket": "abra um chamado",
      "tryAgain": "Tentar novamente mais tarde",
      "openTicketButton": "Abrir um chamado",
      "supportText": "para o suporte da HeroSpark."
    },
    "refundGenericError": {
      "title": "Não foi possível solicitar o reembolso neste momento!",
      "descriptionPart1": "Tente novamente mais tarde ou",
      "openTicketLink": "abra um chamado",
      "descriptionPart2": "para o suporte da HeroSpark.",
      "openTicketButton": "Tentar novamente mais tarde"
    },
    "refundBankSlip": {
      "title": "Reembolso para boleto",
      "description": "Para solicitar o reembolso de um pagamento via boleto, abra um chamado com o suporte da HeroSpark e informe os seguintes dados bancários:",
      "bankSlipData": "Nome completo:\nCPF:\nInstituição bancária:\nAgência (com dígito, se houver):\nConta (com dígito, se houver):",
      "openTicketButton": "Abrir um chamado"
    },
    "refundSuccess": {
      "title": "Seu reembolso foi solicitado com sucesso.",
      "description": "Seu reembolso será realizado, entramos em contato por e-mail para informar mais detalhes de como receber.",
      "emailContact": "contato por e-mail",
      "closeButton": "Fechar Janela",
      "toast": {
        "title": "Solicitação de reembolso enviada!",
        "message": "Após a conclusão do processo, você vai receber um comprovante via e-mail."
      }
    },
    "refundContactProducer": {
      "title": "Solicitação reembolso",
      "description": "Para solicitar o estorno da sua compra, entre em contato diretamente com o vendedor",
      "otherDetails": "Em casos de dúvidas ou o produtor não responder, entre em contato com o suporte da HeroSpark pelo e-mail",
      "closeButton": "Fechar janela"
    },
    "payments": {
      "title": "Pagamentos",
      "paymentDate": "Vencimento",
      "amount": "Valor",
      "status": "Status",
      "bankSlip": "Imprimir boleto",
      "pixCode": "Código pix"
    },
    "status": {
      "payment": {
        "initiated": "Processando",
        "error": "Erro",
        "paid": "Aprovado",
        "refused": "Recusado",
        "cancelled": "Cancelado",
        "waitingPayment": "Aguardando pagamento",
        "scheduled": "Agendado",
        "overdue": "Expirado",
        "refundAnalyzing": "Em contestação",
        "chargeback": "Chargeback",
        "refunded": "Estornado"
      },
      "subscription": {
        "initiated": "Iniciada",
        "active": "Ativa",
        "pastDue": "Atrasada",
        "cancelled": "Cancelada",
        "ended": "Finalizada",
        "inactive": "Inativa"
      }
    }
  },
  "components": {
    "AuthEmailModal": {
      "title": "Para continuar, informe seu e-mail",
      "description": "Você receberá um link de autenticação da HeroSpark e, através dele, será redirecionado para executar essa ação",
      "label": "Seu e-mail",
      "placeholder": "E-mail utilizado na compra do produto",
      "action": "Enviar",
      "toast": {
        "title": "Verifique sua caixa de entrada!",
        "message": "Caso o e-mail fornecido esteja atrelado à essa compra, você receberá um link de autenticação. Aconselhamos olhar também em sua caixa de Spam."
      }
    },
    "EmptyStateComponent": {
      "cta": "Voltar para página anterior"
    }
  },
  "toast": {
    "genericRequestError": {
      "title": "Não foi possível carregar algumas informações!",
      "message": "Recarregue a página ou tente novamente mais tarde."
    },
    "apolloErrors": {
      "course": {
        "title": "Erro ao carregar as informações do curso!"
      },
      "courseContents": {
        "title": "Erro ao carregar o conteúdo do curso!"
      },
      "courseModules": {
        "title": "Erro ao carregar o conteúdo dos módulos do curso!"
      },
      "extraContents": {
        "title": "Erro ao carregar o material complementar do curso!"
      },
      "enrollmentIdsInfo": {
        "title": "Erro ao carregar as informações da matrícula!"
      },
      "lessonExtraContents": {
        "title": "Erro ao carregar o material adicional de uma aula!"
      },
      "orderDetails": {
        "title": "Erro ao carregar os detalhes do pedido!"
      },
      "productsCustomList": {
        "title": "Erro ao carregar mais produtos!"
      },
      "productUrl": {
        "title": "Erro ao carregar o link de venda do produto!"
      },
      "user": {
        "title": "Erro ao carregar as informações do usuário!"
      },
      "membersConfig": {
        "title": "Erro ao carregar as informações da área de membros!"
      }
    }
  },
  "Home": {
    "DefaultSectionTitle": {
      "seeAll": "Ver tudo"
    },
    "continue": {
      "continueSectionTitle": {
        "title": "Continue"
      },
      "toast": {
        "error": {
          "title": "Houve um erro ao carregar a seção de continue!",
          "message": "Recarregue a página ou tente novamente mais tarde."
        }
      }
    },
    "myProducts": {
      "myProductsSectionTitle": {
        "title": "Meus Produtos",
        "seeAll": "Ver tudo"
      },
      "emptyStateComponent": {
        "title": "Aguardando a liberação do produto",
        "description": "Se você já efetuou o pagamento, por favor, atualize a página dentro de alguns minutos. \n Em caso de boletos aguarde o tempo minimo para confirmação do pagamento."
      },
      "productCard": {
        "of": "de",
        "contents": "conteúdos",
        "status": {
          "finished": "Concluído",
          "blocked": "Bloqueado",
          "access": "Acessar",
          "continue": "Continuar"
        },
        "badges": {
          "live_meet": "Ao vivo",
          "buy": "Comprar agora"
        }
      },
      "toast": {
        "error": {
          "title": "Houve um erro ao carregar a seção de meus produtos!",
          "message": "Recarregue a página ou tente novamente mais tarde."
        }
      }
    }
  },
  "CourseDetails": {
    "emptyState": {
      "title": "Ainda não há conteúdos disponíveis",
      "description": "Por favor, volte mais tarde ou entre em contato \n com o(a) produtor(a) para saber quando os conteúdos serão disponibilizadas"
    },
    "product_type": {
      "path": "Combo",
      "online_course": "Curso Online",
      "ebook": "Ebook",
      "file": "Arquivo",
      "Quiz": "Prova",
      "webinar": "Webinar",
      "live_meet": "Encontro ao vivo"
    },
    "product_niche": {
      "other": "Outros",
      "animals-and-plants": "Animais e Plantas",
      "apps-and-software": "Apps e Software",
      "business-and-career": "Negócios e Carreira",
      "cooking-and-gastronomy": "Culinária e Gastronomia",
      "design": "Design",
      "ecology-and-environment": "Ecologia e Meio Ambiente",
      "education": "Educação",
      "entretainment": "Entrentenimento",
      "fashion-and-beauty": "Moda e Beleza",
      "finances-and-investments": "Finanças e Investimentos",
      "health-and-sports": "Saúde e Esportes",
      "hobbies": "Hobbies",
      "home-and-construction": "Casa e Construção",
      "information-technology": "Tecnologia da Informação",
      "internet": "Internet",
      "languages": "Idiomas",
      "law": "Direito",
      "music-and-arts": "Música e Artes",
      "personal-development": "Desenvolvimento Pessoal",
      "public-tender": "Concurso Público",
      "relationships": "Relacionamentos",
      "sexuality": "Sexualidade",
      "spirituality-and-religion": "Espiritualidade e Religião"
    },
    "productCard": {
      "badges": {
        "buy": "Comprar agora"
      }
    }
  },
  "clipboard": {
    "success": "Copiado para área de transferência",
    "failure": "Erro ao copiar para área de transferência"
  },
  "ExtraContent": {
    "extraContentTitle": "Geral do produto:",
    "lessonsExtraContentTitle": "Específico do conteúdo:",
    "ExtraContentCard": {
      "tooltip": "Não é possível baixar materiais tipo vídeo ou texto",
      "download": "Baixar Material"
    },
    "NotSupported": {
      "title": "Este material complementar não pode\nser visualizado, mas você pode\nbaixá-lo para acessá-lo.",
      "download": "Baixar Material"
    }
  }
}
