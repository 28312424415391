import {
  XStack,
  Text,
  Popover,
  Avatar,
  YStack,
  Separator,
  getTokens,
  useMedia,
  Stack,
  View,
  isWeb,
} from 'tamagui'
import { Button } from '@components/Button'
import { HeroIcon } from '@components/HeroIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { router } from 'expo-router'
import { useSignOut } from '@hooks/useUser'
import { useGetGeneralConfig } from '@hooks/useMembersConfig'
import { IBackRoute } from '@layouts/AppLayout'
import { ImageBackground } from 'react-native'
import { openWebBrowser } from '@utils/openWebBrowser'
import { useSession } from '@hooks/useSession'

interface IHeaderProps {
  backRoute?: IBackRoute
  isFixedHeader: boolean
  isVisible?: boolean
  logoUrl?: string | null
}

export function Header({
  backRoute,
  isFixedHeader,
  isVisible,
  logoUrl,
}: IHeaderProps) {
  const { signOutMutate } = useSignOut()
  const { data: generalConfig } = useGetGeneralConfig()
  const faviconUrl = generalConfig?.faviconUrl
  const media = useMedia()
  const { user } = useSession()
  const [isOpen, setIsOpen] = useState(false)

  const setFavicon = useCallback(() => {
    if (!isWeb) return

    const favicons = document.querySelectorAll("link[rel*='icon']")

    if (faviconUrl) {
      if (favicons.length === 0) {
        const newLink = document.createElement('link')
        newLink.setAttribute('rel', 'icon')
        document.head.appendChild(newLink)
        const faviconWithCache = `${faviconUrl}?t=${new Date().getTime()}`
        newLink.setAttribute('href', faviconWithCache)
      } else {
        favicons.forEach((favicon) => {
          const faviconWithCache = `${faviconUrl}?t=${new Date().getTime()}`
          favicon.setAttribute('href', faviconWithCache)
        })
      }
    } else {
      favicons.forEach((favicon) => {
        favicon.parentNode?.removeChild(favicon)
      })

      const link = document.createElement('link')
      link.setAttribute('rel', 'icon')
      link.setAttribute('href', 'data:,')
      document.head.appendChild(link)

      setTimeout(() => {
        link.parentNode?.removeChild(link)
      }, 100)
    }
  }, [faviconUrl])

  useEffect(() => {
    setFavicon()
  }, [setFavicon])

  useMemo(() => {
    if (!isVisible && isOpen && !isFixedHeader) setIsOpen(false)
  }, [isVisible, isFixedHeader, isOpen])

  const handleLogout = useCallback(() => {
    signOutMutate()
  }, [signOutMutate])

  const handleBack = useCallback(() => {
    if (backRoute?.back) backRoute.back()
    if (typeof backRoute === 'object') {
      if (backRoute.route === '/enrollments') {
        window.location.href = `${backRoute.route}`
      } else if (typeof backRoute.route === 'string') {
        router.push(backRoute.route)
      }
    }
  }, [backRoute])

  const paddingHorizontal = useMemo(() => {
    const sizes = getTokens().size
    if (media.gtLg) return sizes['$spacing-xsl'].val
    if (media.gtMd) return sizes['$spacing-xl'].val

    return sizes[media.gtSm ? '$spacing-xxxs' : '$spacing-xs'].val
  }, [media.gtSm, media.gtMd, media.gtLg])

  const displaySchoolLogo = backRoute?.shouldDisplayLogo && logoUrl
  const containerJustifyContent =
    displaySchoolLogo || backRoute?.label ? 'space-between' : 'flex-end'
  const headerBackgroundColor = isVisible ? '$neutral-0' : 'transparent'
  const popoverCrossAxis = isFixedHeader ? -65 : -50

  const handleProfilePress = () => {
    window.location.href = `/users/${user?.id}/edit`
  }

  const handleOrdersPress = () => {
    window.location.href = '/orders'
  }

  const handleCertificatesPress = () => {
    if (isWeb) {
      window.location.href = '/certificates'
    } else {
      openWebBrowser('/certificates')
    }
  }

  return (
    <Stack
      backgroundColor={headerBackgroundColor}
      position="absolute"
      width="100%"
      zIndex={1000}
    >
      <XStack
        width="100%"
        paddingVertical={16}
        backgroundColor="transparent"
        paddingHorizontal={paddingHorizontal}
        justifyContent={containerJustifyContent}
      >
        {backRoute && (
          <>
            {displaySchoolLogo ? (
              <View justifyContent="center" alignItems="center">
                <ImageBackground
                  testID="header-logo"
                  alt="logo"
                  resizeMode="contain"
                  source={{
                    uri: logoUrl,
                  }}
                  style={{
                    width: 124,
                    height: 40,
                  }}
                />
              </View>
            ) : backRoute.label ? (
              <Button
                variant="primary"
                testID="header-back-button"
                aria-label="Back"
                link
                paddingLeft={0}
                onPress={handleBack}
              >
                <HeroIcon
                  icon={['fas', 'chevron-left']}
                  color="$primary-300"
                  size={18}
                />
                <Text
                  fontSize="$font-size-xxs"
                  ff="$mulishRegular"
                  color="$primary-300"
                  position="relative"
                  textDecorationLine="underline"
                  top={-4}
                  ml={8}
                >
                  {backRoute.label}
                </Text>
              </Button>
            ) : null}
          </>
        )}
        {isWeb && (
          <XStack $gtSm={{ display: 'flex' }}>
            <Popover
              allowFlip
              placement="bottom"
              offset={{ mainAxis: 7, crossAxis: popoverCrossAxis }}
              open={isOpen}
              onOpenChange={(open) => setIsOpen(open)}
            >
              <Popover.Trigger asChild>
                <Button
                  outline
                  borderWidth={1}
                  hoverStyle={{
                    borderColor: '$neutral-800',
                    backgroundColor: 'transparent',
                  }}
                  focusStyle={{
                    borderColor: '$neutral-800',
                    backgroundColor: 'transparent',
                  }}
                  pressStyle={{
                    borderColor: '$neutral-800',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                  }}
                  borderColor="transparent"
                  onPress={() => setIsOpen(!isOpen)}
                >
                  <XStack gap={8} alignItems="center">
                    <Avatar
                      circular
                      size={24}
                      borderColor="$neutral-600"
                      borderWidth={1}
                    >
                      <Avatar.Image src={user?.coverImageUrl} />
                      <Avatar.Fallback bc="$primary-300" />
                    </Avatar>
                    {/* <HamburgerMenu
                    isOpen={isOpen}
                    menuClicked={() => setIsOpen(!isOpen)}
                    width={18}
                    height={10}
                    strokeWidth={1}
                    rotate={0}
                    color={isLightMode ? '#262626' : '#fff'}
                    borderRadius={0}
                    animationDuration={0.5}
                  /> */}
                  </XStack>
                </Button>
              </Popover.Trigger>
              <Popover.Content
                borderWidth={1}
                borderColor="$neutral-100"
                borderRadius={8}
                backgroundColor="$neutral-0"
                elevate
                padding={0}
              >
                <YStack>
                  <YStack paddingHorizontal={20} paddingVertical={20}>
                    <Text
                      fontSize="$font-size-xs"
                      ff="$mulishSemiBold"
                      color="$neutral-800"
                    >
                      {user?.firstName} {user?.lastName}
                    </Text>
                    <Text
                      fontSize="$font-size-xs"
                      ff="$mulishRegular"
                      color="$neutral-800"
                    >
                      {user?.email}
                    </Text>
                  </YStack>
                  <Separator borderColor="$neutral-100" />
                  <XStack
                    paddingHorizontal={20}
                    paddingVertical={12}
                    gap={8}
                    alignItems="center"
                    hoverStyle={{
                      cursor: 'pointer',
                      backgroundColor: '$neutral-100',
                    }}
                    onPress={handleProfilePress}
                  >
                    <HeroIcon
                      icon={['fal', 'user']}
                      color="$neutral-600"
                      size={18}
                    />
                    <Text
                      color="$neutral-800"
                      fontSize="$font-size-xxs"
                      ff="$mulishRegular"
                    >
                      Meu perfil
                    </Text>
                  </XStack>
                  <XStack
                    paddingHorizontal={20}
                    paddingVertical={12}
                    gap={8}
                    alignItems="center"
                    hoverStyle={{
                      cursor: 'pointer',
                      backgroundColor: '$neutral-100',
                    }}
                    onPress={handleOrdersPress}
                  >
                    <HeroIcon
                      icon={['fal', 'usd-circle']}
                      color="$neutral-600"
                      size={18}
                    />
                    <Text
                      color="$neutral-800"
                      fontSize="$font-size-xxs"
                      ff="$mulishRegular"
                    >
                      Meus pedidos
                    </Text>
                  </XStack>
                  <XStack
                    paddingHorizontal={20}
                    paddingTop={12}
                    paddingBottom={16}
                    gap={8}
                    alignItems="center"
                    hoverStyle={{
                      cursor: 'pointer',
                      backgroundColor: '$neutral-100',
                    }}
                    onPress={handleCertificatesPress}
                  >
                    <HeroIcon
                      icon={['fal', 'user-graduate']}
                      color="$neutral-600"
                      size={18}
                    />
                    <Text
                      color="$neutral-800"
                      fontSize="$font-size-xxs"
                      ff="$mulishRegular"
                    >
                      Certificados
                    </Text>
                  </XStack>
                  <Separator borderColor="$neutral-100" />
                  <XStack
                    paddingHorizontal={20}
                    paddingTop={12}
                    paddingBottom={20}
                    gap={8}
                    alignItems="center"
                    hoverStyle={{
                      cursor: 'pointer',
                      backgroundColor: '$neutral-100',
                    }}
                    onPress={() => handleLogout()}
                  >
                    <HeroIcon
                      icon={['fal', 'door-open']}
                      color="$neutral-600"
                      size={18}
                    />
                    <Text
                      color="$neutral-800"
                      fontSize="$font-size-xxs"
                      ff="$mulishRegular"
                    >
                      Sair
                    </Text>
                  </XStack>
                </YStack>
              </Popover.Content>
            </Popover>
          </XStack>
        )}
      </XStack>
    </Stack>
  )
}
