import { useLazyQuery } from '@apollo/client'
import { GET_PRODUCT_URL } from './queries/getProductUrl'

interface IUseGetProductUrlProps {
  itemId: string
  productListId: string
}

export const useGetProductUrl = ({
  itemId,
  productListId,
}: IUseGetProductUrlProps) => {
  const [getProductUrl, { data, loading, error, variables }] = useLazyQuery(
    GET_PRODUCT_URL,
    {
      variables: { itemId, productListId },
    },
  )

  return { getProductUrl, data, loading, error, variables }
}
