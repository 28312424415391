import { gql } from '@apollo/client'

export const GET_COURSE = gql`
  query getCourse($id: ID!) {
    course(id: $id) {
      id
      name
      kind
      description
    }
  }
`
