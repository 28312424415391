import { HeroIcon } from '@components/HeroIcon'
import { Text, useMedia, Button, useWindowDimensions, XStack } from 'tamagui'
import { useState } from 'react'

interface ILessonTitleLinkProps {
  handlePress: () => void
  title: string
  disabled: boolean
}

export function LessonTitleLink({
  handlePress,
  title,
  disabled,
}: ILessonTitleLinkProps) {
  const { width } = useWindowDimensions()
  const media = useMedia()
  const [isHovered, setIsHovered] = useState(false)

  const handlePressButton = () => {
    if (disabled) return null
    handlePress()
  }

  const handleHover = (bool: boolean) => {
    if (disabled) return null
    setIsHovered(bool)
  }

  const calculateWidth = media.gtSm ? 'auto' : '60%'
  const cursorStyle = disabled ? 'default' : 'pointer'
  const textColor = isHovered ? '$primary-300' : '$neutral-800'
  const textDecorationLine = disabled ? 'none' : 'underline'
  const maxWidth = width * 0.8
  const fontSize = media.gtSm ? '$font-size-sm' : '$font-size-xs'
  const iconSize = media.gtSm ? 18 : 24

  return (
    <XStack flex={1}>
      <Button
        onPress={handlePressButton}
        height="auto"
        size={20}
        pressStyle={{ outlineWidth: 0 }}
        focusStyle={{ outlineWidth: 0 }}
        onHoverIn={() => handleHover(true)}
        onHoverOut={() => handleHover(false)}
        style={{ cursor: cursorStyle }}
        width={calculateWidth}
        justifyContent="flex-start"
        bg={'transparent'}
        gap={6}
      >
        <Text
          color={textColor}
          ff="$mulishBold"
          numberOfLines={3}
          fontSize={fontSize}
          textAlign="left"
          textDecorationLine={textDecorationLine}
          maxWidth={maxWidth}
        >
          {title}
        </Text>
        {!disabled && (
          <HeroIcon
            icon={['fal', 'arrow-right']}
            size={iconSize}
            color={textColor}
          />
        )}
      </Button>
    </XStack>
  )
}
