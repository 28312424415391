import { Shimmer } from '@components/Shimmer'
import { Stack, XStack, YStack } from 'tamagui'

export function CourseGhostLoading() {
  return (
    <>
      <Stack
        flexDirection="column"
        marginBottom={40}
        marginTop={100}
        p={'$size.spacing-xs'}
        $gtSm={{ padding: 0 }}
      >
        <Stack
          $gtSm={{
            flexDirection: 'row',
            gap: 20,
            padding: '$size.spacing-xs',
          }}
          $gtMd={{
            padding: '$size.spacing-xsl',
          }}
          $gtLg={{
            width: '100%',
            maxWidth: 1608,
            marginHorizontal: 'auto',
            padding: '$size.spacing-lg',
          }}
        >
          <YStack
            $gtSm={{ width: '50%', marginBottom: 0 }}
            marginBottom={20}
            alignSelf="baseline"
            gap={20}
          >
            <Shimmer width={100} height={16} />
            <Shimmer width={250} height={35} $gtSm={{ width: '50%' }} />
            <Shimmer width={350} height={10} $gtSm={{ width: '100%' }} />
            <XStack gap={16} $gtSm={{ width: '50%' }}>
              <Shimmer width={150} height={48} $gtSm={{ width: '60%' }} />
              <Shimmer width={100} height={48} $gtSm={{ width: '30%' }} />
            </XStack>
            <Shimmer
              alignSelf="center"
              marginVertical={30}
              width={200}
              height={150}
              $gtSm={{ width: '100%', height: 150 }}
            />
          </YStack>
          <YStack justifyContent="center" $gtSm={{ width: '50%' }}>
            <Shimmer width={350} height={150} $gtSm={{ width: '100%' }} />
            <Shimmer
              width={0}
              height={0}
              $gtSm={{ width: '100%', height: 'auto' }}
            />
            <Shimmer
              width={0}
              height={0}
              $gtSm={{ width: '100%', height: 'auto' }}
            />
          </YStack>
        </Stack>
      </Stack>
      <YStack
        gap={20}
        bg="$background-100"
        p={'$size.spacing-xs'}
        $gtSm={{
          padding: '$size.spacing-md',
          marginTop: 100,
        }}
        $gtMd={{
          padding: '$size.spacing-xsl',
        }}
      >
        <Shimmer
          width={250}
          height={30}
          $gtSm={{ width: '40%', height: 50, marginBottom: 50 }}
        />
        <XStack gap={16}>
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
          <Shimmer width={300} height={150} $gtSm={{ height: 200 }} />
        </XStack>
      </YStack>
    </>
  )
}
