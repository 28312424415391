import { addDays, formatDateShort, isPastDate } from '@utils/dateFormatter'

export interface IContentAvailableProps {
  releaseAt: string | null
  releaseAfter: number | null
  lockAt: string | null
  lockAfter: number | null
  createdAt: string
  availableUntil?: string
}

export function isContentAvailable({
  releaseAfter,
  lockAfter,
  releaseAt,
  lockAt,
  createdAt,
  availableUntil,
}: IContentAvailableProps) {
  const isReleasedAt = releaseAt ? isPastDate(releaseAt) : true
  const isReleasedAfter = releaseAfter
    ? isPastDate(addDays(createdAt, releaseAfter))
    : true
  const isLockedAt = lockAt ? !isPastDate(lockAt) : true
  const isLockedAfter = lockAfter
    ? !isPastDate(addDays(createdAt, lockAfter))
    : true
  const isStillAvailable = availableUntil ? !isPastDate(availableUntil) : true

  return (
    isReleasedAt &&
    isReleasedAfter &&
    isLockedAt &&
    isLockedAfter &&
    isStillAvailable
  )
}

export function releaseContentDateText({
  releaseAt,
  releaseAfter,
  lockAt,
  lockAfter,
  createdAt,
}: IContentAvailableProps) {
  if (releaseAt && !lockAt && !lockAfter) {
    return `Libera em ${formatDateShort(releaseAt)}`
  }
  if (releaseAfter && !lockAt && !lockAfter) {
    const releaseDate = addDays(createdAt, releaseAfter)
    return `Libera em ${formatDateShort(releaseDate)}`
  }
  if (lockAt) {
    if (isPastDate(lockAt)) {
      return `Bloqueado em ${formatDateShort(lockAt)}`
    }
    return `Libera em ${formatDateShort(lockAt)}`
  }
  if (lockAfter) {
    const lockDate = addDays(createdAt, lockAfter)
    if (isPastDate(addDays(createdAt, lockAfter))) {
      return `Bloqueado em ${formatDateShort(lockDate)}`
    }

    return `Liberado até ${formatDateShort(lockDate)}`
  }
  return ''
}
