import { HeroIcon } from '@components/HeroIcon'
import { LessonTypeTranslation } from '@utils/lessonMediaType'
import { XStack, Card, Text, Image, useMedia, CardProps, isWeb } from 'tamagui'
import { getVideoThumb } from '@utils/videoThumbs'
import { ILesson } from '@dtos/Course'
import { IconName } from '@fortawesome/fontawesome-svg-core'

interface ILastViewedLessonSlideProps extends CardProps {
  item: ILesson
  width: number
  height: number
  idx: number
  isClickable: boolean
  onPressSlide: (args: ILesson) => void | null
}

export function LastViewedLessonSlide({
  item,
  width,
  height,
  idx,
  isClickable,
  onPressSlide,
  ...extraProps
}: ILastViewedLessonSlideProps) {
  const media = useMedia()
  const cardTransform = isWeb ? 'translate(-50%, 0)' : 'unset'
  const titleTopMargin = isWeb ? 16 : 10
  const additionalHeight = isWeb ? 0 : 30
  const cardLeft = isWeb ? '50%' : 65

  const lessonTitleFontSize = () => {
    return media.gtXl ? '$font-size-lg' : '$font-size-xs'
  }
  const iconSize = media.gtSm ? 60 : 40

  const findLessonIcon = () => {
    return LessonTypeTranslation(
      Object.keys(item.activity || {}).length > 0
        ? 'Quiz'
        : item.media?.type || 'Document',
    )
  }

  const LessonImage = () => {
    return {
      uri:
        getVideoThumb({
          type: item.media?.type || 'Video',
          host: item.media?.host || null,
          hostKey: item.media?.hostKey || null,
          duration: item.media?.duration || null,
          coverImage: item.coverImage,
        }) || '',
      height,
      width,
    }
  }

  const isYoutubeOrVimeo = ['Youtube', 'Vimeo'].includes(item.media?.host || '')
  const hasCoverOrHostedImage = item.coverImage || isYoutubeOrVimeo

  return (
    <Card
      top="0"
      position="relative"
      left={cardLeft}
      transform={cardTransform}
      width={width}
      height={height + additionalHeight}
      key={idx}
      onPress={() => isClickable && onPressSlide?.(item)}
      hoverStyle={{
        cursor: isClickable ? 'pointer' : 'default',
      }}
      {...extraProps}
    >
      {hasCoverOrHostedImage ? (
        <Image borderRadius={8} source={LessonImage()} alt="" />
      ) : (
        <XStack
          bg="$background-100"
          alignItems="center"
          borderRadius={8}
          justifyContent="center"
          height={height}
        >
          <HeroIcon
            icon={findLessonIcon() as IconName}
            color="$primary-300"
            size={iconSize}
          />
        </XStack>
      )}
      <Text
        marginTop={titleTopMargin}
        numberOfLines={1}
        overflow="visible"
        fontSize={lessonTitleFontSize()}
        color="$neutral-800"
        ff="$mulishSemiBold"
      >
        {item.title}
      </Text>
    </Card>
  )
}
