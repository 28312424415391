const CONTINUE_CARD_DIMENSIONS = {
  modal: { width: 144, height: 81 },
  xs: { width: 204, height: 115 },
  sm: { width: 316, height: 178 },
  md: { width: 298, height: 168 },
  lg: { width: 345, height: 194 },
  xxl: { width: 345, height: 194 },
}

const CONTINUE_CARD_DIMENSIONS_MODAL = {
  xs: { width: 350, height: 81 },
  sm: { width: 576, height: 81 },
}

export { CONTINUE_CARD_DIMENSIONS, CONTINUE_CARD_DIMENSIONS_MODAL }
