import { View, useMedia, useWindowDimensions } from 'tamagui'
import { Slider } from '@components/Slider'
import { ContinueCard } from './ContinueCard'
import { DefaultSectionTitle } from '../DefaultSectionTitle'
import { useState, useMemo, useCallback } from 'react'
import { ContinueLessonsModal } from './ContinueLessonsModal'
import { useContinueLessons } from '@hooks/useContinueLessons'
import { useTranslation } from 'react-i18next'
import { isContentAvailable } from '@utils/contentAvailable'
import { useMediaSize } from '@hooks/useMediaSize'
import { CONTINUE_CARD_DIMENSIONS } from '@constants/continueCardDimensions'

export function ContinueLessonsSection({
  showProductName,
}: {
  showProductName: boolean
}) {
  const { t } = useTranslation('Home')
  const [isHovered, setIsHovered] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const media = useMedia()
  const { continueLessons } = useContinueLessons()
  const { width: windowWidth } = useWindowDimensions()
  const sizes = useMediaSize()

  const reversedLessons = useMemo(() => {
    if (!continueLessons) return []

    const filteredContinueLessons = continueLessons.filter((continueLesson) => {
      const dataAvailable = {
        releaseAt: continueLesson.lesson.releaseAt,
        releaseAfter: continueLesson.lesson.releaseAfter,
        lockAt: continueLesson.lesson.lockAt,
        lockAfter: continueLesson.lesson.lockAfter,
        createdAt: continueLesson.enrollment.activatedAt,
      }
      const isEnrollmentActive = continueLesson.enrollment.status === 'active'
      return isContentAvailable(dataAvailable) && isEnrollmentActive
    })

    return [...filteredContinueLessons].reverse()
  }, [continueLessons])

  const containerPadding = media.gtSm ? 48 : 16
  const spaceBetweenCards = media.gtSm ? 20 : 16

  const slidesPerView = () => {
    const cardWidth =
      CONTINUE_CARD_DIMENSIONS[sizes] || CONTINUE_CARD_DIMENSIONS.xs
    return (
      (windowWidth - containerPadding) / (cardWidth.width + spaceBetweenCards)
    )
  }

  const shouldDisplaySliderControls = media.gtMd && isHovered
  const shouldExtendCard = media.gtMd && continueLessons?.length === 1
  const shouldDisplaySeeAllButton =
    continueLessons && continueLessons?.length > slidesPerView()

  const handleButtonPress = () => {
    setOpenModal(true)
  }

  const lessonCards = useCallback(
    (isModalVersion: boolean) => {
      return reversedLessons.map((lessonToContinue) => (
        <ContinueCard
          cardId={lessonToContinue.enrollment.id.toString()}
          showProductName={showProductName}
          key={lessonToContinue.id.toString()}
          lessonProgress={lessonToContinue}
          shouldExtendCard={shouldExtendCard}
          productTitle={lessonToContinue.schoolProduct.title}
          isModalVersion={isModalVersion}
        />
      ))
    },
    [reversedLessons, showProductName, shouldExtendCard],
  )

  return (
    <View
      paddingLeft="$size.spacing-xs"
      $gtSm={{
        paddingLeft: '$size.spacing-xsl',
      }}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <Slider
        slidesTotalNumber={reversedLessons.length}
        slidesPerView={slidesPerView()}
        displaySliderControls={false}
        displaySliderControlsHorizontal={shouldDisplaySliderControls}
        slidesSpacing={spaceBetweenCards}
        sliderTitle={
          <DefaultSectionTitle
            title={t('continue.continueSectionTitle.title')}
            isHoveringSection={isHovered}
            onButtonPress={handleButtonPress}
            showButton={shouldDisplaySeeAllButton ?? false}
          />
        }
      >
        {lessonCards(false)}
      </Slider>
      <ContinueLessonsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        lessonCards={lessonCards(true)}
        title={t('continue.continueSectionTitle.title')}
      />
    </View>
  )
}
