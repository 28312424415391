import { createContext, ReactNode } from 'react'
import { IContinueLessons } from '@dtos/User'

interface IContinueLessonsContextData {
  continueLessons: IContinueLessons[] | null
  isLoading: boolean
}

export const ContinueLessonsContext =
  createContext<IContinueLessonsContextData>({} as IContinueLessonsContextData)

export function ContinueLessonsProvider({
  children,
  continueLessons,
  isLoading,
}: {
  children: ReactNode
  continueLessons: IContinueLessons[] | null
  isLoading: boolean
}) {
  return (
    <ContinueLessonsContext.Provider value={{ continueLessons, isLoading }}>
      {children}
    </ContinueLessonsContext.Provider>
  )
}
