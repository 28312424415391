import React, { forwardRef } from 'react'
import {
  GetProps,
  GetRef,
  Spinner,
  Stack,
  Text,
  createStyledContext,
  styled,
  withStaticProperties,
} from 'tamagui'

type VariantType = 'primary' | 'secundary' | 'success' | 'danger' | 'disabled'

export const ButtonContext = createStyledContext<{
  link: boolean
  outline: boolean
  disabled: boolean
  icon: boolean | undefined
  loading: boolean
  variant: VariantType
}>({
  link: false,
  outline: false,
  disabled: false,
  icon: undefined,
  loading: false,
  variant: 'primary',
})

const colorByVariant = {
  primary: '$primary-300',
  secundary: '$secundary-300',
  danger: '$danger-300',
  success: '$success-300',
  disabled: '$neutral-500',
}

export const ButtonFrame = styled(Stack, {
  name: 'Button',
  tag: 'button',
  context: ButtonContext,
  focusable: true,
  role: 'button',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  br: '$spacing-nano',
  cursor: 'pointer',
  borderWidth: 2,
  borderColor: 'transparent',
  variants: {
    size: {
      small: {
        paddingVertical: '$size.spacing-nano',
        paddingHorizontal: '$size.spacing-xxs',
      },
      medium: {
        paddingVertical: '$size.spacing-xxxs',
        paddingHorizontal: '$size.spacing-xs',
      },
      large: {
        paddingVertical: '$size.spacing-xxs',
        paddingHorizontal: '$size.spacing-md',
      },
    },
    variant: {
      primary: {
        bg: '$primary-300',
        hoverStyle: {
          bg: '$primary-200',
        },
        focusStyle: {
          bg: '$primary-100',
        },
        pressStyle: {
          bg: '$primary-100',
          borderWidth: 2,
          borderStyle: 'solid',
          borderOffset: 1,
          borderColor: '$info-300',
        },
      },
      secondary: {
        bg: 'transparent',
        borderColor: '$primary-300',
        hoverStyle: {
          borderColor: '$primary-100',
          bg: '$primary-400',
        },
        focusStyle: {
          borderColor: '$primary-100',
          bg: '$primary-400',
        },
        pressStyle: {
          bg: '$primary-400',
          borderWidth: 2,
          borderStyle: 'solid',
          borderOffset: 1,
          borderColor: '$info-300',
        },
      },
      danger: {
        bg: '$danger-300',
        hoverStyle: {
          bg: '$danger-200',
        },
        focusStyle: {
          bg: '$danger-200',
        },
        pressStyle: {
          bg: '$danger-200',
          borderWidth: 2,
          borderStyle: 'solid',
          borderOffset: 1,
          borderColor: '$info-300',
        },
      },
      success: {
        bg: '$success-300',
        hoverStyle: {
          bg: '$success-200',
        },
        focusStyle: {
          bg: '$success-200',
        },
        pressStyle: {
          bg: '$success-200',
          borderWidth: 2,
          borderStyle: 'solid',
          borderOffset: 1,
          borderColor: '$info-300',
        },
      },
      disabled: {
        bg: '$neutral-200',
        borderColor: '$neutral-200',
      },
    },
    outline: {
      true: (_, { props }: { props: { variant: VariantType } }) => ({
        bg: 'transparent',
        borderColor: colorByVariant[props.variant],
        color: colorByVariant[props.variant],
        hoverStyle: {
          borderColor: colorByVariant[props.variant],
          bg: colorByVariant[props.variant],
          color: 'inherit',
        },
        focusStyle: {
          bg: colorByVariant[props.variant],
        },
        pressStyle: {
          borderWidth: 2,
          borderStyle: 'solid',
          borderOffset: 1,
          borderColor: colorByVariant[props.variant],
          bg: colorByVariant[props.variant],
        },
      }),
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        bg: '$neutral-200',
        borderColor: '$neutral-200',
      },
    },
    link: {
      true: {
        borderColor: 'transparent',
        bg: 'transparent',
        hoverStyle: {
          borderColor: 'transparent',
          bg: 'transparent',
        },
        focusStyle: {
          bg: 'transparent',
        },
        pressStyle: {
          bg: 'transparent',
        },
      },
    },
    icon: {
      true: {
        borderColor: 'transparent',
        bg: 'transparent',
        hoverStyle: {
          bg: 'transparent',
        },
        focusStyle: {
          bg: 'transparent',
        },
        pressStyle: {
          bg: 'transparent',
        },
      },
    },
    loading: {
      true: {
        pointerEvents: 'none',
        bg: 'neutral-200',
      },
    },
  } as const,
  defaultVariants: {
    variant: 'primary',
    size: 'medium',
  },
})

export type ButtonProps = GetProps<typeof ButtonFrame>

export const ButtonText = styled(Text, {
  name: 'ButtonText',
  context: ButtonContext,
  userSelect: 'none',
  ff: '$mulishBold',
  fontSize: '$font-size-xxs',
  variants: {
    variant: {
      primary: {
        color: '$neutral-0',
      },
      secondary: {
        color: '$primary-300',
      },
      danger: {
        color: '$neutral-0',
      },
      success: {
        color: '$neutral-0',
      },
      disabled: {
        color: '$neutral-500',
      },
    },
    outline: {
      true: () => ({
        color: 'inherit',
      }),
    },
    link: {
      true: {
        textDecorationLine: 'underline',
        hoverStyle: {
          opacity: 0.7,
        },
      },
    },
    disabled: {
      true: {
        color: '$neutral-500',
        textDecorationColor: 'transparent',
      },
    },
    loading: {
      true: {
        color: 'transparent',
      },
    },
  } as const,
  defaultVariants: {
    variant: 'primary',
  },
})

const ButtonIcon = ({ icon, loading, children }: ButtonProps) => {
  if (loading || !icon) return
  return <>{children}</>
}

export const StyledSpinner = styled(Spinner, {
  name: 'StyledSpinner',
  context: ButtonContext,
  userSelect: 'none',
  color: '$neutral-800',
  position: 'absolute',
  variants: {
    size: {
      small: {
        size: 'small',
      },
      medium: {
        size: 'small',
      },
      large: {
        size: 'large',
      },
    },
  } as const,
  defaultVariants: {
    size: 'medium',
  },
})

const ButtonSpinner = ({ icon, loading }: ButtonProps) => {
  if (icon) return
  if (!loading) return

  return <StyledSpinner />
}

export const AppButton = withStaticProperties(ButtonFrame, {
  Props: ButtonContext.Provider,
  Text: ButtonText,
  Icon: ButtonIcon,
  Spinner: ButtonSpinner,
})

type Props = GetProps<typeof AppButton>

type Ref = GetRef<typeof AppButton>
// eslint-disable-next-line react/display-name
export const Button = forwardRef<Ref, Props>(
  ({ children, ...rest }: Props, ref) => {
    return (
      <AppButton ref={ref} {...rest}>
        <AppButton.Icon {...rest}>{children}</AppButton.Icon>
        <AppButton.Spinner {...rest} />
        {!rest.icon && (
          <AppButton.Text
            variant={rest.variant}
            outline={rest.outline}
            link={rest.link}
            loading={rest.loading}
            {...rest}
            backgroundColor="transparent"
            testID="button-text"
          >
            {children}
          </AppButton.Text>
        )}
      </AppButton>
    )
  },
)
