import { Dialog, YStack } from 'tamagui'

import { Modal } from '@components/Modal'
import { useTranslation } from 'react-i18next'
import { HeroIcon } from '@components/HeroIcon'

interface IUnsubscribeSuccessModalProps {
  open: boolean
  courseName?: string
  expirationDate?: string
  onClose: () => void
}

export function UnsubscribeSuccessModal({
  courseName,
  expirationDate,
  onClose,
  open,
}: IUnsubscribeSuccessModalProps) {
  const { t } = useTranslation('OrderDetails')

  return (
    <Modal open={open} snapPoints={[40, 40]} maxWidth={392} onClose={onClose}>
      <YStack
        gap="$size.spacing-xs"
        alignItems="center"
        paddingHorizontal="$size.spacing-lg"
        paddingVertical="$size.spacing-xsl"
        br="$spacing-nano"
        $gtSm={{
          p: '$size.spacing-lg',
        }}
      >
        <HeroIcon
          icon={['fal', 'check-circle']}
          color="$success-200"
          size={48}
        />
        <YStack gap="$size.spacing-lg">
          <Dialog.Title
            color="$neutral-800"
            ff="$mulishBold"
            fontSize="$font-size-sm"
            textAlign="center"
          >
            {t('subscription.unubscribeSuccess.title')}
          </Dialog.Title>
          <Dialog.Description
            color="$neutral-800"
            ff="$mulishRegular"
            fontSize="$font-size-xxs"
            textAlign="center"
          >
            {t('subscription.unubscribeSuccess.description', {
              courseName,
              expirationDate,
            })}
          </Dialog.Description>
        </YStack>
      </YStack>
    </Modal>
  )
}
