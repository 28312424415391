import { ReactNode } from 'react'
import type { TooltipProps } from 'tamagui'
import { Tooltip, Text, Paragraph, TooltipGroup } from 'tamagui'
import { isWeb } from '@tamagui/core'

interface IHeroTooltipProps extends TooltipProps {
  text?: string
  children?: ReactNode
  width?: number
  display: boolean
}

function BaseTooltip({
  text,
  children,
  width,
  display,
  ...props
}: IHeroTooltipProps) {
  return (
    <Text>
      <Tooltip {...props}>
        <Tooltip.Trigger>{children}</Tooltip.Trigger>
        {display && (
          <Tooltip.Content
            borderRadius={2}
            padding={4}
            enterStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
            scale={1}
            x={0}
            y={0}
            bg="$neutral-700"
            height="100%"
            width={width}
            opacity={1}
          >
            <Tooltip.Arrow bg="$neutral-700" />
            <Paragraph
              size={14}
              textAlign="center"
              fontSize="$font-size-xxs"
              ff="$mulishRegular"
              color="$neutral-0"
            >
              {text}
            </Paragraph>
          </Tooltip.Content>
        )}
      </Tooltip>
    </Text>
  )
}

export function HeroTooltip({ children, ...rest }: IHeroTooltipProps) {
  if (!isWeb) return <>{children}</>
  return (
    <TooltipGroup delay={{ open: 500, close: 100 }}>
      <BaseTooltip {...rest}>{children}</BaseTooltip>
    </TooltipGroup>
  )
}
