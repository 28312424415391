import { useScreenLocker } from '@hooks/useScreenLocker'
import { cacheDirectory, downloadAsync } from 'expo-file-system'
import { shareAsync } from 'expo-sharing'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isWeb } from 'tamagui'

export function useBankSlipPrint() {
  const { t } = useTranslation('OrderDetails')
  const { lock, unlock } = useScreenLocker()

  const printBankSlip = useCallback(
    async (url: string) => {
      if (isWeb) {
        window.open(url, '_blank')
        return
      }

      lock({
        title: t('loadPrintTitle'),
        description: t('loadPrintDescription'),
      })

      const localURL = `${cacheDirectory}fatura.pdf`

      await downloadAsync(url, localURL)

      unlock()

      await shareAsync(localURL)
    },
    [lock, t, unlock],
  )

  return { printBankSlip }
}
